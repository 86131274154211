<div class="side-navbar">
    <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item" style="margin-top: 40px;">
            <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/dashboard/seller/dashboard">
                <i class="fa-solid fa-house"></i>
                Dashboard
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/seller/edit-profile"
                [queryParams]="{edit:'1'}">
                <i class="fa-solid fa-user"></i>
                <span class="label-menu">Profile </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/seller/vehicle-list">
                <i class="fa-solid fa-car"></i>
                My Vehicles
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/seller/add-vehicle">
                <i class="fa-solid fa-plus"></i>
                Add Vehicles
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/seller/sales"
                [queryParams]="{edit:'1'}">
                <i class="fa-solid fa-tag"></i>
                <span class="label-menu">Sales </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/seller/transaction">
                <i class="fa-solid fa-cart-shopping"></i>
                Payment
            </a>
        </li>

        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/seller/statement">
                <i class="fa-solid fa-receipt"></i>
                Statement
            </a>
        </li>
    </ul>
</div>