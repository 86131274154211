import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Vehicle } from "src/app/main/vehicles/vehicles.component";
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { catchError, filter } from 'rxjs/operators';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { ToastrService } from "ngx-toastr";

declare var Tawk_API: any;

@Injectable()
export class SharedService {
    private httpClient: HttpClient;
    private selectedVehicleSource = new BehaviorSubject<Vehicle>({ make: '', model: '', year: '', vin: '' });
    selectedVehicle$ = this.selectedVehicleSource.asObservable();
    private isShowheaderSubject = new BehaviorSubject<boolean>(false);
    isShowheader$ = this.isShowheaderSubject.asObservable();

    url: any = environment.API_URL
    constructor(
        private http: HttpClient,
        private router: Router,
        private handler: HttpBackend,
        private toastrService: ToastrService,
    ) {
        this.httpClient = new HttpClient(handler);
    }


    private hideFooter = new BehaviorSubject<any>({});
    hideFooter$ = this.hideFooter.asObservable();

    hideFooterElement(x: any) {
        this.hideFooter.next(x);
    }

    // src\app\shared\services1\shared.service.ts
    getImage() {
        return 'assets/img/imageres.jpg'
    }



    getTime() {
        return this.httpClient.get('http://worldclockapi.com/api/json/est/now')
            .pipe(
                catchError(error => {
                    console.error('Error fetching time:', error);
                    throw error; // Rethrow or handle as needed
                })
            );
    }

    objectToQueryString(obj: any) {
        const queryParams = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
                queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }

        return queryParams.join('&');
    }


    getBannerList(data: any) {
        return this.http.get(`${this.url}banner/getbanners?${data}`);
    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    deleteImage(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'vin/delete', httpOptions);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    uploadVideo(file: any) {
        return this.http.post(this.url + "upload/video", file);
    }

    uploadPdf(file: any) {
        return this.http.post(this.url + "upload/pdf", file);
    }
    dayEndReport(data: any) {
        return this.http.post(this.url + "reciept/dayend-report", data);
    }

    setSelectedVehicle(vehicle: any) {
        this.selectedVehicleSource.next(vehicle);
    }

    setIsShowHeader(isShow: boolean) {
        this.isShowheaderSubject.next(isShow);
    }

    getCommentsierarchy(data: any): Observable<any> {
        return this.http.get(this.url + 'comment/get-comment-hierarchy?referenceName=' + data);
    }

    getTimeFromServer(data: any) {
        return this.http.get(this.url + `getdbtime/getCurrentTime?${data}`)
    }


    //temp service
    userVerifyWithSendOtp(data: any): Observable<any> {
        return this.http.post(this.url + 'user/send-otp', data);
    }

    userVerifyOtp(data: any): Observable<any> {
        return this.http.post(this.url + 'user/verify-otp', data);
    }

    deleteUser(data: any): Observable<any> {
        return this.http.delete(this.url + `user/remove/${data}`);
    }

    private isLoading = new BehaviorSubject<boolean>(false);

    get isLoading$() {
        return this.isLoading.asObservable();
    }

    show() {
        this.isLoading.next(true);
    }

    hide() {
        this.isLoading.next(false);
    }


    getEventService(url: string) {
        var thid = this;
        fetchEventSource(this.url + url, {
            method: 'GET',
            headers: {
                'Authorization': "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done"), // Include headers if needed
                'Other-Header': 'value',
            },
            onmessage(event) {
                if (event.data != '') {
                    const formatedData = JSON.parse(event.data);
                    if (formatedData?.eventName == 'USER_LOGOUT' && formatedData?.success == true) {
                        localStorage.removeItem('currentUser');
                        localStorage.removeItem('lang');
                        localStorage.removeItem("sortTypeKey");
                        localStorage.removeItem("sortTypeOrder");
                        localStorage.removeItem("isViewTypeList");
                        thid.navigateTohome();
                    }
                }
            },
        });
    }
    geteventurl(url: string) {
        return (this.url + url);
    }

    navigateTohome() {
        this.router.navigate(['']);
    }

    customerQueryCreation(file: any) {
        return this.http.post(this.url + "contact-us/ticket", file);
    }

    private readonly idSubject = new BehaviorSubject<string | null>(null);
    readonly id$ = this.idSubject.asObservable();

    setId(id: string | null): void {
        this.idSubject.next(id);
    }
    // hideChat() {
    //     if (typeof Tawk_API !== 'undefined' && typeof Tawk_API.showWidget === 'function') {
    //         Tawk_API.hideWidget();
    //         Tawk_API.minimize();
    //     }
    // }

    // showChat() {

    //     this.router.events.pipe(
    //         filter((event) => event instanceof NavigationStart)
    //     ).subscribe((event: NavigationStart) => {
    //         // Execute Tawk.to actions before route changes
    //         if (event.url.includes('dashboard') || event.url.includes('auction-join') || event.url.includes('banner')) {
    //             if (typeof Tawk_API !== 'undefined' && typeof Tawk_API.showWidget === 'function') {
    //                 Tawk_API.minimize();
    //                 Tawk_API.hideWidget();
    //             }

    //         }
    //     });
    //     this.router.events.pipe(
    //         filter((event) => event instanceof NavigationEnd)
    //     ).subscribe((event: NavigationEnd) => {
    //         if (event.url.includes('dashboard') || event.url.includes('auction-join') || event.url.includes('banner')) {
    //             if (typeof Tawk_API !== 'undefined' && typeof Tawk_API.showWidget === 'function') {
    //                 Tawk_API.minimize();
    //                 Tawk_API.hideWidget();
    //             }
    //         } else {
    //             if (typeof Tawk_API !== 'undefined' && typeof Tawk_API.showWidget === 'function') {
    //                 Tawk_API.showWidget();
    //             } else {
    //                 var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    //                 (function () {
    //                     var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //                     s1.async = true;
    //                     s1.src = 'https://embed.tawk.to/650093ebb1aaa13b7a767595/1ha543l1o';
    //                     s1.charset = 'UTF-8';
    //                     s1.setAttribute('crossorigin', '*');
    //                     s0.parentNode.insertBefore(s1, s0);
    //                 })();
    //             }
    //         }
    //     });
    // }
    cloudFrontUrl(size: any, url: any) {
        if (url?.includes('/assets/')) {
            const dynamicPath = url?.split('/assets/').pop();
            const cdnUrl = `${environment.CF_URL}${size}/assets/${dynamicPath}`;
            return cdnUrl;
        } else {
            return url;
        }
    }

}

