<section class="vehicle-details-wpr">
    <div class="filter-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="vehicle-details-heading">
                        <h2> <a href="#" routerLink="/vehicle">My Vehicle</a>
                            <span>{{vehicleDetails?.carDescription}}</span> </h2>
                        <ul class="product-details">
                            <li>
                                <h5 class="product-title-info">Location: <span
                                        class="product-title-info-value">{{vehicleDetails?.branchs}}</span></h5>
                            </li>
                            <li>
                                <h5 class="product-title-info">Sequence No:<span
                                        class="product-title-info-value">{{vehicleDetails?.auctionDisplayNo}}</span>
                                </h5>
                            </li>
                        </ul>
                        <div class="v-d-btn-wpr" style="margin-top: 7px;" *ngIf="isAuctionVehicle">
                            <a *ngIf="isPreviousCarAvailable"
                                class="secondary-btn secondary-btn_border-round secondary-btn__gray"
                                (click)="GetAuctionVehicleByDisplay('p')"><span
                                    style="cursor: pointer;">Previous</span></a>
                            <a *ngIf="isNextCarAvailable" class="secondary-btn secondary-btn_border-round details-btn"
                                (click)="GetAuctionVehicleByDisplay('n')"><span
                                    style="color: white; cursor: pointer;">Next</span></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="container" style="margin-top: 15px;">
        <div class="row">
            <div class="col-md-8">
                <div>
                    <div class="row p-slider">
                        <div class="col-md-3 slider-thumbnail">
                            <div class="" *ngFor="let i of filterImage(vehicleDetails?.vinimages)">
                                <img  [lazyLoad]="sharedService.cloudFrontUrl('400x300',i?.image)"
                                [defaultImage]="i?.image ? i?.image : '../../../assets/img/default-car123123.jpg'"
                                alt="" (click)="onClickImage(i?.image)">
                            </div>
                        </div>
                        <div class="col-md-9 slider-details-wpr" style="position: relative;">
                            <i class="fa fa-chevron-right" (click)="nextImage()" style="
                                color: white;
                                font-size: 28px;
                                position: absolute;
                                top: 50%;
                                z-index: 1 !important;
                                right: 22px;
                                cursor: pointer;
                                clip-path: circle(42%);
                                backdrop-filter: blur(2px);
                                padding: 10px;
                                background: rgb(255 255 255 / 25%);"></i>

                            <i class="fa fa-chevron-left " (click)="previousImage()" style="
                            color: white;
                            font-size: 28px;
                            position: absolute;
                            top: 50%;
                            z-index: 1 !important;
                            left: 22px;
                            cursor: pointer;
                            clip-path: circle(42%);
                            backdrop-filter: blur(2px);
                            padding: 10px;
                            background: rgb(255 255 255 / 25%);"></i>
                            <div *ngIf="zoomImageClick">

                                <div class="img-zoom-container carImgZoomOut" (click)="onClickImageZoom()"
                                    (mouseenter)="showResult = true; imageZoom('myimage', 'myresult');"
                                    (mouseleave)="showResult = false">
                                    <img id="myimage" class="carImgZoomOut" (click)="onClickImageZoom()"
                                    [lazyLoad]="sharedService.cloudFrontUrl('600x500',showVehicle)"
                                    [defaultImage]="showVehicle ?showVehicle: '../../../assets/img/default-car123123.jpg'"
                                    >
                                    <div id="myresult" (click)="onClickImageZoom()"
                                        class="img-zoom-result carImgZoomOut"></div>
                                    <div style=" width: 14px;
                                    height: 14px; 
                                    top: 2%;
                                    position: absolute;
                                    left: 95%; 
                                    transform: translateX(-50%); 
                                    " *ngIf="currentUser?.userType === 4 || currentUser?.userType === 5"><a
                                            (click)="vehicleDetails?.watchList ? deleteWatchList(vehicleDetails?.watchList?._id) : addToWatchList()"
                                            style="margin-left: 5px;"
                                            [tooltip]="vehicleDetails?.watchList ? 'click to Remove' : 'click to add'"><i
                                                class="fa-solid fw-100 fa-bookmark tc-red fa-2x"
                                                [ngClass]="{'fw-1000' : vehicleDetails?.watchList}"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!zoomImageClick">
                                <div>
                                    <img id="myimage" class="img-zoom-container carImgZoomIn"
                                        (click)="onClickImageZoom()"
                                        [lazyLoad]="sharedService.cloudFrontUrl('600x500',showVehicle)"
                                        [defaultImage]="showVehicle ? showVehicle: '../../../assets/img/default-car123123.jpg'"
                                        height="100%" width="100%" style="border-radius: 10px;">
                                    <div id="myresult" class="img-zoom-result"></div>
                                    <div style="top: 2%;
                                    position: absolute;
                                    left: 95%; 
                                    transform: translateX(-50%); 
                                    " *ngIf="currentUser?.userType === 4 || currentUser?.userType === 5"><a
                                            (click)="vehicleDetails?.watchList ? deleteWatchList(vehicleDetails?.watchList?._id) : addToWatchList()"
                                            style="margin-left: 5px;"
                                            [tooltip]="vehicleDetails?.watchList ? 'Click to Remove' : 'Click to Add'"><i
                                                class="fa-solid fw-100 fa-bookmark tc-red fa-2x"
                                                [ngClass]="{'fw-1000' : vehicleDetails?.watchList}"></i></a>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="screenWidth && screenWidth < 768">
                    <div class="col-md-12" style="margin-top: 10px;">
                        <div class="d-flex" style="overflow: overlay; width: 100%; ">
                            <div class="" *ngFor="let i of filterImage(vehicleDetails?.vinimages)">
                                <img style="max-width: 100px; margin-right: 10px;
                                            max-height: 100px;
                                            cursor: pointer;"  [lazyLoad]="sharedService.cloudFrontUrl('400x300',i?.image)" 
                                            [defaultImage]="i?.image ? i?.image : '../../../assets/img/default-car123123.jpg'"
                                            alt="" (click)="onClickImage(i?.image)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-details-wpr">
                    <ul class="product-discription">
                        <li>
                            <img src="../../../../../assets/img/icons/vector-1.webp" />
                            <h6>{{vehicleDetails?.engines}} ltr</h6>
                        </li>
                        <li>
                            <img src="../../../../../assets/img/icons/vector-2.webp" />
                            <h6>{{vehicleDetails?.milage === 0 || vehicleDetails?.milage === 1 ? '-' : vehicleDetails?.milage + ' mi'}}</h6>
                        </li>
                        <li>
                            <img src="../../../../../assets/img/icons/vector-3.webp" />
                            <h6>{{vehicleDetails?.fueltypes ? vehicleDetails?.fueltypes : 'NA'}}</h6>
                        </li>
                        <li>
                            <img src="../../../../../assets/img/icons/vector-4.webp" />
                            <h6>{{vehicleDetails?.drivetypes ? vehicleDetails?.drivetypes : 'NA'}}</h6>
                        </li>

                        <li>
                            <img src="assets/images/key.png" height="25" width="25" />
                            <h6>{{vehicleDetails?.inspection?.key?.status ? 'yes' : 'No'}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="card mt-4">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>

                                        <th scope="col" colspan="2">Vehicle Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Item Number</td>
                                        <td class="text-right">{{vehicleDetails?.auctionDisplayNo}}</td>
                                    </tr>
                                    <tr>
                                        <td>Lot Number</td>
                                        <td class="text-right">{{vehicleDetails?.lotNo}}</td>
                                    </tr>
                                    <tr>
                                        <td>Vin Number </td>
                                        <td class="text-right">
                                            <span *ngIf="currentUser">{{vehicleDetails?.vin}}</span>
                                            <span *ngIf="!currentUser">{{vehicleDetails?.vin.length>8 ? '**' :
                                                ''}}{{vehicleDetails?.vin.length > 8 ? vehicleDetails?.vin.slice(-8) :
                                                vehicleDetails?.vin}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Body Type</td>
                                        <td class="text-right">{{vehicleDetails?.bodys ? vehicleDetails?.bodys : 'NA'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Engine Type</td>
                                        <td class="text-right">{{vehicleDetails?.engines ? vehicleDetails?.engines :
                                            'NA'}} ltr</td>
                                    </tr>
                                    <tr>
                                        <td>Cylinders</td>
                                        <td class="text-right">{{vehicleDetails?.cylinder == 0 ? 'NA' :
                                            vehicleDetails?.cylinder ? vehicleDetails?.cylinder : 'NA'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Drive Type</td>
                                        <td class="text-right">{{vehicleDetails?.drivetypes ? vehicleDetails?.drivetypes
                                            : 'NA'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Transmission Type</td>
                                        <td class="text-right">{{vehicleDetails?.transmission ?
                                            vehicleDetails?.transmission : 'NA'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel Type</td>
                                        <td class="text-right">{{vehicleDetails?.fueltypes ? vehicleDetails?.fueltypes :
                                            'NA'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Color Exterior</td>
                                        <td class="text-right">{{vehicleDetails?.color ? vehicleDetails?.color : 'NA'}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Remarks</td>
                                        <td class="text-right">{{vehicleDetails?.inventoryRemarks}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div class="vehicle-info">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card bid-info-card"
                    *ngIf="vehicleDetails?.inventoryStatus==1 || vehicleDetails?.inventoryStatus==2">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" colspan="2">Bid Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Eligibility Status</td>
                                        <td class="text-right" *ngIf="currentUser">{{currentUser?.creditLimit >=
                                            customBid ? 'Eligible' : 'Not Eligible'}}</td>
                                        <td class="text-right" *ngIf="!currentUser">Not Eligible</td>
                                    </tr>
                                    <tr>
                                        <td>Starting Bid</td>
                                        <td class="text-right">AED <span
                                                class="text-danger">{{vehicleDetails?.startingBid ?
                                                vehicleDetails?.startingBid : 0}}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Bid Status</td>
                                        <td class="text-right">Pre Bid<span class="text-danger"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Current Pre Bid</td>
                                        <td class="text-right">{{bidHistory?.bidDetails?.latestBidAmount ?
                                            bidHistory?.bidDetails?.latestBidAmount: 'Not Available'}}<span
                                                class="text-danger"></span></td>
                                    </tr>
                                    <tr>
                                        <td>Your Bid</td>
                                        <td class="text-right">{{bidHistory?.bidDetails?.userBidAmount ?
                                            bidHistory?.bidDetails?.userBidAmount : 'Not Available'}}<span
                                                class="text-danger"></span></td>
                                    </tr>

                                    <tr>
                                        <td colspan="2" class="text-center" style="color: #dc3545;"
                                            *ngIf="bidHistory ? bidHistory?.bidDetails?.latestBidAmount === bidHistory?.bidDetails?.userBidAmount : false">
                                            <b>You are highest bidder</b></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="bold">Make Pre Bid </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">
                                            <div class="bid-info">
                                                <button class="btn btn-outline-secondary"
                                                    (click)="decreaseVal('custome')">-</button>
                                                <div class="form-control readonly-default center-content"
                                                    id="pre-bid-val">{{customBid}}</div>
                                                <button class="btn btn-outline-secondary"
                                                    (click)="increaseVal('custome')">+</button>
                                                <a (click)="showConfirmation()"
                                                    class="primary-btn primary-btn_border-round details-btn primary-btn__d-block"
                                                    style="cursor:pointer;"><span>Pre Bid</span></a>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card mt-4">
                    <div>
                        <div class="table-responsive table-card table-style-2">
                            <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" colspan="2"><span *ngIf="isAuctionVehicle">Auction
                                            </span>Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td class="text-right"><span
                                                *ngIf="isAuctionVehicle">{{auctionDetail?.title}}</span><span
                                                *ngIf="!isAuctionVehicle">N/A</span></td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td class="text-right">{{vehicleDetails?.branchs}}</td>
                                    </tr>
                                    <tr>
                                        <td>Sale Date</td>
                                        <td class="text-right"><span
                                                *ngIf="isAuctionVehicle">{{auctionDetail?.auctionDate |
                                                date}}</span><span *ngIf="!isAuctionVehicle">N/A</span></td>
                                    </tr>
                                    <tr>
                                        <td>Time Left</td>
                                        <td class="text-right"><span class="text-danger"><span
                                                    *ngIf="isAuctionVehicle">{{transform(auctionDetail?.auctionDate)}}</span><span
                                                    *ngIf="!isAuctionVehicle">N/A</span></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card col-sm-12 mt-5" *ngIf="videos?.length>=1">
                    <div class="card-body">
                        <div>
                            <label for="priceForm" style="cursor: pointer;"
                                (click)="viewExteriorVedio = !viewExteriorVedio">Click here to <span
                                    *ngIf="!viewExteriorVedio">see</span><span *ngIf="viewExteriorVedio">hide</span>
                                exterior video</label>
                            <div *ngIf="viewExteriorVedio">
                                <video width="400" controls>
                                    <source [src]="videos[0]" type="video/mp4">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-sm-12 mt-5" *ngIf="videos?.length>=2">
                    <div class="card-body">
                        <div>
                            <label for="priceForm" style="cursor: pointer;"
                                (click)="viewInteriorVedio = !viewInteriorVedio">Click here to <span
                                    *ngIf="!viewInteriorVedio">see</span><span *ngIf="viewInteriorVedio">hide</span>
                                interior video</label>
                            <div *ngIf="viewInteriorVedio">
                                <video width="400" controls>
                                    <source [src]="videos[1]" type="video/mp4">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="h3-heading">More Vehicles</h3>
                <div class="my-vehicle">
                    <div class="product-list-new product-card-view">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let car of inventoryList">
                                <div class="product-item product-item__style-2 product-item__border-radius-card"
                                    style="margin: 6px;">
                                    <div class="product-image">
                                        <img  [lazyLoad]="sharedService.cloudFrontUrl('400x300',car?.singleImages)"
                                        [defaultImage]="car?.singleImages ? car?.singleImages  : '../../../assets/img/default-car123123.jpg'"
                                        />
                                    </div>
                                    <div class="product-header">
                                        <h4 class="product-title capitalize" routerLink="/vehicle-details"
                                            style="cursor: pointer !important;" [queryParams]="{id:car?._id}">
                                            {{car?.carDescription}}</h4>
                                        <div class="product-main-info">
                                            <h5 class="product-title-info">Starting Bid: <span
                                                    class="product-title-info-value">AED</span>
                                                <span class="product-title-info-highlited">{{car?.startingBid}}</span>
                                            </h5>
                                            <ul class="product-details">
                                                <li>
                                                    <h5 class="product-title-info">Lot No: <span
                                                            class="product-title-info-value"
                                                            routerLink="/vehicle-details"
                                                            style="cursor: pointer !important;"
                                                            [queryParams]="{id:car?._id}"><span
                                                                class="hov">{{car?.lotNo}}</span></span></h5>
                                                </li>
                                                <li>
                                                    <h5 class="product-title-info">Vin No: <span
                                                            class="product-title-info-value"
                                                            routerLink="/vehicle-details"
                                                            style="cursor: pointer !important;"
                                                            [queryParams]="{id:car?._id}">
                                                            <span class="hov">{{car?.vin.length>6 ? '**' :
                                                                ''}}{{car?.vin.length > 6 ? car?.vin.slice(-6) :
                                                                car?.vin}}</span></span></h5>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="product-discription">
                                            <li>
                                                <img src="../../../../../assets/img/icons/vector-1.webp" />
                                                <h6>{{car?.engines}} ltr</h6>
                                            </li>
                                            <li>
                                                <img src="../../../../../assets/img/icons/vector-2.webp" />
                                                <h6>{{ (car?.mileage === 0 || car?.mileage === 1) ? '-' : car?.mileage + ' mi' }}</h6>
                                            </li>
                                            <li>
                                                <img src="../../../../../assets/img/icons/vector-3.webp" />
                                                <h6>{{car?.fueltypes}}</h6>
                                            </li>
                                            <li>
                                                <img src="../../../../../assets/img/icons/vector-4.webp" />
                                                <h6>{{car?.drivetypes}}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-footer">
                                        <div class="product-button">
                                            <a routerLink="/vehicle-details" [queryParams]="{id:car?._id}"
                                                class="primary-btn"><span>Pre
                                                    Bid</span></a>
                                                    &nbsp;
                                            <a (click)="onClickDetails(car?._id)"
                                                class="secondary-btn secondary-btn__black" style="color: white !important;"><span>details</span></a>
                                        </div>

                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #callAPIDialog>
    <div class="modal-body text-center">
        <div class="row">
            <div class="col-12">
                <img src="../../assets/images/warning.svg" style="height: 150px;" class="img-fluid mx-auto d-block"
                    alt="Warning Image">
            </div>
            <div class="col-12 mt-3">
                <h2><b>Warning</b></h2>
            </div>
            <div class="col-12 mt-2">
                <p>Please Login as a buyer.</p>
            </div>
            <div class="col-12 mt-3">
                <button type="button" class="btn btn-primary" (click)="dialogRef.hide()">OK</button>
            </div>
        </div>
    </div>
</ng-template>