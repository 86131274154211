<!-- Log In Modal -->
<div class="authPanelPopup auth modal fade" id="logInPopup" tabindex="-1" role="dialog" aria-labelledby="logInPopup"
    data-backdrop="static" style="overflow-y: hidden;" aria-hidden="true"
    [ngClass]="{'termCondition':termAndCondition}">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="header-stickey" *ngIf="!termAndCondition">
                    <img src="assets/img/icons/auth-matrix-2.svg" />
                </div>
                <button type="button" id="btnClose" (click)="closePopUp()" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body insideScroller" id="scrolltop">
                <div class="container-fluid">
                    <div class="form" *ngIf="termAndCondition">
                        <div>
                            <h6><span tooltip="back" style="cursor: pointer;" (click)="BackToSignUp()" class="mr-3"><i
                                        class="fa-solid fa-arrow-left"></i></span>Agreement</h6>
                        </div>
                        <app-terms-and-condition-content></app-terms-and-condition-content>
                        <div>
                            <a href="JavaScript:void(0)" class="btn-block primary-btn"
                                (click)="acceptTermCondition()"><span>Accept</span></a>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="left-section">

                                <!--Sign Up popup Section-->
                                <form class="form" *ngIf="SignUpPopupSection" [formGroup]="userForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Sign Up</h6>
                                        <h4>SIgn Up For Free</h4>
                                    </div>
                                    <div class="input-body">
                                        <!-- Become a section -->
                                        <div class="form-group" *ngIf="!buyerTab1 && !sellerTab1">
                                            <label class="form-label">Become a</label>
                                            <div class="mt-3">
                                                <a href="JavaScript:void(0)" (click)="buyerTabActive()"
                                                    [ngClass]="{'active' : buyerTab1 == true}"
                                                    class="btn-block primary-btn">
                                                    <span>Buyer</span>
                                                </a>
                                                <a href="JavaScript:void(0)" (click)="sellerTabActive()"
                                                    [ngClass]="{'active' : sellerTab1 == true}"
                                                    class="btn-block primary-btn">
                                                    <span>Seller</span>
                                                </a>
                                            </div>
                                        </div>

                                        <!-- Rest of the form -->
                                        <div *ngIf="buyerTab1 || sellerTab1">
                                            <div class="text-danger" *ngIf="!buyerTab1 && !sellerTab1">
                                                Please select either Buyer or Seller
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="PhoneNumber">Phone Number</label>
                                                <ngx-intl-tel-input style="display: block; width: 100%;" (ngModelChange)="phoneValidityCheck()" 
                                                    id="PhoneNumber" [ngModelOptions]="{updateOn: 'blur'}"
                                                    [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[ SearchCountryField.Iso2, SearchCountryField.Name ]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.UnitedArabEmirates"
                                                    [maxLength]="15" [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National" name="PhoneNumber"
                                                    formControlName="phone"
                                                    [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null">
                                                </ngx-intl-tel-input>
                                                <div class="text-danger"
                                                    *ngIf="controlTypePhone === 'phone' && validPhone == false">
                                                    {{errorMessageForPhone}}
                                                    <a href="JavaScript:void(0)" (click)="LogInPopupActive()">Sign
                                                        In</a>
                                                </div>
                                            </div>

                                            <!-- Email input -->
                                            <div class="form-group">
                                                <label class="form-label" for="EmailorPhoneNumber">Email</label>
                                                <input type="email" name="EmailorPhoneNumber"
                                                    (keyup)="onTypeEmail($event)" id="EmailorPhoneNumber"
                                                    formControlName="email" autocomplete="off"
                                                    placeholder="Enter Email Address or Phone Number"
                                                    (blur)="emailValidityCheck()" class="form-control"
                                                    style="height: 58px; border-radius: 0px; width: 100%;"
                                                    [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null" />
                                                <div class="text-danger"
                                                    *ngIf="controlTypeEmail === 'email' && validEmail == false">
                                                    {{errorMessageForEmail}}
                                                    <a href="JavaScript:void(0)" (click)="LogInPopupActive()">Sign
                                                        In</a>
                                                </div>
                                            </div>

                                            <!-- Password input -->
                                            <div class="form-group">
                                                <label class="form-label">Password</label>
                                                <div>
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [type]="hide ? 'password' : 'text'"
                                                            formControlName="password" placeholder="Enter Password"
                                                            [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null">
                                                        <!-- <button mat-icon-button matSuffix (click)="hide = !hide"
                                                            [attr.aria-label]="'Hide password'"
                                                            [attr.aria-pressed]="hide">
                                                            <mat-icon>{{hide ? 'visibility_off' :
                                                                'visibility'}}</mat-icon>
                                                        </button> -->


                                                        <button mat-icon-button matSuffix (click)="hide = !hide"
                                                        [attr.aria-label]="'Hide password'"
                                                        [attr.aria-pressed]="hide">
    <i class="fa" [ngClass]="hide ? 'fa-eye-slash' : 'fa-eye'"></i>
</button>

                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.password.errors" class="text-danger">
                                                    <span *ngIf="f.password.errors.pattern">
                                                        Password must contain at least one Uppercase, Lowercase, Digit
                                                        and One Special symbol
                                                    </span>
                                                </div>
                                            </div>

                                            <!-- Retype password input -->
                                            <div class="form-group">
                                                <label class="form-label">Retype Password</label>
                                                <div>
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [type]="hide1 ? 'password' : 'text'"
                                                            formControlName="confirmPassword"
                                                            placeholder="Enter Retype Password"
                                                            [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null">
                                                        <!-- <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                                            [attr.aria-label]="'Hide password'"
                                                            [attr.aria-pressed]="hide1">
                                                            <mat-icon>{{hide1 ? 'visibility_off' :
                                                                'visibility'}}</mat-icon>
                                                        </button> -->

                                                        <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                                        [attr.aria-label]="'Hide password'"
                                                        [attr.aria-pressed]="hide1">
    <i class="fa" [ngClass]="hide1 ? 'fa-eye-slash' : 'fa-eye'"></i>
</button>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <!-- Term Checkbox -->
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="form2Example31" checked formControlName="term"
                                                    [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null" />
                                                <label class="form-check-label"> By clicking this box, I agree that I am
                                                    at least 18 years of age and that I have
                                                    read and agreed to the Al-Qaryah's Member
                                                    <a href="/terms-and-conditions" target="_blank"> Terms &
                                                        Conditions</a> and
                                                    <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                                                </label>
                                            </div>

                                            <!-- Submit button -->
                                            <div>
                                                <a href="JavaScript:void(0)" id="btnSignUp"
                                                    class="btn-block primary-btn"
                                                    [ngClass]="{'disabled': !isFormValid() || (errorMessageForEmail || errorMessageForPhone) || (!buyerTab1 && !sellerTab1)}"
                                                    (click)="SignUpOTPVerifyPopupActive()"
                                                    [attr.disabled]="!buyerTab1 && !sellerTab1 ? true : null">
                                                    <span>Sign Up</span>
                                                </a>
                                            </div>

                                            <!-- Register buttons -->
                                            <div class="text-center">
                                                <p>Already a member? <a href="JavaScript:void(0)"
                                                        (click)="LogInPopupActive(); isVerifyOtpChangePassword = false">Sign
                                                        In</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </form>



                                <!--Log In popup Section-->
                                <form *ngIf="LogInPopupSection" class="loginPopup form" [formGroup]="loginForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6><b>Login</b></h6>
                                        <h4><b>Welcome Back</b></h4>
                                    </div>
                                    <div class="input-body">
                                        <div class="form-group" *ngIf="!buyerTab && !sellerTab">
                                            <label class="form-label">As a</label>
                                            <div class="mt-3">
                                                <a href="JavaScript:void(0)" (click)="buyerSignInTabActive()"
                                                    [ngClass]="{'active' : buyerTab == true}"
                                                    class=" btn-block primary-btn"><span>Buyer</span></a>
                                                <a href="JavaScript:void(0)" (click)="sellerSignInTabActive()"
                                                    [ngClass]="{'active' : sellerTab == true}"
                                                    class=" btn-block primary-btn"><span>Seller</span></a>
                                            </div>
                                        </div>
                                        <!-- Email input -->
                                        <div *ngIf="buyerTab || sellerTab">
                                            <div class="form-group">
                                                <label class="form-label" for="EmailorPhoneNumber">Email or Phone
                                                    Number</label>
                                                <input type="email" id="EmailorPhoneNumber" name="EmailorPhoneNumber"
                                                    autocomplete="off" placeholder="Enter Email Address or Phone Number"
                                                    class="form-control" formControlName="email"
                                                    (keyup)="onTypeEmail($event)" (keydown)="onTypeEmail($event)"
                                                    [ngClass]="{'leftPadding':onlyNumbers==true}"
                                                    style="height: 50px; border-radius: 0px; width: 100%;" />
                                                <div *ngIf="isNumberEnter" class="text-warning">Please enter your number
                                                    without country code</div>
                                            </div>

                                            <!-- Password input -->
                                            <div class="form-group">
                                                <label class="form-label">Password</label>
                                                <div>
                                                    <mat-form-field appearance="fill" >
                                                        <input matInput [type]="hide2 ? 'password' : 'text'"
                                                            formControlName="password" placeholder="Enter Password"
                                                            autocomplete="off">
                                                        <!-- <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                                            [attr.aria-label]="'Hide password'"
                                                            [attr.aria-pressed]="hide2">
                                                            <mat-icon>{{hide2 ? 'visibility_off' :
                                                                'visibility'}}</mat-icon>
                                                        </button> -->

                                                        <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                                        [attr.aria-label]="'Hide password'"
                                                        [attr.aria-pressed]="hide2">
    <i class="fa" [ngClass]="hide2 ? 'fa-eye-slash' : 'fa-eye'"></i>
</button>
                                                    </mat-form-field>

                                                </div>

                                            </div>

                                            <div class="f-pass form-group">
                                                <a href="javascript:void(0)" (click)="ResetPasswordPopupActive()">Forgot
                                                    password?</a>
                                            </div>

                                            <!-- Submit button -->
                                            <div>
                                                <button class="btn-block primary-btn" (click)="signIn()"><span>Sign
                                                        In</span></button>
                                                <!-- <a ></a> -->
                                            </div>
                                            <!-- Back button -->
                                            <div>
                                                <button class="btn-block secondary-btn mt-2 mb-2" (click)="goBack()">
                                                    <span>
                                                        <i class="fa-solid fa-arrow-left"></i> &nbsp; Back
                                                    </span>
                                                </button>
                                            </div>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Not a member? <a href="javascript:void(0)"
                                                    (click)="SignUpPopupActive(); isVerifyOtpChangePassword = false">Sign
                                                    Up</a></p>
                                        </div>
                                    </div>
                                </form>
                                <!--End Log In popup Section-->

                                <!--Reset Password popup Section-->
                                <div class="form" *ngIf="ResetPasswordPopupSection">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <!-- <h5>Forgot Password</h5> -->
                                        <h4><b>Reset Your Password</b></h4>
                                    </div>

                                    <div class="input-body" *ngIf="isResetPassUsingPhone">
                                        <h6>Select User Type</h6>
                                        <div class="mt-4">
                                            <a href="JavaScript:void(0)" class="mb-4"
                                                *ngIf="isUserSelectedForReset && userTypeForPassReset === 0"
                                                (click)="userTypeForPassReset == 0 ? isUserSelectedForReset = false : userTypeForPassReset = 0"><span>Back</span></a>
                                            <a href="JavaScript:void(0)" style="margin-bottom: 10px !important;"
                                                *ngIf="!isUserSelectedForReset"
                                                (click)="isResetPassUsingPhone = isResetPassUsingPhone === true? false : true "><span>Back
                                                    To Email</span></a>
                                        </div>
                                        <div class="mt-3">
                                            <a href="JavaScript:void(0)" *ngIf="!isUserSelectedForReset"
                                                (click)="isSeller = true; isUserSelectedForReset = true"
                                                class="btn-block primary-btn"><span>Seller</span></a>
                                            <a href="JavaScript:void(0)" *ngIf="!isUserSelectedForReset"
                                                (click)="isSeller = false ; isUserSelectedForReset = true"
                                                class="btn-block primary-btn"><span>Buyer</span></a>
                                        </div>
                                    </div>
                                    <div class="input-body" *ngIf="!isResetPassUsingPhone || isResetPassUsingPhone">
                                        <!-- Email input -->
                                        <div *ngIf="!isResetPassUsingPhone">
                                            <label class="form-label" for="email">Email</label>
                                            <input type="email" id="email" (keyup)="onTypeEmail($event)"
                                                [(ngModel)]="resetEmail" placeholder="Enter email" class="form-control"
                                                style="height: 58px; border-radius: 0px;" />

                                        </div>
                                        <div *ngIf="isUserSelectedForReset && isResetPassUsingPhone">
                                            <div class="form-group">
                                                <label class="form-label" for="phone">Phone Number</label>
                                                <ngx-intl-tel-input (ngModelChange)="phoneValidityCheck()"
                                                    [ngModelOptions]="{updateOn: 'blur'}" [cssClass]="'custom'"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true" [searchCountryField]="[
                                                  SearchCountryField.Iso2,
                                                  SearchCountryField.Name
                                                ]" [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.UnitedArabEmirates"
                                                    [maxLength]="15" [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National" name="phone"
                                                    [formControl]="resetPhone">
                                                </ngx-intl-tel-input>
                                            </div>

                                        </div>
                                        <div class="mb-2">
                                            <a href="JavaScript:void(0)">
                                                <span *ngIf="isResetPassUsingPhone"
                                                    (click)="userTypeForPassReset = 0; isResetPassUsingPhone = false; userTypeForPassReset = 0; isUserSelectedForReset = false; resetPhone.patchValue(null)">Switch
                                                    to Email</span>
                                                <span *ngIf="!isResetPassUsingPhone"
                                                    (click)="isResetPassUsingPhone = true; userTypeForPassReset = 0; resetEmail = ''">Switch
                                                    to Phone</span>
                                            </a>
                                        </div>
                                        <div *ngIf="userNotFound">
                                            <label class="form-label" style="color: red;">User not found.</label>
                                        </div>

                                        <!-- Submit button -->
                                        <div
                                            *ngIf="isResetPassUsingPhone ? isUserSelectedForReset && isResetPassUsingPhone : true">
                                            <a href="JavaScript:void(0)" (click)="GetUserByEmail()"
                                                [ngClass]="{'disabled': (!isResetPassUsingPhone && !resetEmail) || !resetEmail ? !this.resetPhone.valid : !resetEmail}"
                                                class="btn-block primary-btn"><span>send Verification Code</span></a>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Back to <a href="JavaScript:void(0)" (click)="LogInPopupActive()">Log
                                                    In</a></p>
                                        </div>
                                    </div>
                                </div>

                                <form class="form" *ngIf="OTPVerifyPopupSection" [formGroup]="otpForm">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Verification</h6>
                                        <h4>Enter Verification Code</h4>
                                    </div>
                                    <div class="input-body">
                                        <div class="form-group"
                                            *ngIf="userEmailOtpVerification && userPhoneOtpVerification">
                                            <label class="form-label"><b>OTP</b> Verification code sent to your mail and
                                                phone. Please fill and verify both or at least fill one.</label>
                                        </div>
                                        <div class="form-group"
                                            *ngIf="userEmailOtpVerification && !userPhoneOtpVerification">
                                            <label class="form-label"><b>OTP</b>Verification code sent to your E-mail.
                                                Please fill in the below boxes.</label>
                                        </div>
                                        <div class="form-group"
                                            *ngIf="!userEmailOtpVerification && userPhoneOtpVerification">
                                            <label class="form-label"><b>OTP</b>Verification code sent to your phone.
                                                Please fill in the below boxes.</label>
                                        </div>

                                        <!-- Email input -->
                                        <div class="form-group mb-0" *ngIf="userEmailOtpVerification">
                                            <label class="form-label">
                                                <!-- +971 123 123 1234 --><b>E-Mail: &nbsp;&nbsp;</b>
                                                {{registeredUser?.email}}</label>
                                            <div class="opt-section">
                                                <input type="text" maxlength="1" id="first"
                                                    [readOnly]="readonlyEmailInput" placeholder="-"
                                                    formControlName="first"
                                                    (keydown)="checkBackPress($event,1, 'first', 'first')"
                                                    (keyup)="moveToAnother($event,1, 'second', 'first')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" id="second"
                                                    [readOnly]="readonlyEmailInput" placeholder="-"
                                                    formControlName="second"
                                                    (keydown)="checkBackPress($event,2, 'first', 'second')"
                                                    (keyup)="moveToAnother($event,2, 'third', 'second')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" id="third"
                                                    [readOnly]="readonlyEmailInput" placeholder="-"
                                                    formControlName="third"
                                                    (keydown)="checkBackPress($event,3, 'second', 'third')"
                                                    (keyup)="moveToAnother($event,3, 'forth', 'third')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" id="forth"
                                                    [readOnly]="readonlyEmailInput" placeholder="-"
                                                    formControlName="forth"
                                                    (keydown)="checkBackPress($event,4, 'third', 'forth')"
                                                    (keyup)="moveToAnother($event,4, '', 'forth')"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="opt-counter-section form-group" *ngIf="userEmailOtpVerification">
                                            <span class="no-counter">00:30</span>
                                            <span>Have you received the codes? <a href="JavaScript:void(0)"
                                                    (click)="resendOTPAfterRegistration('email')">Resend</a></span>
                                        </div>
                                        <div class="form-group mb-0" *ngIf="userPhoneOtpVerification">
                                            <label class="form-label">
                                                <!-- +971 123 123 1234 -->
                                                <b>Phone: &nbsp;&nbsp;</b><span
                                                    *ngIf="isResetPassUsingPhone">{{resetPhone?.value.dialCode}}{{resetPhone?.value.number}}</span><span
                                                    *ngIf="!isResetPassUsingPhone">{{registeredUser?.countryCode}}{{registeredUser?.phone}}</span></label>
                                            <div class="opt-section">
                                                <input type="text" maxlength="1" [readOnly]="readonlyPhoneInput"
                                                    id="firstPhoneCH" placeholder="-" formControlName="firstPhoneCH"
                                                    (keydown)="checkBackPress($event,1, 'firstPhoneCH', 'firstPhoneCH')"
                                                    (keyup)="moveToAnother($event,1, 'secondPhoneCH', 'firstPhoneCH')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" [readOnly]="readonlyPhoneInput"
                                                    id="secondPhoneCH" placeholder="-" formControlName="secondPhoneCH"
                                                    (keydown)="checkBackPress($event,2, 'firstPhoneCH', 'secondPhoneCH')"
                                                    (keyup)="moveToAnother($event,2, 'thirdPhoneCH', 'secondPhoneCH')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" [readOnly]="readonlyPhoneInput"
                                                    id="thirdPhoneCH" placeholder="-" formControlName="thirdPhoneCH"
                                                    (keydown)="checkBackPress($event,3, 'secondPhoneCH', 'thirdPhoneCH')"
                                                    (keyup)="moveToAnother($event,3, 'forthPhoneCH', 'thirdPhoneCH')"
                                                    class="form-control" />
                                                <input type="text" maxlength="1" [readOnly]="readonlyPhoneInput"
                                                    id="forthPhoneCH" placeholder="-" formControlName="forthPhoneCH"
                                                    (keydown)="checkBackPress($event,4, 'thirdPhoneCH', 'forthPhoneCH')"
                                                    (keyup)="moveToAnother($event,4, '', 'forthPhoneCH')"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                        <div class="opt-counter-section form-group" *ngIf="userPhoneOtpVerification">
                                            <span class="no-counter">00:30</span>
                                            <span>Have you received the codes? <a href="JavaScript:void(0)"
                                                    (click)="resendOTPAfterRegistration('phone')">Resend</a></span>
                                        </div>
                                        <span style="color: red;">{{otpErrorMessage}}</span>
                                        <!-- Submit button -->
                                        <div *ngIf="isVerifyOtpChangePassword">
                                            <a href="JavaScript:void(0)" class="btn-block primary-btn"
                                                [ngClass]="{'disabled':isEnableVerifyBtn}"
                                                (click)="ForgotPasswordPopupActive()"><span>Verify</span></a>
                                        </div>

                                        <div *ngIf="!isVerifyOtpChangePassword">

                                            <a href="JavaScript:void(0)" class="btn-block primary-btn"
                                                [ngClass]="{'disabled':!enableContinueBtn}"
                                                (click)="ContinueToComplateProfile()"><span>Continue</span></a>
                                        </div>

                                    </div>
                                </form>
                                <!-- End OTP Verification popup Section-->


                                <!--Forgot Password popup Section-->
                                <form class="form" [formGroup]="resetPasswordForm" *ngIf="ForgotPasswordPopupSection">
                                    <div>
                                        <img src="assets/img/logo.png" class="logo" />
                                        <h6>Change Password</h6>
                                        <h4>Change Your Password</h4>
                                    </div>
                                    <div class="input-body">


                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="Password">Enter New Password</label>
                                            <input type="password" name="Password" formControlName="password"
                                                id="Password" placeholder="Enter New Password" class="form-control" />

                                            <div *ngIf="p.password.errors" class="text-danger">
                                                <span *ngIf="p.password.errors.pattern">
                                                    Password must contain at least one Uppercase, Lowercase, Digit and
                                                    One Special symbol
                                                </span>
                                            </div>

                                        </div>

                                        <!-- Password input -->
                                        <div class="form-group">
                                            <label class="form-label" for="RePassword">Retype New Password</label>
                                            <input type="password" name="RePassword" formControlName="confirmPassword"
                                                id="RePassword" placeholder="Retype New Password"
                                                class="form-control" />

                                            <div *ngIf="p.confirmPassword.errors" class="text-danger">
                                                <span *ngIf="p.confirmPassword.errors.pattern">
                                                    Password must contain at least one Uppercase, Lowercase, Digit and
                                                    One Special symbol
                                                </span>
                                            </div>

                                        </div>

                                        <!-- Submit button -->
                                        <div>
                                            <a href="JavaScript:void(0)" (click)="UpdatePasswordFun()"
                                                class="btn-block primary-btn"
                                                [ngClass]="{'disabled': !resetPasswordForm.valid || isPasswordMatchFun()}"><span>Update
                                                    Password</span></a>
                                        </div>

                                        <!-- Register buttons -->
                                        <div class="text-center">
                                            <p>Back to <a href="#!">Log In</a></p>
                                        </div>
                                    </div>
                                </form>
                                <!--Forgot Password popup Section-->
                            </div>
                        </div>
                        <div class="col-lg-6 d-none d-lg-block popupCarSection ">
                            <div class="right-section" *ngIf="!termAndCondition">
                                <div class="bg"></div>
                                <div class="stickey">
                                    <img src="assets/img/auth-matrix-1.png" />
                                </div>
                                <div class="car-img">
                                    <img class="image-responsive" src="assets/img/auth-car-1.webp" />
                                </div>
                                <div class="blur-bg-section">
                                    <div class="blur-bg"></div>
                                    <h2>
                                        Unlock <span>unbeatable</span> deals on <span> top-quality </span> cars at our
                                        <span> online auction.</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="stickey-2" *ngIf="!termAndCondition">
                                <img src="assets/img/icons/testimonial-matrix-right-1.svg" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Log In Modal -->


<div class="modal fade" id="msgPopup" tabindex="-1" role="dialog" aria-labelledby="msgPopup" aria-hidden="true">
    <div class="modal-msg modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="icon-section">
                    <i class="fa fa-check"></i>
                </div>
                <h3>Vehicle added successfully!
                    you are able to see it when it
                    approved from admin.</h3>
                <div class="button-section">
                    <a href="" class="primary-btn primary-btn__d-block" data-dismiss="modal"><span>Done</span></a>
                </div>
            </div>
        </div>
    </div>
</div>