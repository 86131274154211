<div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}<br>
    <span *ngIf="reason">
        <label class="mt-4">Remarks</label>
        <textarea class="form-control" [(ngModel)]="reasonModel" rows="5"></textarea>
    </span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="confirm()">Confirm</button>
</div>