<section class="policy-inner-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="policy">
                    <h4>General Terms and conditions for seller and buyer </h4>

                    <p>These terms and conditions (“Terms”) set forth the general terms and conditions of your use of
                        the
                        alqaryahauction.com website (“Website”), “alqaryah auction” mobile application (“Mobile
                        Application”) and any of
                        their related products and services (collectively, “Services”). These Terms constitute a legally
                        binding agreement
                        between Seller (“Seller”, "First Party”, “Principal Supplier” or “Vendor”), Buyer (“Buyer”,
                        “Second Party” or
                        “Purchaser”) and Al Qaryah Cars Auctions LLC (all branches) (“Auction Company” or “we”). If you
                        (either as Seller or
                        Buyer) are entering into these Terms on behalf of a business or other legal entity, you
                        represent that you have the
                        authority to bind such entity to these Terms. If you do not have such authority, or if you do
                        not agree with the
                        terms of these Terms, you must not accept these Terms and may not access and use the Services.
                        By accessing and
                        using the Services, you acknowledge that you have read, understood, and agree to be bound by
                        these Terms.</p>

                    <p>All Lots/Items in auction are supplied by the First Party (the Vendor or Seller) and not by the
                        Auction Company. By
                        using the Website, Mobile Application, or other on-site facilities, you are interacting with
                        entities promoting the
                        sale or provision of goods and services. All related terms, guarantees, or commitments
                        pertaining to such
                        transactions are strictly established between the Second Party (Buyer or Purchaser) and the
                        First Party (Vendor or
                        Seller). </p>

                    <p>The Auction Company is just an intermediary that facilitates both Seller and Buyer to sell and
                        purchase through
                        auction process either on-site or through online bidding.  Buyer and Seller agrees that, to the
                        extent permitted by
                        applicable shall, the Auction Company is not legally responsible for any loss, damage, whether
                        tangible or
                        intangible, or any other claims of any kind resulting from any of these transactions taking
                        place on its Website or
                        Mobile Application. </p>




                    <h4>Terminologies :</h4>
                    <p>Items/Goods/Service/Lot:The goods or services listed on Auction Company’s Website or Mobile
                        Application by Vendors.   </p>
                    <p>Bidder: Anyone who places a bid either on-site or online. </p>
                    <p>Auction Services: Any service that auction company provides to both Seller and Buyer to
                        facilitate auction sales. </p>
                    <p>Runs: The number of times the item is auctioned. Each item is given a run limit after which the
                        listing fees apply.   </p>
                    <p>Listing fees: Fees auction company charges Seller for listing the item in-spite of the sales
                        status (sold/Unsold) after completion of the runs.  </p>
                    <p>Auction Fees: Fees auction company charges Seller if the car is sold.  </p>
                    <p>Own-Bid:When a Seller bids on his own vehicle which he/she has listed on the Auction Company
                        Website either online or on-site.   </p>
                    <p>Storage/Parking Fees: Fees auction company charges either Seller or Buyer for crossing the
                        deadline for picking up the tangible Items.</p>
                    <p>Gate-pass: A Document which Auction Company issues to Seller or Buyer for either dropping off or
                        picking up the items at the designated warehouse.   </p>

                    <h4>Accounts And Membership </h4>
                    <p>You must be at least 21 years of age to use the Services. By using the Services and by agreeing
                        to these Terms you warrant and represent that you are at least 21 years of age. If you create an
                        account on the Services, you are responsible for maintaining the security of your account and
                        you are fully responsible for all activities that occur under the account and any other actions
                        taken in connection with it. We may monitor and review new accounts before you can access
                        account with full functionalities and start using the Services. Providing false contact
                        information of any kind may result in the termination of your account. You must immediately
                        notify us of any unauthorized uses of your account or any other breaches of security. We will
                        not be liable for any acts or omissions by you, including any damages of any kind incurred as a
                        result of such acts or omissions. We may suspend, disable, or delete your account (or any part
                        thereof), and settle any outstanding balances, if we determine that you have violated any
                        provision of these Terms or that your conduct or content would tend to damage our reputation and
                        goodwill. If we delete your account for the foregoing reasons, you may not re-register for our
                        Services. We may block your email address and internet protocol address to prevent further
                        registration. </p>
                    <p>To register either as a Seller or Buyer just click on the sign up and follow the steps for
                        registration. Make sure to upload your legal documentation to verify your identity and for the
                        purpose of invoicing and ownership transfer of Items sold. (Legal document submission for
                        identity verification)  </p>
                    <p>The Auction Company will ensure to keep your personal or company data secure from threats as per
                        the Privacy Policy.</p>

                    <h4>Accuracy of Item’s information </h4>
                    <p>The Seller shall have sole responsibility for the accuracy, quality, integrity, legality,
                        reliability, and appropriateness of the item. Auction Company may monitor and review the Content
                        of the items uploaded by Seller and try its best to improve the quality of
                        information/description of items uploaded by Seller. You (Seller) grant us permission to access,
                        copy, distribute, store, transmit, reformat, display and perform the Content of your user
                        account solely as required for the purpose of providing the Services to you. Without limiting
                        any of those representations or warranties, we have the right, though not the obligation, to, in
                        our own sole discretion, refuse or remove any item that, in our reasonable opinion, violates any
                        of our policies or is in any way harmful or objectionable.   </p>
                    <p>Occasionally there may be information on the Services or Items that contain typographical errors,
                        inaccuracies or omissions that may relate to product descriptions, pricing, promotions and
                        offers. Auction Company reserves the right to correct any errors, inaccuracies or omissions, and
                        to change or update information or cancel sales if any information on the Items or item is found
                        inaccurate at any time without prior notice. We undertake no obligation to update, amend or
                        clarify information on the items including, without limitation, pricing information, except as
                        required by law.  

                    </p>

                    <h1>Items/services details. </h1>
                    <h4>Seller services: </h4>




                    <p>Every Seller will be assigned a plan during user identity verification after the Seller submits
                        registration. The Seller can see the plan in its profile once the auction company verifies the
                        Seller.   </p>
                    <p>The Seller will be required to add a car to the website for the approval process with complete
                        detail. The Seller will be asked to give a reserve price for the car being added. The Seller
                        acknowledges and agrees that the reserve price he/she has added or accepted during approval
                        process is going to be the price on which the Auction Company will approve the sale of the car
                        and sale value could be equal to or greater than the reserve prices added or accepted by
                        Seller.  
                    </p>
                    <p>Visit the Seller page available on the menu to see the base plan that is applicable for every
                        Seller. 
                    </p>


                    <ul>
                        Seller fees are as follows:
                        <div class="pl-10">

                            <li><span style="font-size: 15px; font-weight: 600;">Listing fees: </span>
                                Listing Fee will be applicable, whether the Seller Item(s) are sold or not, for each
                                Item
                                that the Seller enters the Auction only for assigned runs as per your (Seller) plan
                                assigned. If Seller wishes to withdraw the item(s) then auction company will apply the
                                cancellation of service policy which is described in this agreement. Seller must pick up
                                Item(s) the same day after the payment of dues and once Seller confirms withdrawal, the
                                Auction Company will not allow remaining runs and Seller must pick up the car.
                            </li>
                            <li> <span style="font-size: 15px; font-weight: 600;">Auction fees </span>
                                The Seller acknowledges and agrees to compensate the Auction Company a commission
                                (Auction
                                Fees) on the sales of the Item(s) as defined in Seller Plan
                            </li>
                            <li><span style="font-size: 15px; font-weight: 600;">Storages fees </span>
                                Seller has 1-day free parking after the car is unsold (after 2 runs), Own-bid or
                                withdrawn
                                after which the Parking Fees will start as per your (Seller Plan).
                            </li>
                            <li><span style="font-size: 15px; font-weight: 600;">Own bid fees </span>

                                if Seller Own-Bid the car listed by him/her, the Auction Company will apply and take
                                Downpayment 20% of car value after which the sales will be cancelled and downpayment
                                will be
                                confiscated as per our cancellation policy and this amount will not be refundable and
                                complete downpayment will be considered as Auction Fees. <br>
                                If Seller is caught doing such act, auction company will reserve the rights to block the
                                Seller from selling and, if auction company wishes, will file case against the Seller.

                            </li>
                        </div>
                    </ul>
                    <h4>Buyers Services: </h4>


                    <p>Buyers must apply for a bid limit from the auction company as per the plans available. For a
                        Buyer to select a plan he/she must pay a bid limit security deposit either cash, card or bank
                        transfer to acquire the plan. Visit the Buyer page to understand the process furthermore. </p>
                    <p>The bid limit is refundable to Buyers through bank transfer or card refund (if paid by card) if
                        there is no purchase done online. In case a Buyer has purchased, the bid limit will be decreased
                        by the purchase value and the security deposit will freeze and cannot be released back to the
                        Buyer until complete dues are cleared. A Buyer can apply to pay for the purchased item(s) from
                        the security deposit amount, but it will result in cancellation of the bid limit where he/she
                        will not be able to bid for any car further. </p>


                    <li style="font-size: 25px; font-weight: 600; padding-left:50px">
                        Car's sale:
                    </li>
                    <p style="font-size: 15px; font-weight: 600; padding-left:100px">Pre-bid: </p>

                    <p class="pl-100">Buyers are given the functionality to predefine the bid before the auction starts
                        to facilitate
                        the Buyer who cannot wait for the live auction to start or don’t have enough time to participate
                        in the auction. For a Buyer to pre-bid, you just need to visit vehicle page>select auction date
                        in filter> select a car and go to the details of car> bid information section> place a higher
                        bid then starting bid or current pre bid. After pre-bidding you will be able to see the track of
                        it in your Buyer dashboard menu. </p>
                    <p class="pl-100">The Buyer is completely liable for the pre bid if done intentionally or
                        unintentionally. You
                        (Buyer) must be careful when placing any bid and make sure you cross check your bid value before
                        bidding since there is no withdrawal from bidding once you place a bid. </p>
                    <p class="pl-100">Once the auction starts the starting bid will be the highest pre-bid that a Buyer
                        has place if any. Buyer needs to make sure that their pre-bid is the highest pre bid to enable
                        the starting bid as his/Her's  </p>

                    <p class="pl-100" style="font-size: 15px; font-weight: 600;">
                        Online/Onsite Bidding:
                    </p>

                    <p class="pl-100">Auction Company will sync online, and onsite bidding and Bidder will be able to
                        place bid on the
                        same car from onsite auction location and online. </p>
                    <p class="pl-100">Every car has a starting bid and a reserve bid. Starting bid could be redefined by
                        pre-bids while
                        reserve bid is placed by Sellers to approve the sales of the item once the bid value reaches the
                        reserve bid amount. </p>









                    <p class="pl-100" style="font-size: 15px; font-weight: 600;">Under Approval: </p>

                    <p class="pl-100">When an item’s last bid is less than reserve price (status: Under-Approval)
                        Auction Company will
                        consider the car as Under Approval, where Seller will be asked to either accept, reject or
                        counter offer the last Bidder. If the last bid or counteroffer (of Seller) is accepted by Seller
                        or Bidder, the item will be considered as sold under the same terms and conditions of this
                        agreement. The Buyer must take great care when accepting the offer, if any, by the Seller during
                        the Under Approval process since there will be no turning back for the Sales. </p>
                    <p class="pl-100">Auction Company will only allow Seller or Buyer to close the deal within 24 hours
                        if the car is
                        Under Approval from the auction time and after which the sale will be considered as unsold </p>






                    <li class="pl-10" style="font-size: 15px; font-weight: 600;">Storage </li>
                    <p class="pl-10">
                        Auction Company will Charge the Buyer Storage Fees of 50 AED per day if Buyer fails to pick up
                        the car within 5 days from the sale/auction date.
                    </p>
                    <h1>Disclaimer of Warranty </h1>
                    <h4>General Warranties </h4>

                    <p>You agree that such Items/Services are provided on an “as is” and “as available” basis. We
                        expressly disclaim all warranties of any kind, whether express or implied, including but not
                        limited to the implied warranties of merchantability, fitness for a particular purpose and
                        non-infringement. We make no warranty that the Item will meet your requirements, or that the
                        Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to
                        the results that may be obtained from the use of the items/service or as to the accuracy or
                        reliability of any information obtained through the Service or that defects in the item will be
                        corrected. No advice or information, whether oral or written, obtained by you from us or through
                        the Service shall create any warranty not expressly made herein. The majority of the item (Cars)
                        are accident/salvaged sold without any prior inspection on As-Is bases and accident cars are not
                        eligible to get inspection/passing test from any Vehicle passing Departments
                        (Tasjeel/RTA/Maroor/etc.) in UAE. For the Item(car) that is repaired will be mentioned by us
                        before auction if passing not yet completed for the car. </p>
                    <p>You undertake to refrain from using any form of hacking tools or programs, such as hackers,
                        robots, spiders, scrapers, etc., to access the website or app, for any reason whatsoever.
                        Additionally, users undertake to comply with all provisions of laws pertaining to IT, indicated
                        in Federal Decree-Law No</p>


                    <h4>SELLER’S WARRANTIES </h4>
                    <p>The Seller warrants that he/she is the sole registered and legal owner of the vehicle(s) that is
                        mentioned by Seller either through website or directly through on of our agent/salesperson, and
                        the title or documentation of the vehicle(s) are valid, transferrable, and unimpeded. </p>
                    <p>The Seller confirms that the vehicle(s) herein is/are not stolen and do not contain any stolen
                        parts. In the case of a Seller carrying out a sale of a stolen vehicle(s), the Auction Company
                        is not responsible for any sale and will not be held responsible in any way shape or form.
                        Auction Company will only verify the ownership of the car through the documents presented by the
                        Seller and Seller is responsible for the authenticity of the documents he/she provides for
                        approval. The Seller warrants that he/she shall be held responsible for this act. </p>
                    <p>The Seller warrants that the vehicle(s) identification number (VIN) has not been tampered with,
                        replaced, or altered in any way and is the original VIN number matching the original
                        documentation issued for the vehicle(s). Any vehicle(s) without VIN or VIN that is not legible
                        or where there is a discrepancy between the VIN on the vehicle(s) and documentation will not be
                        accepted for sale or in case it is sold and returned; Seller is the sole liable for it. </p>
                    <p>The Seller agrees to provide clear and transparent information of all relevant history of the
                        vehicle(s), any enhancements made and guarantees that the odometer is accurate and has not been
                        rolled back. If any of these are later found to be true, the Auction Company reserves the right
                        to reverse the sale and charge the Seller twenty percent (20%) of the sale price or minimum AED
                        5,000 whichever is higher. </p>
                    <p>The Seller warrants that the documents provided for the vehicle(s) are free and clear of all
                        lines, encumbrances and adverse claims of any kind and are original and genuine. </p>
                    <p>The Seller understands he/she is fully responsible for any description of said vehicle(s).
                        Auction Company does not make any representations or express any opinions of its own concerning
                        the Seller vehicle(s). Auction Company does not research the authenticity of documents or the
                        provenance of the vehicle(s) or verify any information provided by the Seller, nor does Auction
                        Company undertake any duty to do any of the foregoing for the benefit of the Buyer, Seller, or
                        anyone else. </p>


                    <h4>Auction </h4>
                    <p>The Auction Company facilitates Buyers to bid on the auction both online and on-site. To
                        Understand the Auction process, visit the FAQ Page and if more information required then contact
                        us. </p>

                    <h4>Bidding security deposit: </h4>

                    <p>The Buyer will be required to add bidding security deposits by cash/bank/card before he/she is
                        allowed to bid online. As per the plans, deposit will allow Buyer to get a bid limit. A Buyer
                        cannot bid on an Item higher than the remaining bid limit of his/her. If there is any purchase
                        made the bid limit will be reduced accordingly and will only be restored once the payment is
                        cleared for the purchased item(cars). </p>
                    <p>Bid Security Deposit is refundable through bank transaction only and will be refunded once all
                        the dues are cleared for the purchased items(cars) and storage if any. In case Buyer fails to
                        pay the remaining balance within 5 days from the purchased date, Auction Company will cancel the
                        sales and will confiscate the downpayment/Bid Security Deposit. </p>

                    <h4>Won Items: </h4>
                    <p>The Buyer can see the car he/she won from auction in the Buyer dashboard menu. The Buyer must
                        clear the payment on time to avoid any storage and cancellation within 5 days from the purchased
                        date. Once the Buyer clears the payment, the gate pass will be generated automatically in
                        history until the free parking days are over. Once storage starts applying the car will show in
                        the payment section again until the dues are further cleared and the car is picked up. </p>





                    <h4>Invoicing and payment and sales proceeds: </h4>
                    <p>Buyer will be tax invoiced by the Seller after the full payment is cleared and car is picked up
                        by the Buyer. The Buyer must clearly mention before purchasing the where the invoicing will be
                        under the company name or his/her individual name. In case the Buyer wants the tax invoice under
                        the company name then he/she should be the authorized person for that company trade license.
                    </p>
                    <p>The Buyer can either pay by cash, account deposit or bank transfer. If Buyer wishes to clear the
                        payment online, then auction company will apply online service charges based on the invoice
                        which customer will get to know at the time when he/she selects payment mode online. </p>
                    <p>Seller will be invoiced for the service provided by Auction Company and maintain statement of
                        account for adjustment in case the car is sold and if the item is unsold then there will listing
                        fees applied which Seller needs to pay by directly visiting the branch where his car is at and
                        clear payment to get releasing note for the car. </p>
                    <p>The sales proceeds for the Seller item(cars) will be released by the Auction Company either by
                        cash, cheque or bank transfer as Auction Company sees fit. Cash and cheque can be collected from
                        the cash counter at the relevant branch. </p>
                    <p>VAT, per the UAE Federal Decree-Law No. (8) of 2017 on Value Added Tax (“VAT Law”), will be added
                        - where relevant in an amount of 5% to the final auction price and all fees, such as, without
                        limitation, administrative fees storage fees delivery or export fees. The application of VAT to
                        the final price falls under the Sellers duties, and not that of the Auction Company, in
                        accordance with the VAT Law.</p>

                    <h4>Gate-pass: </h4>

                    <p>The sale will be considered complete once the car is out of Auction Company warehouse and until
                        the car is not picked Auction Company has the right to cancel the sales, even if full payment is
                        settled, and the downpayment will not be refundable. </p>

                    <h4>Refund Policy and Sales Cancellation </h4>
                    <p>Refund Policy is only applicable for Buyer for Item(car) in following cases:</p>


                    <div class="pl-10">

                        <li>If an Item sold by the Auction Company where Seller car’s VIN/Chassis No: is damaged or not
                            matching. </li>
                        <li>Car presented as ready car without any visible damage and damage status not presented before
                            auction which results in passing failure. (car value only refundable) </li>
                        <li>Car cancelled after full payment due to Buyer’s failure to pick up the car (downpayment not
                            refundable and storage value to be deducted) </li>
                        <li>Any extra functionality mentioned by auctioneer during auction (only for Onsite and with
                            proof)
                        </li>
                    </div>



                    <p>
                        Auction Company will not refund the Buyer for the following:
                    </p>

                    <div class="pl-10">
                        <li>If any part of the car is not working or damaged (engine, gear, chassis and other parts) and
                            Item(car) sold in that condition. </li>
                        <li>If there is clearly visible damage or damage status was present during auction, even if the
                            car
                            was ready, and passing test fails there will be no refund. </li>
                        <li>Any personal issues and reason to refund. </li>
                    </div>
                    <li>Cancellation of sales is at the sole discretion of
                        Auction Company and has the right to cancel the sales any time after the deadline (5 days from
                        purchase date). If Buyer fails to pay the balance or pick up the car (even after full payment)
                        the Auction Company reserves the right to cancel the car and confiscate the downpayment</li>


                    <h4>Limitation of liability </h4>
                    <p>Seller shall indemnify, defend, and hold the Auction Company harmless from and against any claim,
                        demand, lawsuits, or liabilities (including freight, attorney’s fees, and defense costs) arising
                        from or related to the sale of the vehicle(s), from the condition, title, mileage, or history of
                        the vehicle or any component part of the vehicle, or any misrepresentation, concealment, or
                        omission of facts by the Seller. </p>
                    <p>Seller agrees to transfer the ownership of vehicle to Buyer after sales whenever the Buyer
                        request. Seller shall hold Auction Company harmless against any liability that might occur in
                        the process of ownership transfer to Buyer. </p>
                    <p>Seller shall maintain insurance on the listed vehicle(s) on their own accord and waves any claims
                        against Auction Company for damages that occur to the listed vehicle(s) being held for auction,
                        including but not limited to damages due to vandalism, theft, flood, fire, or any act of God.
                    </p>
                    <p>The Seller agrees that any vehicle(s) stored or left more than one (1) month from the auction
                        date shall be considered abandoned and disposed of as Auction Company sees fit unless a special
                        agreement is reached between the parties. The Seller agrees to pay all imposed charges that
                        apply before the vehicle(s) is released back. </p>



                    <h4>Indemnification </h4>
                    <p>
                        You (Buyer and Seller) agree to indemnify and hold the Auction Company and its affiliates,
                        directors, officers, employees, agents, suppliers and licensors harmless from and against any
                        liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in
                        connection with or arising from any third party allegations, claims, actions, disputes, or
                        demands asserted against any of them as a result of or relating to your Content, your use of the
                        Services or any willful misconduct on your part.
                    </p>
                    <h4>
                        Severability
                    </h4>
                    <p>
                        All rights and restrictions contained in these Terms may be exercised and shall be applicable
                        and binding only to the extent that they do not violate any applicable laws and are intended to
                        be limited to the extent necessary so that they will not render these Terms illegal, invalid or
                        unenforceable. If any provision or portion of any provision of these Terms shall be held to be
                        illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of
                        the parties that the remaining provisions or portions thereof shall constitute their agreement
                        with respect to the subject matter hereof, and all such remaining provisions or portions thereof
                        shall remain in full force and effect.
                    </p>
                    <h4>
                        Dispute Resolution
                    </h4>
                    <p>
                        The formation, interpretation, and performance of these Terms and any disputes arising out of it
                        shall be governed by the substantive and procedural laws of United Arab Emirates without regard
                        to its rules on conflicts or choice of law and, to the extent applicable, the laws of United
                        Arab Emirates. The exclusive jurisdiction and venue for actions related to the subject matter
                        hereof shall be the courts located in United Arab Emirates, and you hereby submit to the
                        personal jurisdiction of such courts. You hereby waive any right to a jury trial in any
                        proceeding arising out of or related to these Terms. The United Nations Convention on Contracts
                        for the International Sale of Goods does not apply to these Terms.
                    </p>
                    <h4>
                        Changes and Amendments of terms and conditions
                    </h4>

                    <p>We reserve the right to modify these Terms, or its terms related to the Services at any time at
                        our discretion. When we do, we will post a notification within the Services, send you an email
                        to notify you. We may also provide notice to you in other ways at our discretion, such as
                        through the contact information you have provided. </p>
                    <p>An updated version of these Terms will be effective immediately upon the posting of the revised
                        Terms unless otherwise specified. Your continued use of the Services after the effective date of
                        the revised Terms (or such other act specified at that time) will constitute your consent to
                        those changes. </p>

                    <h4>
                        Acknowledgment
                    </h4>

                    <p>You acknowledge that you have read these Terms and agree to all its terms and conditions. By
                        accessing and using the Services you agree to be bound by these Terms. If you do not agree to
                        these Terms, you must refrain from accessing the Website or Mobile Application and availing of
                        the Services. </p>



                    <h4>
                        Contact us
                    </h4>
                    <p>
                        If you have any questions, concerns, or complaints regarding these Terms, we encourage you to
                        contact us using the details below:
                    </p>
                    <p>800 222 100</p>
                    <p>+971 56 403 0184 </p>
                    <p>customer.care@alqaryahauction.com</p>

                </div>
            </div>
            <div class="col-lg-12">
                <div class="policy">
                    <h4 class="text-right">الشروط العامة للبائع والمشتري</h4>

                    <p class="text-right" style="direction: rtl;">تحدد هذه الشروط والأحكام ("الشروط") الشروط والأحكام
                        العامة لاستخدامك لموقع الويب alqaryahauction.com ("الموقع الإلكتروني") وتطبيق الهاتف المحمول
                        "مزاد القرية" ("تطبيق الهاتف المحمول") وأي من المنتجات ذات الصلة و الخدمات (يُشار إليها إجمالاً
                        باسم "الخدمات") </p>
                    <p class="text-right" style="direction: rtl;">تشكل هذه الشروط اتفاقية ملزمة قانونًا بين البائع
                        ("البائع" أو "الطرف الأول" أو "المورد الرئيسي" أو "البائع") والمشتري ("المشتري" أو "الطرف
                        الثاني" أو "المشتري") وشركة القرية لمزادات السيارات ذ.م.م. (جميع الفروع) ("شركة المزاد" أو
                        "نحن"). إذا كنت ( بصفتك بائعًا أو مشتريًا) توافق على هذه الشروط نيابة عن شركة أو كيان قانوني
                        آخر، فإنك تقر بأن لديك السلطة لإلزام هذا الكيان بهذه الشروط. إذا لم تكن لديك هذه السلطة، أو كنت
                        لا توافق على هذه الشروط، فيجب ألا تقبل هذه الشروط ولا يجوز لك الوصول إلى الخدمات واستخدامها. وفي
                        حال قمت بالأستفادة من الخدمات واستخدامها، فإنك تقر بأنك قد قرأت هذه الشروط وفهمتها ووافقت على
                        الالتزام بها. </p>
                    <p class="text-right" style="direction: rtl;">يتم توفير جميع القطع/العناصر المعروضة في المزاد من قبل
                        الطرف الأول (البائع أو
                        البائع) وليس من قبل شركة المزاد.
                        باستخدامك الموقع الإلكتروني أو تطبيق الهاتف المحمول أو المرافق الأخرى الموجودة في الموقع، فإنك
                        تتفاعل مع الكيانات
                        التي تروج لبيع أو توفير السلع والخدمات. يتم تحديد جميع الشروط أو الضمانات أو الالتزامات ذات
                        الصلة المتعلقة بهذه
                        المعاملات بشكل صارم بين الطرف الثاني (المشتري أو المشتري) والطرف الأول (البائع أو البائع). </p>
                    <p class="text-right" style="direction: rtl;">شركة المزادات هي مجرد وسيط يسهل لكل من البائع والمشتري
                        البيع والشراء من خلال
                        عملية المزاد سواء في الموقع أو من خلال
                        المزايدة عبر الإنترنت. يوافق البائع والمشتري على أن، إلى الحد الذي يسمح به القانون المعمول به،
                        شركة المزاد ليست
                        مسؤولة قانونًا عن أي خسارة أو ضرر، سواء كان ملموسًا أو غير ملموس، أو أي مطالبات أخرى من أي نوع
                        ناتجة عن هذه
                        المعاملات التي تجري على موقعها الإلكتروني. أو تطبيق الهاتف المحمول.
                    </p>





                    <h4 class="text-right" style="direction: rtl;">المصطلحات:</h4>
                    <p class="text-right" style="direction: rtl;"><b>العناصر/البضائع/الخدمات/القطعة:</b> السلع أو
                        الخدمات المدرجة على الموقع الإلكتروني
                        لشركة المزادات أو تطبيق الهاتف المحمول
                        من قبل البائعين. </p>
                    <p class="text-right" style="direction: rtl;"><b>مقدم العرض:</b> أي شخص يقدم عرضًا سواء في الموقع أو
                        عبر الإنترنت. </p>
                    <p class="text-right" style="direction: rtl;"><b>خدمات المزاد:</b> أية خدمة تقدمها شركة المزاد لكل
                        من البائع والمشتري لتسهيل البيع
                        بالمزاد. </p>
                    <p class="text-right" style="direction: rtl;"><b>عدد مرات التشغيل:</b> عدد مرات عرض السلعة بالمزاد
                        العلني. يتم منح كل سلعة حد تشغيل
                        يتم بعده تطبيق رسوم الإدراج. </p>
                    <p class="text-right" style="direction: rtl;"><b>رسوم الإدراج:</b> تفرض شركة المزاد رسوم على البائع
                        لإدراج السلعة بصرف النظر عن
                        حالة البيع (مباع/غير مباع) بعد أكمال عدد
                        مرات التشغيل المتفق عليها. </p>
                    <p class="text-right" style="direction: rtl;"><b>رسوم المزاد:</b> الرسوم التي تفرضها شركة المزاد على
                        البائع في حالة بيع السيارة.
                    </p>
                    <p class="text-right" style="direction: rtl;"><b>المزايدة الخاصة:</b> عندما يقوم البائع بالمزايدة
                        على سيارته الخاصة التي قام
                        بإدراجها على الموقع الإلكتروني لشركة المزاد
                        إما عبر الإنترنت أو في الموقع. </p>
                    <p class="text-right" style="direction: rtl;"><b>رسوم التخزين/وقوف السيارات:</b> تفرض شركة المزاد
                        رسومًا على البائع أو المشتري
                        مقابل تجاوز الموعد النهائي لاستلام العناصر
                        الملموسة. </p>
                    <p class="text-right" style="direction: rtl;"><b>تصريح البوابة:</b> مستند تصدره شركة المزاد للبائع
                        أو المشتري إما لتسليم العناصر أو
                        استلامها من المستودع المخصص. </p>

                    <h4 class="text-right" style="direction: rtl;">الحسابات والعضوية</h4>
                    <p class="text-right" style="direction: rtl;">يجب أن يكون عمرك 21 عامًا على الأقل حتى تتمكن من
                        استخدام الخدمات. باستخدام
                        الخدمات والموافقة على هذه الشروط، فإنك
                        تضمن وتقر بأن عمرك لا يقل عن 21 عامًا. إذا قمت بإنشاء حساب على الخدمات، فأنت مسؤول عن الحفاظ على
                        أمان حسابك وأنت
                        مسؤول مسؤولية كاملة عن جميع الأنشطة التي تحدث ضمن الحساب وأي إجراءات أخرى مرتبطة به. يجوز لنا
                        مراقبة ومراجعة
                        الحسابات الجديدة قبل أن تتمكن من الوصول إلى الحساب بكامل الخصائص والبدء في استخدام الخدمات. قد
                        يؤدي تقديم معلومات
                        اتصال زائفة من أي نوع إلى إنهاء حسابك. يجب عليك إخطارنا على الفور بأي استخدامات غير مصرح بها
                        لحسابك أو أي انتهاكات
                        أخرى للأمن. لن نكون مسؤولين عن أي أفعال أو تجاوزات من جانبك، بما في ذلك أي أضرار من أي نوع يتم
                        تكبدها نتيجة لهذه
                        الأفعال أو التجاوزات. يجوز لنا تعليق حسابك أو تعطيله أو حذفه (أو أي جزء منه)، وتسوية أي أرصدة
                        مستحقة، إذا قررنا أنك
                        قد انتهكت أي بند من هذه الشروط أو أن سلوكك أو المحتوى الخاص بك قد يميل إلى الإضرار بسمعتنا وحسن
                        نيتنا. إذا قمنا بحذف
                        حسابك للأسباب المذكورة أعلاه، فلا يجوز لك إعادة التسجيل في خدماتنا. قد نقوم بحظر عنوان بريدك
                        الإلكتروني وعنوان
                        بروتوكول الإنترنت الخاص بك لمنع التسجيل في المستقبل.</p>
                    <p class="text-right" style="direction: rtl;">للتسجيل كبائع أو مشتري فقط اضغط على التسجيل واتبع
                        خطوات التسجيل. تأكد من تحميل وثائقك القانونية
                        للتحقق من هويتك
                        ولأغراض إعداد الفواتير ونقل ملكية السلع المباعة. (تقديم وثيقة قانونية للتحقق من الهوية) ستضمن
                        شركة المزاد الحفاظ على
                        بياناتك الشخصية أو بيانات شركتك آمنة من التهديدات وفقًا لسياسة الخصوصية.</p>
                    <h4 class="text-right" style="direction: rtl;">دقة معلومات السلعة : </h4>
                    <p class="text-right" style="direction: rtl;">يتحمل البائع وحده المسؤولية عن دقة السلعة وجودتها
                        وسلامتها وشرعيتها وموثوقيتها
                        وملاءمتها. يجوز لشركة المزادات مراقبة
                        ومراجعة محتوى السلع التي تم تحميلها من قبل البائع وبذل قصارى جهدها لتحسين جودة المعلومات/وصف
                        السلع التي تم تحميلها
                        من قبل البائع. أنت (البائع) تمنحنا الإذن بالوصول إلى محتوى حساب المستخدم الخاص بك ونسخه وتوزيعه
                        وتخزينه ونقله وإعادة
                        تنسيقه وعرضه وتنفيذه فقط كما هو مطلوب لغرض تقديم الخدمات لك. دون تقييد أي من تلك الإقرارات أو
                        الضمانات، لدينا الحق،
                        ولكن ليس الالزام، وفقًا لتقديرنا الخاص، في رفض أو إزالة أي سلعة، في رأينا ، تنتهك أيًا من
                        سياساتنا أو تكون ضارة بأي
                        شكل من الأشكال. أو مرفوضة. في بعض الأحيان قد تكون هناك معلومات حول الخدمات أو السلع التي تحتوي
                        على أخطاء مطبعية أو
                        عدم دقة أو سهو قد يتعلق بأوصاف المنتج والأسعار والعروض الترويجية والعروض. تحتفظ شركة المزاد
                        بالحق في تصحيح أي أخطاء
                        أو عدم دقة أو سهو، وتغيير أو تحديث المعلومات أو إلغاء المبيعات إذا تبين أن أي معلومات حول السلع
                        أو السلعة غير دقيقة
                        في أي وقت دون إشعار مسبق. نحن لا نتعهد بأي التزام بتحديث أو تعديل أو توضيح المعلومات المتعلقة
                        بالسلع بما في ذلك، على
                        سبيل المثال لا الحصر، معلومات التسعير، باستثناء ما يقتضيه القانون. </p>
                    <h1 class="text-right" style="direction: rtl;">تفاصيل العناصر/الخدمات </h1>





                    <ul class="text-right" style="direction:rtl;">خدمات البائع:
                        <li class="text-right" style="direction: rtl;">سيتم تعيين خطة لكل بائع أثناء التحقق من هوية
                            المستخدم بعد أن يقوم البائع
                            بالتسجيل. يمكن للبائع رؤية الخطة في
                            ملفه الشخصي بمجرد قيام شركة المزاد بالتحقق من البائع. سيُطلب من البائع إضافة سيارة إلى
                            الموقع الإلكتروني لعملية
                            الموافقة مع التفاصيل الكاملة. سيُطلب من البائع تقديم سعر احتياطي للسيارة التي تتم إضافتها.
                            يقر البائع ويوافق على
                            أن السعر الاحتياطي الذي أضافه أو قبِل به أثناء عملية الموافقة سيكون هو السعر الذي ستوافق
                            عليه شركة المزاد على
                            بيع السيارة ويمكن أن تكون قيمة البيع مساوية أو أكبر من الأسعار الاحتياطية التي تمت إضافتها
                            أو قبولها من قبل
                            البائع.
                        </li>
                        <li class="text-right" style="direction: rtl;"> يوافق البائع على أن يدفع لشركة المزاد المبالغ
                            ذات الصلة الموضحة أدناه
                            بالإضافة إلى أي غرامات لانتهاك هذه الشروط
                            أو أي رسوم أخرى قد تنشأ في المستقبل فيما يتعلق بخدمات المزاد، للحصول على تعويض شركة المزاد.
                            قم بزيارة صفحة
                            البائع المتاحة في القائمة لرؤية الخطة الأساسية التي تنطبق على كل بائع. رسوم البائع هي كما
                            يلي:
                        </li>
                        <li class="text-right" style="direction: rtl;"> رسوم الإدراج: سيتم تطبيق رسوم الإدراج، سواء تم
                            بيع سلعة (سلع) البائع أم لا، لكل سلعة يدخلها
                            البائع في المزاد
                            فقط لعمليات التشغيل المخصصة وفقًا لخطة (البائع) المخصصة لك. إذا رغب البائع في سحب السلعة
                            (السلع)، فسوف تقوم شركة
                            المزاد بتطبيق سياسة إلغاء الخدمة الموضحة في هذه الاتفاقية. يجب على البائع استلام السلعة
                            (السلع) في نفس اليوم بعد
                            دفع المستحقات وبمجرد تأكيد البائع للانسحاب، لن تسمح شركة المزاد بعمليات التشغيل المتبقية
                            ويجب على البائع استلام
                            السيارة.
                        </li>
                        <li class="text-right" style="direction: rtl;"> رسوم المزاد: يقر البائع ويوافق على تعويض شركة
                            المزاد عمولة (رسوم المزاد) على مبيعات العنصر
                            (العناصر) كما هو
                            محدد في خطة البائع
                        </li>
                        <li class="text-right" style="direction: rtl;"> رسوم التخزين: يتمتع البائع بموقف مجاني للسيارات
                            لمدة يوم واحد في حالة عدم بيع السيارة (بعد
                            عرض السيارة لمرتين
                            تشغيل)، أو استحواذها أو سحبها، وبعد ذلك ستبدأ رسوم مواقف السيارات وفقًا لـ (خطة البائع)
                            الخاصة بك. </li>
                        <li class="text-right" style="direction: rtl;"> رسوم المزايدة الخاصة: إذا قام البائع بتقديم
                            العرض على السيارة المدرجة من قبله، فسوف تقوم
                            شركة المزاد بالتقدم
                            بطلب وتأخذ دفعة أولى بنسبة 20٪ من قيمة السيارة وبعد ذلك سيتم إلغاء المبيعات ومصادرة الدفعة
                            الأولى وفقًا لسياسة
                            الإلغاء الخاصة بنا وهذا المبلغ لن يكون قابلاً للاسترداد وسيتم اعتبار الدفعة الأولى كاملة
                            كرسوم مزاد. إذا تم
                            القبض على البائع وهو يقوم بمثل هذا الفعل، فسوف تحتفظ شركة المزاد بالحق في منع البائع من
                            البيع، وإذا رغبت شركة
                            المزاد، فسوف ترفع قضية ضد البائع. </li>
                    </ul>
                    <h4 class="text-right" style="direction: rtl;">خدمات المشترين: </h4>


                    <p class="text-right" style="direction: rtl;">يجب على المشترين التقدم بطلب للحصول على حد
                        للعرض(العطاء) من شركة المزاد وفقًا
                        للخطط المتاحة. لكي يتمكن المشتري من تحديد خطة، يجب عليه دفع وديعة تأمين بحد العرض إما نقدًا أو
                        ببطاقة أو تحويل مصرفي للحصول على الخطة. تفضل بزيارة صفحة المشتري لفهم العملية أيضًا. </p>
                    <p class="text-right" style="direction: rtl;"> يكون حد العرض قابلاً للاسترداد للمشترين من خلال
                        التحويل المصرفي أو استرداد
                        البطاقة (إذا تم الدفع عن طريق البطاقة) في حالة عدم إجراء عملية شراء عبر الإنترنت. في حالة قيام
                        المشتري بالشراء، سيتم تخفيض حد العرض بقيمة الشراء وسيتم تجميد وديعة التأمين ولا يمكن إعادتها إلى
                        المشتري حتى تتم تسوية المستحقات الكاملة. يمكن للمشتري التقدم بطلب لدفع ثمن السلعة (السلع) التي
                        تم شراؤها من مبلغ وديعة التأمين، ولكن سيؤدي ذلك إلى إلغاء حد المزايدة حيث لن يتمكن من المزايدة
                        على أي سيارة بعد ذلك. </p>


                    <li style="font-size: 25px; font-weight: 600; direction: rtl;" class="text-right">
                        بيع السيارات
                    </li>
                    <p style="font-size: 15px; font-weight: 600; direction: rtl;" class="text-right">العرض المسبق قبل
                        المزاد: </p>

                    <p class="text-right" style="direction: rtl;"> يتم منح المشترين أمكانية تحديد العرض مسبقًا قبل بدء
                        المزاد لتسهيل الأمر على
                        المشتري الذي لا يمكنه انتظار بدء المزاد المباشر أو ليس لديه الوقت الكافي للمشاركة في المزاد. لكي
                        يقوم المشتري بالمزايدة المسبقة، ما عليك سوى زيارة صفحة السيارة>حدد تاريخ المزاد في الفلتر> حدد
                        سيارة وانتقل إلى تفاصيل السيارة> قسم معلومات المزايدة> قم بتقديم عرض أعلى ثم بدء المزايدة أو
                        المزايدة المسبقة الحالية. بعد تقديم العطاءات المسبقة، ستتمكن من رؤية مسار ذلك في قائمة لوحة تحكم
                        المشتري. يتحمل المشتري المسؤولية الكاملة عن العطاء المسبق إذا تم ذلك عن قصد أو عن غير قصد. يجب
                        عليك (المشتري) توخي الحذر عند تقديم أي عرض والتأكد من التحقق من قيمة عرضك قبل تقديم العطاءات حيث
                        لا يوجد أي انسحاب من العطاءات بمجرد تقديمها. بمجرد أن يبدأ المزاد، سيكون العرض الأفتتاحي هو أعلى
                        عرض مسبق يقدمه المشتري إن وجد. يحتاج المشتري إلى التأكد من أن عرض السعر المسبق الخاص به هو أعلى
                        عرض سعر مسبق لتمكين عرض السعر المبدئي باعتباره عرض السعر الخاص به/لها. </p>


                    <p class="text-right" style="font-size: 15px; font-weight: 600; direction: rtl;">
                        المزايدة عبر الإنترنت/في الموقع:
                    </p>

                    <p class="text-right" style="direction: rtl;"> ستقوم شركة المزاد بالمزامنة عبر الإنترنت، وسيتمكن
                        مقدمو العطاءات والمزايدة في
                        الموقع من تقديم عرض على نفس السيارة من موقع المزاد في الموقع وعبر الإنترنت. كل سيارة لها عرض
                        ابتدائي وعرض احتياطي. يمكن إعادة تعريف عرض السعر المبدئي من خلال عروض الأسعار المسبقة بينما يتم
                        تقديم عرض سعر احتياطي من قبل البائعين للموافقة على مبيعات السلعة بمجرد وصول قيمة عرض السعر إلى
                        مبلغ عرض السعر الاحتياطي.</p>

                    <p class="text-right" style="font-size: 15px; font-weight: 600; direction: rtl;"> تحت موافقة: </p>

                    <p class="text-right" style="direction: rtl;"> عندما يكون العرض الأخير لعنصر ما أقل من السعر
                        الاحتياطي (الحالة: عدم
                        الموافقة)، ستعتبر شركة المزاد السيارة تحت الموافقة)أي ان السيارة لايمكن شرائها لأنها لم تصل الى
                        السعر ، حيث سيُطلب من البائع إما قبول آخر عطاء أو رفضه أو تقديم عرض مضاد له. إذا تم قبول العرض
                        الأخير أو العرض المقابل (للبائع) من قبل البائع أو مقدم العرض، فسيتم اعتبار السلعة مباعة بموجب
                        نفس شروط وأحكام هذه الاتفاقية. يجب على المشتري توخي الحذر الشديد عند قبول العرض، إن وجد، من قبل
                        البائع أثناء عملية "الموافقة" حيث لن يكون هناك إرجاع للمبيعات. </p>
                    <p class="text-right" style="direction: rtl;">ستسمح شركة المزاد للبائع أو المشتري بإغلاق الصفقة فقط
                        في غضون 24 ساعة إذا كانت
                        السيارة تحت الموافقة من وقت المزاد وبعد ذلك سيتم اعتبار البيع غير مباع </p>







                    <li style="font-size: 15px; font-weight: 600; direction: rtl;" class="text-right"> تخزين </li>
                    <p class="text-right" style="direction: rtl;">
                        ستفرض شركة المزاد على المشتري رسوم تخزين قدرها 50 درهمًا إماراتيًا يوميًا إذا فشل المشتري في
                        استلام السيارة خلال 5 أيام من تاريخ البيع/المزاد.
                    </p>
                    <h1 class="text-right" style="direction: rtl;">تنويه من الضمان </h1>
                    <h4 class="text-right" style="direction: rtl;">الضمانات العامة </h4>

                    <p class="text-right" style="direction: rtl;">أنت توافق على أن هذه العناصر/الخدمات يتم تقديمها على
                        أساس "كما هي" و"كما هو
                        متاح". نحن نخلي مسؤوليتنا صراحة عن جميع الضمانات من أي نوع، سواء كانت صريحة أو ضمنية، بما في ذلك
                        على سبيل المثال لا الحصر الضمانات الضمنية الخاصة بالتسويق والملاءمة لغرض معين وعدم الانتهاك. نحن
                        لا نقدم أي ضمان بأن العنصر سوف يلبي متطلباتك، أو أن الخدمة ستكون دون انقطاع، أو في الوقت
                        المناسب، أو آمنة، أو خالية من الأخطاء؛ كما أننا لا نقدم أي ضمان فيما يتعلق بالنتائج التي يمكن
                        الحصول عليها من استخدام العناصر/الخدمة أو فيما يتعلق بدقة أو موثوقية أي معلومات تم الحصول عليها
                        من خلال الخدمة أو أنه سيتم تصحيح العيوب في العنصر. لا تشكل أي نصيحة أو معلومات، سواء كانت شفهية
                        أو مكتوبة، تحصل عليها منا أو من خلال الخدمة أي ضمان غير منصوص عليه صراحة في هذه الوثيقة. غالبية
                        السلعة (السيارات) هي عبارة عن حوادث/تم إنقاذها وتباع دون أي فحص مسبق على قواعدها كما هي، وسيارات
                        الحوادث ليست مؤهلة للحصول على اختبار الفحص/اجتياز المركبات من أي إدارة مرور للمركبات (تسجيل/هيئة
                        الطرق والمواصلات/المرور/إلخ) في الإمارات العربية المتحدة. سيتم ذكر السلعة (السيارة) التي تم
                        إصلاحها من قبلنا قبل المزاد إذا لم يكتمل اجتياز السيارة بعد. </p>
                    <p class="text-right" style="direction: rtl;">ت تتعهد بالامتناع عن استخدام أي شكل من أشكال أدوات أو
                        برامج القرصنة، مثل
                        المتسللين والروبوتات والعناكب والكاشطات وما إلى ذلك، للوصول إلى موقع الويب أو التطبيق، لأي سبب
                        كان. بالإضافة إلى ذلك، يتعهد المستخدمون بالالتزام بجميع أحكام القوانين المتعلقة بتقنية
                        المعلومات، المشار إليها في المرسوم بقانون اتحادي رقم (34) لسنة 2021 في شأن مكافحة الشائعات
                        والجرائم الإلكترونية وتعديلاته. </p>

                    <h4 class="text-right" style="direction: rtl;">ضمانات البائع </h4>
                    <p class="text-right" style="direction: rtl;">يضمن البائع أنه المالك القانوني الوحيد المسجل للمركبة
                        (المركبات) التي ذكرها
                        البائع إما من خلال الموقع الإلكتروني أو مباشرة من خلال وكيلنا / مندوب المبيعات لدينا، وأن ملكية
                        أو وثائق المركبة (المركبات) هي صالحة، قابلة للتحويل، ودون عوائق. يؤكد البائع أن السيارة
                        (المركبات) المذكورة هنا ليست مسروقة ولا تحتوي على أي أجزاء مسروقة. في حالة قيام البائع ببيع
                        مركبة (مركبات) مسروقة، فإن شركة المزاد ليست مسؤولة عن أي عملية بيع ولن تتحمل المسؤولية بأي شكل
                        من الأشكال. لن تتحقق شركة المزاد من ملكية السيارة إلا من خلال المستندات المقدمة من البائع ويكون
                        البائع مسؤولاً عن صحة المستندات التي يقدمها للموافقة. ويضمن البائع أنه سيكون مسؤولاً عن هذا
                        الفعل. يضمن البائع عدم التلاعب برقم تعريف السيارة (المركبات) (VIN) أو استبداله أو تغييره بأي شكل
                        من الأشكال، وهو رقم VIN الأصلي المطابق للوثائق الأصلية الصادرة للمركبة (المركبات). لن يتم قبول
                        أي مركبة (مركبات) بدون رقم VIN أو VIN غير مقروء أو حيث يوجد تناقض بين رقم VIN الخاص بالمركبة
                        (المركبات) والوثائق للبيع أو في حالة بيعها وإعادتها؛ البائع هو المسؤول الوحيد عن ذلك. </p>
                    <p class="text-right" style="direction: rtl;"> يوافق البائع على تقديم معلومات واضحة وشفافة عن تاريخ
                        السيارة (المركبات) ذي
                        الصلة وأي تحسينات تم إجراؤها ويضمن دقة عداد المسافات وعدم إعادته والتلاعب به. إذا تبين لاحقًا أن
                        أيًا من هذه الأمور صحيح، تحتفظ شركة المزادات بالحق في إلغاء البيع وتحصيل البائع عشرين بالمائة
                        (20٪) من سعر البيع أو الحد الأدنى 5000 درهم إماراتي أيهما أعلى. </p>
                    <p class="text-right" style="direction: rtl;"> يضمن البائع أن المستندات المقدمة للمركبة (المركبات)
                        خالية وخالية من جميع
                        الخطوط والأعباء والمطالبات السلبية من أي نوع وأنها أصلية وحقيقية. يدرك البائع أنه مسؤول مسؤولية
                        كاملة عن أي وصف للمركبة (المركبات) المذكورة. لا تقدم شركة المزاد أي تعهدات أو تعبر عن أي آراء
                        خاصة بها فيما يتعلق بمركبة (مركبات) البائع. لا تقوم شركة المزاد بالبحث في صحة المستندات أو مصدر
                        السيارة (المركبات) أو التحقق من أي معلومات يقدمها البائع، ولا تتعهد شركة المزاد بأي واجب للقيام
                        بأي مما سبق لصالح المشتري أو البائع أو اي شخص اخر. </p>

                    <h4 class="text-right" style="direction: rtl;">مزاد علني</h4>
                    <p class="text-right" style="direction: rtl;"> تسهل شركة المزاد للمشترين تقديم عطاءاتهم في المزاد
                        عبر الإنترنت وفي الموقع.
                        لفهم عملية المزاد، قم بزيارة صفحة الأسئلة الشائعة وإذا كنت بحاجة إلى مزيد من المعلومات، فاتصل
                        بنا.</p>

                    <h4 class="text-right" style="direction: rtl;"> وديعة تأمين العطاء: </h4>

                    <p class="text-right" style="direction: rtl;">سيُطلب من المشتري إضافة ودائع ضمان المزايدة
                        نقدًا/البنك/البطاقة قبل السماح
                        له/لها بالمزايدة عبر الإنترنت. وفقًا للخطط، سيسمح الإيداع للمشتري بالحصول على حد للمزايدة. لا
                        يمكن للمشتري المزايدة على عنصر أعلى من الحد الأقصى للمزايدة المتبقية له/لها. إذا تم إجراء أي
                        عملية شراء، فسيتم تخفيض حد العرض وفقًا لذلك ولن يتم استعادته إلا بعد إتمام عملية الدفع مقابل
                        العنصر الذي تم شراؤه (السيارات). </p>
                    <p class="text-right" style="direction: rtl;"> يكون مبلغ تأمين العطاء قابلاً للاسترداد من خلال
                        المعاملات المصرفية فقط، وسيتم
                        استرداده بمجرد تسوية جميع المستحقات للعناصر المشتراة (السيارات) والتخزين إن وجدت. في حالة فشل
                        المشتري في دفع الرصيد المتبقي خلال 5 أيام من تاريخ الشراء، ستقوم شركة المزادات بإلغاء المبيعات
                        وستصادر الدفعة الأولى / وديعة ضمان العطاء. </p>

                    <h4 class="text-right" style="direction: rtl;">السلع التي تم ربحها في المزاد: </h4>
                    <p class="text-right" style="direction: rtl;"> يمكن للمشتري رؤية السيارة التي فاز بها من المزاد في
                        قائمة لوحة تحكم المشتري.
                        يجب على المشتري سداد الدفعة في الوقت المحدد لتجنب أي رسوم تخزين أو إلغاء خلال 5 أيام من تاريخ
                        الشراء. بمجرد قيام المشتري بسداد الدفعة، سيتم إنشاء تصريح البوابة تلقائيًا في السجل حتى انتهاء
                        أيام مواقف السيارات المجانية. بمجرد بدء تطبيق التخزين، ستظهر السيارة في قسم الدفع مرة أخرى حتى
                        تتم تصفية المستحقات واستلام السيارة. </p>





                    <h4 class="text-right" style="direction: rtl;"> الفواتير والدفع وعائدات المبيعات: </h4>
                    <p class="text-right" style="direction: rtl;"> سيقوم البائع بإصدار فاتورة ضريبية للمشتري بعد سداد
                        المبلغ بالكامل واستلام
                        السيارة من قبل المشتري. يجب على المشتري أن يذكر بوضوح قبل الشراء المكان الذي ستكون فيه الفواتير
                        تحت اسم الشركة أو اسمه الشخصي. في حالة رغبة المشتري في الحصول على الفاتورة الضريبية باسم الشركة،
                        فيجب أن يكون هو الشخص المفوض للحصول على الرخصة التجارية لتلك الشركة. </p>
                    <p class="text-right" style="direction: rtl;">ن للمشتري الدفع إما نقدًا أو عن طريق إيداع الحساب أو
                        التحويل البنكي. إذا كان
                        المشتري يرغب في سداد الدفعة عبر الإنترنت، فسوف تقوم شركة المزاد بتطبيق رسوم الخدمة عبر الإنترنت
                        بناءً على الفاتورة التي سيتعرف عليها العميل في الوقت الذي يختار فيه الدفع عبر الإنترنت. </p>
                    <p class="text-right" style="direction: rtl;"> سيتم إصدار فاتورة للبائع مقابل الخدمة المقدمة من قبل
                        شركة المزادات والاحتفاظ
                        بكشف حساب للتعديل في حالة بيع السيارة وإذا لم يتم بيع السلعة، فسيتم تطبيق رسوم الإدراج والتي يجب
                        على البائع دفعها عن طريق زيارة الفرع مباشرة حيث توجد سيارته ودفع المستحقات للحصول على مذكرة
                        تحرير السيارة. </p>
                    <p class="text-right" style="direction: rtl;">سيتم تحرير عائدات المبيعات الخاصة بعنصر البائع
                        (السيارات) من قبل شركة المزاد
                        إما نقدًا أو بشيك أو تحويل مصرفي حسبما تراه شركة المزاد مناسبًا. يمكن تحصيل النقد والشيكات من
                        شباك النقد في الفرع المعني. </p>
                    <p class="text-right" style="direction: rtl;"> سيتم إضافة ضريبة القيمة المضافة، بموجب المرسوم بقانون
                        اتحادي رقم (8) لسنة
                        2017 بشأن ضريبة القيمة المضافة ("قانون ضريبة القيمة المضافة")، حيثما كان ذلك مناسباً، بمبلغ 5%
                        من سعر المزاد النهائي وجميع الرسوم، مثل ، على سبيل المثال لا الحصر، الرسوم الإدارية ورسوم
                        التخزين ورسوم التسليم أو رسوم التصدير. إن تطبيق ضريبة القيمة المضافة على السعر النهائي يقع ضمن
                        واجبات البائع، وليس من واجبات شركة المزادات، وفقًا لقانون ضريبة القيمة المضافة. </p>
                    <p class="text-right" style="direction: rtl;"> القرية لمزادات السيارات ذ.م.م تعمل كوكيل فقط. البائع
                        هو المسؤول الوحيد عن
                        التسجيل والامتثال لضريبة القيمة المضافة في دولة الإمارات العربية المتحدة. في حالة تجاوز البائع
                        غير المسجل حد التسجيل الإلزامي لضريبة القيمة المضافة، فيجب عليه التسجيل على الفور ويكون مسؤولاً
                        عن أي امتثال قانوني. يتحمل البائع وحده المسؤولية عن أي عدم امتثال لضريبة القيمة المضافة ولا
                        ينبغي له تحميل القرية لمزادات السيارات أي عواقب قانونية/عدم امتثال. </p>
                    <h4 class="text-right" style="direction: rtl;"> عبور البوابة: </h4>

                    <p class="text-right" style="direction: rtl;"> سيتم اعتبار البيع مكتملاً بمجرد خروج السيارة من
                        مستودع شركة المزاد والى وقت
                        عدم استلام السيارة، يحق لشركة المزاد إلغاء عملية البيع، حتى لو تم سداد كامل المبلغ، ولن يتم
                        استرداد الدفعة الأولى. </p>

                    <h4 class="text-right" style="direction: rtl;">سياسة الاسترداد وإلغاء المبيعات </h4>
                    <p class="text-right" style="direction: rtl;">تنطبق سياسة استرداد الأموال على المشتري مقابل السلعة
                        (السيارة) فقط في الحالات
                        التالية: </p>



                    <ul style="direction: rtl;" style="direction: rtl;">
                        <li class="text-right" style="direction: rtl;"> إذا كانت السلعة المباعة من قبل شركة المزاد حيث
                            رقم VIN/رقم الهيكل لسيارة
                            البائع: تالفًا أو
                            غير متطابق.</li>
                        <li class="text-right" style="direction: rtl;"> اذا تم عرض السيارة كسيارة جاهزة دون أي ضرر ظاهر،
                            ولم يتم عرض حالة الضرر
                            قبل المزاد مما يؤدي
                            إلى فشل الاجتياز. (قيمة السيارة قابلة للاسترداد فقط) </li>
                        <li class="text-right" style="direction: rtl;"> اذا تم إلغاء السيارة بعد السداد الكامل بسبب فشل
                            المشتري في استلام
                            السيارة (الدفعة الأولى غير
                            قابلة للاسترداد ويتم خصم قيمة التخزين) </li>
                        <li class="text-right" style="direction: rtl;"> اذا ذكر المسؤول عن المزاد أي وظيفة إضافية أثناء
                            المزاد (فقط في الموقع
                            ومع أثبات)

                            لن تقوم شركة المزاد برد الأموال للمشتري مقابل ما يلي: </li>

                        <li class="text-right" style="direction: rtl;"> إذا كان أي جزء من السيارة لا يعمل أو تالف
                            (المحرك، والعتاد، والشاسيه
                            وأجزاء أخرى) و تم بيع
                            السلعة (السيارة) على تلك الحالة.</li>
                        <li class="text-right" style="direction: rtl;"> إذا كان هناك ضرر واضح للعيان أو كانت حالة الضرر
                            موجودة أثناء المزاد، حتى
                            لو كانت السيارة
                            جاهزة، وفشل اجتياز الاختبار، فلن يكون هناك أي استرداد. </li>
                        <li class="text-right" style="direction: rtl;">
                            أي مشاكل شخصية وسبب استرداد الأموال.
                            <p class="text-right" style="direction: rtl;"> يتم إلغاء المبيعات وفقًا لتقدير شركة المزادات
                                وحدها ولها الحق في إلغاء المبيعات في أي
                                وقت بعد الموعد النهائي
                                (5 أيام من تاريخ الشراء). إذا فشل المشتري في دفع الرصيد أو استلام السيارة (حتى بعد
                                السداد الكامل)، تحتفظ
                                شركة المزاد بالحق في إلغاء السيارة ومصادرة الدفعة الأولى</p>
                        </li>

                    </ul>

                    <h4 class="text-right" style="direction: rtl;"> تحديد المسؤولية </h4>
                    <p class="text-right" style="direction: rtl;">ب على البائع تعويض شركة المزاد والدفاع عنها وحمايتها
                        من وضد أي مطالبة أو طلب
                        أو دعاوى قضائية أو التزامات (بما في ذلك الشحن وأتعاب المحاماة وتكاليف الدفاع) الناشئة عن أو
                        المتعلقة ببيع السيارة (المركبات)، من حالة السيارة أو ملكيتها أو عدد الكيلومترات التي قطعتها أو
                        تاريخها أو أي جزء مكون منها، أو أي تحريف أو إخفاء أو إغفال للحقائق من قبل البائع. </p>
                    <p class="text-right" style="direction: rtl;"> يوافق البائع على نقل ملكية السيارة إلى المشتري بعد
                        البيع كلما طلب المشتري
                        ذلك. يجب على البائع حماية شركة المزاد من أي مسؤولية قد تحدث أثناء عملية نقل الملكية إلى المشتري.
                    </p>
                    <p class="text-right" style="direction: rtl;"> يجب على البائع الحفاظ على التأمين على المركبة
                        (المركبات) المدرجة من تلقاء
                        نفسه ورفع أي مطالبات ضد شركة المزاد عن الأضرار التي تحدث للمركبة (المركبات) المدرجة للعرض في
                        المزاد، بما في ذلك على سبيل المثال لا الحصر الأضرار الناجمة عن التخريب أو السرقة أو فيضان أو
                        حريق أو أي عمل من أعمال الله. </p>
                    <p class="text-right" style="direction: rtl;">يوافق البائع على أن أي مركبة (مركبات) مخزنة أو متروكة
                        لأكثر من شهر (1) من
                        تاريخ المزاد تعتبر مهجورة ويتم التخلص منها حسبما تراه شركة المزاد مناسبًا ما لم يتم التوصل إلى
                        اتفاق خاص بين الطرفين. يوافق البائع على دفع جميع الرسوم المفروضة قبل إعادة السيارة (المركبات)
                        مرة أخرى. </p>



                    <h4 class="text-right" style="direction: rtl;">التعويض عن الضرر </h4>
                    <p class="text-right" style="direction: rtl;">
                        توافق أنت (المشتري والبائع) على تعويض وحماية شركة المزاد والشركات التابعة لها ومديريها ومسؤوليها
                        وموظفيها ووكلائها ومورديها ومرخصيها من وضد أي التزامات أو خسائر أو أضرار أو تكاليف، بما في ذلك
                        أتعاب المحاماة المعقولة، المتكبدة فيما يتعلق أو الناشئة عن أي ادعاءات أو مطالبات أو إجراءات أو
                        نزاعات أو مطالب من طرف ثالث ضد أي منها نتيجة أو تتعلق بالمحتوى الخاص بك، أو استخدامك للخدمات أو
                        أي سوء سلوك متعمد من جانبك.
                    </p>
                    <h4 class="text-right" style="direction: rtl;">
                        الاستقلالية
                    </h4>
                    <p class="text-right" style="direction: rtl;">
                        يجوز ممارسة جميع الحقوق والقيود الواردة في هذه الشروط وتكون قابلة للتطبيق وملزمة فقط إلى الحد
                        الذي لا تنتهك فيه أي قوانين معمول بها ويقصد بها أن تقتصر على الحد الضروري حتى لا تجعل هذه الشروط
                        غير قانونية أو غير صالحة. أو غير قابلة للتنفيذ. إذا تم اعتبار أي حكم أو جزء من أي حكم من هذه
                        الشروط غير قانوني أو غير صالح أو غير قابل للتنفيذ من قبل محكمة ذات اختصاص قضائي، فإن نية الأطراف
                        هي أن تشكل الأحكام المتبقية أو أجزاء منها اتفاقهم فيما يتعلق موضوع هذه الاتفاقية، وستظل جميع
                        الأحكام المتبقية أو أجزاء منها سارية المفعول والتأثير بالكامل.
                    </p>
                    <h4 class="text-right" style="direction: rtl;">
                        حل النزاعات
                    </h4>
                    <p class="text-right" style="direction: rtl;">
                        تخضع صياغة وتفسير وتنفيذ هذه الشروط وأي نزاعات تنشأ عنها للقوانين الموضوعية والإجرائية لدولة
                        الإمارات العربية المتحدة بغض النظر عن قواعدها بشأن النزاعات أو اختيار القانون، وإلى الحد الذي
                        ينطبق فيه مع قوانين دولة الإمارات العربية المتحدة. إن الاختصاص القضائي الحصري ومكان إقامة
                        الدعاوى المتعلقة بالموضوع المذكور هنا هو المحاكم الموجودة في دولة الإمارات العربية المتحدة، وأنت
                        بموجب هذا تخضع للاختصاص القضائي الشخصي لهذه المحاكم. أنت بموجب هذا تتنازل عن أي حق في محاكمة
                        أمام هيئة محلفين في أي إجراء ينشأ عن هذه الشروط أو يتعلق بها. لا تنطبق اتفاقية الأمم المتحدة
                        بشأن عقود البيع الدولي للبضائع على هذه الشروط.
                    </p>
                    <h4 class="text-right" style="direction: rtl;">
                        التغييرات والتعديلات على الشروط والأحكام
                    </h4>

                    <p class="text-right" style="direction: rtl;"> نحن نحتفظ بالحق في تعديل هذه الشروط أو شروطها
                        المتعلقة بالخدمات في أي وقت
                        وفقًا لتقديرنا. عندما نقوم بذلك، سننشر إشعارًا داخل الخدمات، ونرسل إليك بريدًا إلكترونيًا
                        لإعلامك. يجوز لنا أيضًا تقديم إشعار لك بطرق أخرى وفقًا لتقديرنا، مثل معلومات الاتصال التي
                        قدمتها. ستكون النسخة المحدثة من هذه الشروط سارية فور نشر الشروط المعدلة ما لم ينص على خلاف ذلك.
                        إن استمرارك في استخدام الخدمات بعد تاريخ سريان الشروط المعدلة (أو أي قانون آخر محدد في ذلك
                        الوقت) سيشكل موافقتك على هذه التغييرات. </p>

                    <h4 class="text-right" style="direction: rtl;">
                        إعتراف
                    </h4>

                    <p class="text-right" style="direction: rtl;"> أنت تقر بأنك قرأت هذه الشروط وتوافق على جميع شروطها
                        وأحكامها. من خلال الوصول
                        إلى الخدمات واستخدامها، فإنك توافق على الالتزام بهذه الشروط. إذا كنت لا توافق على هذه الشروط،
                        فيجب عليك الامتناع عن الوصول إلى الموقع الإلكتروني أو تطبيق الهاتف المحمول والاستفادة من
                        الخدمات. </p>



                    <h4 class="text-right" style="direction: rtl;">
                        اتصل بنا
                    </h4>
                    <p class="text-right" style="direction: rtl;">
                        إذا كانت لديك أي أسئلة أو مخاوف أو شكاوى بخصوص هذه الشروط، فنحن ندعوك للاتصال بنا باستخدام
                        التفاصيل أدناه:
                    </p>
                    <p class="text-right">800 222 100</p>
                    <p class="text-right">+971 56 403 0184 </p>
                    <p class="text-right">customer.care@alqaryahauction.com</p>

                </div>
            </div>
        </div>
    </div>
</section>