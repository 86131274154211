import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class ConfirmdialogServiceService {

  bsModalRef: BsModalRef;
  private userChoiceSubject = new Subject<boolean>();
  private userReason = new Subject<{}>();

  constructor(private modalService: BsModalService) { }

  showConfirmationDialog(message: string): Observable<boolean> {
    const initialState = {
      message: message
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true, class:'modal-dialog-centered1' });
    return this.userChoiceSubject.asObservable();
  }

  showConfirmationDialogWithReason(message: string, remarks:boolean): Observable<{}> {
    const initialState = {
      message: message,
      reason:remarks
    };
    this.bsModalRef = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true, class:'modal-dialog-centered1' });
    return  this.userReason.asObservable();
   
  }

  setUserChoice(choice: boolean, reasonData= '', reason:boolean = false): void {
    if(reason)
    {
      this.userReason.next({reason:reasonData, choice:choice})
    }
    else
    {
      this.userChoiceSubject.next(choice);
    }
  }

  
  showWarningDialog(message: string): Observable<boolean> {
    const initialState = {
      message: message,
    };
    this.bsModalRef = this.modalService.show(WarningDialogComponent, { initialState, class:'modal-dialog-centered' });

    return this.userChoiceSubject.asObservable();
  }

  hideWarningDialog(){
    this.modalService.hide();
  }
}
