import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SingUpService } from '../../services/signup.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ConfirmdialogServiceService } from '../../confirmation-dialog/confirmdialog-service.service';


@Component({
  selector: 'app-terms-and-condition-content',
  templateUrl: './TermsAndCondition-Content.Component.html',
})
export class TermsAndConditionContentComponent implements OnInit {



  constructor(
  ) { }
  ngOnInit(): void {

  }

}
