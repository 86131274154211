import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class SallerBuyerService {
    url: any = environment.API_URL;
    lang = sessionStorage.getItem('lang');
    data:any;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getsellerDashboard(data: any) {
        return this.http.post(this.url + 'analytics/seller-dashboard-analytics', data)
    }  

    getInventoryList(data: any) {
        return this.http.post(this.url + 'inventory/list', data)
    }

    getTransmssionList(data: any) {
        return this.http.post(this.url + 'master/transmissionlist', data)
    }

    getPassList(data: any) {
        return this.http.post(this.url + 'inventory/getPassList', data);
    }

    getSellerMappingBranchList(data:any){
        return this.http.post(this.url + 'inventory/sellermappingbranchList', data)
    }
    getSellerplanList(data:any){
        return this.http.post(this.url + 'master/sellerplanlist', data)
    }

    createOrderWithDrawRequest(data:any){
        return this.http.post(this.url + 'order/create', data)
    }
    updatePreBid(data: any) {
        return this.http.post(this.url + 'bid/update-bid', data)
    }
    
    getPrebid(data:any){
        return this.http.post(this.url + 'bid/get-bid', data)
    }
    
    getPrebidWeb(data:any){
        return this.http.post(this.url + 'bid/get-pre-bid', data)
    }
     
    getHistorybid(data:any){
        return this.http.post(this.url + 'bid/bid-history', data)
    }

    orderHistoryList(data: any) {
        return this.http.post(this.url + 'order/list', data);
    }

    getCarList(data: any) {
        return this.http.post(this.url + 'inventory/carList', data)
    }

    SalerList(data: any) {
        return this.http.post(this.url + 'user/list', data);
    }

    makeList(data: any) {
        return this.http.post(this.url + 'master/list', data);
    }

    yearList(data: any) {
        return this.http.post(this.url + 'master/yearlist', data);
    }

    engineList(data: any) {
        return this.http.post(this.url + 'master/enginelist', data);
    }
    
    bodyList(data: any) {
        return this.http.post(this.url + 'master/bodylist', data);
    }

    driveList(data: any) {
        return this.http.post(this.url + 'master/drivetypelist', data);
    }

    serviceChargeList(data: any) {
        return this.http.post(this.url + 'salereciept/servicechargelist', data);
      }

    receiptUpdate(id, data) {
        return this.http.patch(this.url + `reciept/update/${id}`, data);
      }

    fuelList(data: any) {
        return this.http.post(this.url + 'master/fueltypelist', data);
    }

    modelList(data: any) {
        return this.http.post(this.url + 'master/modellist', data);
    }

    seriesList(data: any) {
        return this.http.post(this.url + 'master/serieslist', data);
    }

    transmissionList(data:any){
        return this.http.post(this.url + 'master/transmissionlist', data);
    }

    exteriorColorList(data: any) {
        return this.http.post(this.url + 'master/exteriorcolorlist', data);
    }

    InteriorColorList(data: any) {
        return this.http.post(this.url + 'master/interiorcolorlist', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

    addCar(data: any) {
        return this.http.post(this.url + 'inventory/add', data);
    }

    sendReservePriceConfirmationOtp(data: any) {
        return this.http.post(this.url + 'inventory/send-reserve-price-confirmation-otp', data);
    }

    updateReservePriceUpto(data: any) {
        return this.http.post(this.url + 'inventory/update-reserve-price-up-to-offered', data);
    }

    verifyReservePriceConfirmationOtp(data: any) {
        return this.http.post(this.url + 'user/verify-otp', data);
    }

    updateCar(data: any) {
        return this.http.patch(this.url + 'inventory/update', data);
    }

    checkVin(data:any){
        return this.http.post(this.url +'inventory/checkUniqueVin',data);
    }

    soldVehicleList(data:any){
        return this.http.post(this.url+'reciept/list',data );
    }

    recentSoldCars(data:any){
        return this.http.post(this.url+'salereciept/servicechargelist',data );
    }

    recieptAddCustomerPayment(data:any){
        return this.http.post(this.url+'reciept/add-customer-payment', data );
    }

    addPayment(data: any) {
        return this.http.post(this.url + 'reciept/addLedger', data);
    }

    getTransactionList(data:any){
        return this.http.post(this.url+'salereciept/sellerpaybalelist',data );
    }

    accountGlListReport(data: any) {
        return this.http.post(this.url + 'account/glListReport', data);
    }

    getStatement(data: any) {
        return this.http.post(this.url + 'account/consumer-statement-client-view', data);
    }

    getBuyerAnalytics(data:any){
        return this.http.post(this.url+'order/buyer-analytics',data );
    }

    getTransactionAnalytics(data:any){
        return this.http.post(this.url+'order/transaction-analytics',data );
    }

    getBuyerTransactionAnalytics(data:any){
        return this.http.post(this.url+'order/buyer-transaction-analytics',data );
    }

    getProfile(data:any){
        return this.http.post(this.url + 'user/list',data);
    }

    changePassword(data:any){
        return this.http.patch(this.url + 'user/change-password',data);
    }

    updateProfile(data:any){
        return this.http.patch(this.url + 'user/update',data);
    }

    branchList(data:any){
        return this.http.post(this.url + 'master/branchlist',data);
    }

    auctionList(data:any){
        return this.http.post(this.url + 'auction/list',data);
    }

    auctionDetail(data:any){
        return this.http.post(this.url + 'auction/get-auction',data);
    }

    inventoryByAuction(data:any){
        return this.http.post(this.url + 'inventory/inventory-by-auction-display',data);
    }

    auctionListwithDate(data:any){
        return this.http.post(this.url + 'auction/auctionlist',data);
    }

    watchList(data:any){
        return this.http.post(this.url + 'inventory/watchlistlist',data);
    }

    getPlanList(data:any){
        return this.http.post(this.url + 'master/buyerlimitlist',data);
    }

    watchListAdd(data:any){
        return this.http.post(this.url + 'inventory/watchlistadd',data);
    }


    deleteWatchList(data:any){
        const httpOptions = {
            headers: new HttpHeaders({}),body:data
        }
        return this.http.delete(this.url + 'inventory/watchlistdelete',httpOptions);
    }

    uploadImage(file: any) {
        return this.http.post(this.url + "upload/image", file);
    }

    buyPlan(data:any){
        return this.http.post(this.url + 'order/create',data);
    }
    getBuyerPlan(data:any){
        return this.http.post(this.url + 'order/list',data);
    }

    warehouseList(data: any) {
        return this.http.post(this.url + 'master/warehouselist', data);
    }

    addReservePrice(data: any) {
        return this.http.post(this.url + 'inventory/addreseverprice', data);
    }

    carApproved(data: any) {
        return this.http.patch(this.url + 'inventory/inventoryRequestUpdate', data);
    }

    updateReservePrice(data: any) {
        return this.http.patch(this.url + 'inventory/selleracceptanceUpdate', data);
    }

    checkVinNumber(data: any) {
        return this.http.post(this.url + 'inventory/checkUniqueVin', data);
    }

    sellerInvoiceData(data: any) {
        return this.http.post(this.url + 'salereciept/get-invoice', data);
    }

    accountGlTempList(data: any) {
        return this.http.post(this.url + 'account/gltempList', data);
    }

    inventoryCopy(data: any) {
        return this.http.post(this.url + 'inventory/inventoryCopy', data);
      }

}