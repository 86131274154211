import { Component, OnInit, Input, HostListener, AfterViewInit, AfterContentInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationExtras, NavigationStart } from '@angular/router';
import { UIHeaderService } from '../services/ui-header.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SingUpService } from '../services/signup.service';
import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../services1/shared.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalPopupComponent } from '../layout1/modal-popup/modal-popup.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { DatePipe } from '@angular/common';
import { SallerBuyerService } from 'src/app/main/dashboard/sallerBuyer.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit, AfterViewInit {

  isScrolled: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 0;
  }

  checkURL: boolean;
  currentUser: any;
  shortName: string;
  @Input() ClassGet;
  hideHeader: boolean = false;
  car: any;
  customLoader: boolean;
  page: number = 1;
  itemPerPage: number = 10;
  todayAuctionsList: any[] = [];
  auctionList: any = [];
  totalCount: number;
  notFound: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private signUpService: SingUpService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private toastService: ToastrService,
    private datePipe: DatePipe,
    private spinner: SpinnerVisibilityService,
    private service: SallerBuyerService,
  ) {

  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
   
    setTimeout(() => {
      this.auctionJoinHide();
      this.hideHeader = true;
    }, 2000)
   

    this.currentUser = this.authService?.currentUserValue;
    this.shortName = this.currentUser?.name?.slice(0, 2).toUpperCase();


  }
  ngAfterViewInit() {
    
    this.getAuctionList(this.page, this.itemPerPage);
    let chech = window.setInterval(() => {
      //   if(this.router.url=='/home'){
      //     (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
      //   }else{
      //     (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-dark-bg');
      //   }
  
      if (this.router.url != '/vehicle' && this.router.url != '/home') {
        localStorage.removeItem('search');
      }
  
      let detail = this.router.url.includes('/contact-us/details')
      if (this.router.url == '/home') {
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-white-bg');
      }
      else if (this.router.url == '/contact-us' || this.router.url == '/sell' || this.router.url == '/buy' || this.router.url == '/about-us' || this.router.url == '/carrer-job-posting' || detail == true) {
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-white-bg');
      }
      else {
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.remove('hader-dark-bg');
        (<HTMLInputElement>document.getElementById('commonHeader'))?.classList?.add('hader-white-bg');
      }
    }, 100)
      if (!this.currentUser?.isProfileCompleted) {
        this.activatedRoute.queryParams.subscribe(params => {
          const loginParam = params['login'] || params['signup'];
          if (loginParam && loginParam === 'true') {
            const myElement = document.getElementById('logInPopup');
            myElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            myElement.classList.add('in');
            myElement.classList.add('show');
            if (params['signup']) {
              this.signUpService.EnableSignUpModel(true);
            }
            if (params['login']) {
              this.signUpService.EnableSignUpModel(false);
            }
            if (params['signup'] || params['login']) {
              this.router.navigate([]);
            }
          }
        })
      } else {
        this.activatedRoute.queryParams.subscribe(params => {
          if (params['signup'] || params['login']) {
            this.router.navigate([]);
          }
        })
  
      }
    }
  auctionJoin(data: any) {
    if (!this.currentUser) {
      this.toastService.info('Please logIn as a buyer!!', 'Warning');
      return
    }
    if (this.currentUser?.status != 1) {
      this.toastService.info('Please wait for admin approval!!', 'Info');
      return
    }
    if (this.currentUser) {
      if (this.currentUser.userType === 4 || this.currentUser.userType === 5)
        // this.route.navigateByUrl(['auction-join', {id:data?._id,auction:true}]);
        this.router.navigateByUrl('auction-join?id=' + data?._id);
      else
        this.toastService.warning('Please logIn as a buyer!!', 'Warning');
    } else {
      this.toastService.warning('Please logIn as a buyer!!', 'Warning')
    }
  }

  checkTimeOver(end: any) {
    let hour: any = end.split("T")[1].split(":")[0];
    let min: any = end.split("T")[1].split(":")[1];
    let formatedTime = this.datePipe.transform(end, 'yyyy-MM-dd HH:mm', 'UTC');
    let time = new Date(formatedTime);
    // time.setHours(parseInt(hour));
    // time.setMinutes(parseInt(min));
    const timeDiff = time.getTime() - new Date().getTime();
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    if (seconds < 0) {
      return true
    }
  }

  compareDate(startDate: any, id: any) {
    let auctionStartDataTime = new Date(startDate).getTime();
    let currentDate = new Date().getTime();
    //capare Date and Time
    if (currentDate > auctionStartDataTime) {
      this.router.navigate(['/', 'auction-join?id=' + id]);
    } else {
      this.toastService.error('Auction has not started Yet');
    }
  }

  getAuctionList(page: any, pageLimit: any) {
    this.spinner.show();
    this.customLoader = true;
    let data = {
      pageLimit: pageLimit,
      page: page,
      inventoryStatus: 2,
      pendingcar: true,
      isAuctionActive: true,
      orderBy: 'auctionDate',
      fromData: this.datePipe.transform(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
      toData: this.datePipe.transform(
        new Date().setDate(new Date().getDate() + 2),
        'yyyy-MM-dd'
      ),
    };


    this.service.auctionListwithDate(data).subscribe((res: any) => {
      if (res.status == true) {

        let data = res.result;
        this.cdr.detectChanges();
        data.forEach(element => {
          let value = this.isToday(element.auctionDate);
          value === true ? this.todayAuctionsList.push(element) : this.auctionList.push(element);
        });
      }
      this.customLoader = false;
    });
    this.spinner.hide();
  }

  isAuctionJoinShow = false;
  auctionJoinHide() {
    this.sharedService.isShowheader$.subscribe((val: any) => {
      if (val === true) {
        this.isAuctionJoinShow = val;
      } else {
        this.isAuctionJoinShow = false;
      }
    })
  }

  isToday(dateString: string): boolean {
    const today = new Date();
    const date = new Date(this.datePipe.transform(dateString, 'yyyy-MM-dd', 'UTC'));
    let returnValue = false;
    if (date.getDate() <= today.getDate() &&
      date.getMonth() <= today.getMonth() &&
      date.getFullYear() <= today.getFullYear()) {
      returnValue = true
    }
    else {
      returnValue = false;
    }
    return returnValue;
  }

  HeaderStyle1() {
    this.hideHeader = false;
    setTimeout(() => {
      this.hideHeader = true;
    }, 3000)
    this.sharedService.hideFooterElement(true);
    this.checkURL = true;
  }
  HeaderStyle2() {
    // this.sharedService.hideFooterElement(true);
    this.checkURL = false;
  }

  logout() {
    this.hideHeader = false;
    setTimeout(() => {
      this.hideHeader = true;
    }, 3000)
    this.sharedService.hideFooterElement(true);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('lang');
    localStorage.removeItem("sortTypeKey");
    localStorage.removeItem("sortTypeOrder");
    localStorage.removeItem("isViewTypeList");
    this.router.navigateByUrl('/home');
    this.currentUser = null;
    //location.reload();
    this.cdr.detectChanges();
  }

  activateSignUpPopUp(isEnable) {
    this.signUpService.EnableSignUpModel(isEnable)
  }

  
  lowercaseTitle(title) {
    title = title.toLowerCase();
    return title;
  }
}