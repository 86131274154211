<app-header class="hader-dark-bg-- header-fixed"></app-header>

<div class="my-account-home clearfix">
    <div class="sidebar-main-wpr" [@widthGrow]="state" style="height: '{getRightDivHeight()}';overflow: hidden;">
        <div class="menu-overlay" *ngIf="menuOverlay"></div>
        <app-buyer-sidebar (click)="changeState()"></app-buyer-sidebar>
    </div>
    <div class="main-dashboard-panel my-account-panel" style="margin-top: 25px;">
        <div class="container-fluid">
            <div class="row">
                <div class="router-wpr col-md-12">
                    <div class="dashboard-menu" (click)="changeState()">
                        <i class="fa-solid fa-bars"></i>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>