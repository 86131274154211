import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import screenfull from 'screenfull';
import { SallerBuyerService } from '../dashboard/sallerBuyer.service';
import { BidStockService } from '../auction-join/services/bid-stock.service ';
import { AuctionStockService } from '../auction-join/services/auction-stock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ConfirmdialogServiceService } from 'src/app/shared/confirmation-dialog/confirmdialog-service.service';
import { SharedService } from 'src/app/shared/services1/shared.service';

@Component({
  selector: 'app-youtube-banner',
  templateUrl: './youtube-banner.component.html',
  styleUrls: ['./youtube-banner.component.scss']

})
export class YoutubeBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  aprovalStatus: boolean = false;
  private destroy$ = new Subject<void>();
  historyData: any = [{ isFrontEnd: true }];
  images = ['../../../assets/img/imageres.jpg', '../../../assets/img/7-imga.jpg'];
  auctionId: any = '658ae641c557f13c4f92042a';
  auctionDetailsData: any;
  responseData: any;
  currentUser: any;
  showVehicle: any;
  bidedData: any;
  isSold: boolean = false;
  isUnSold: boolean = false;
  isUnderApproval: boolean = false;
  isPause: boolean = false;
  currentVehicleOnBid: any;
  upcomingInventoryList: any;
 
  constructor(
    private bidStockService: BidStockService,
    private auctionService: AuctionStockService,
    private service: SallerBuyerService,
    private router: ActivatedRoute,
    private loader: SpinnerVisibilityService,
    private confirmationService: ConfirmdialogServiceService,
    public sharedService :SharedService,
  ) { 
  }
  ngOnInit() {
    this.wakeupfunction();
    this.currentUser = { _id: '644bb2165b522f97549c00a6' };
    this.router.queryParams.subscribe((params) => {
      this.auctionId = params['id'];
      this.getAuctionDetail(this.auctionId);
      this.firstTimeFunctionInitialization();
    });
  }

  async firstTimeFunctionInitialization() {
    this.isCarApprovedSubscribe();
    this.lastFiveBidsSubscribe();
    await this.getPendingCar();
    this.forceRefreshSubscribe();
    this.auctionResumePauseFunSubscribe();
    this.isSoldSubscribe();
    this.isUnSoldSubscribe();
    this.getSkipStatusSubscribe();
    this.isUnderApprovalSubscribe();
    this.isAuctionVehicleReloadSubscription();
    this.RevceiveFunctionalAudioMessageSubscription();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.bidStockService.unsubscribeReceiveFunctionalAudioMessage();
    this.bidStockService.socketend(this.auctionId, this.currentUser._id);

  }

  ngAfterViewInit() {
    this.isPause = this.auctionDetailsData?.isAuctionPaused;
  }

  toggleFullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }
  getAuctionDetail(id: any) {
    this.service
      .auctionDetail({ auctionId: id })
      .subscribe((data: any) => {
        if (data.result) {
          this.auctionDetailsData = data.result;
          this.isPause = this.auctionDetailsData?.isAuctionPaused;
        }
      });
  }

  isSoldSubscribe() {
    this.bidStockService.isSoldSubscription()
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.status == true) {
            this.isSold = res.status;
            setTimeout(() => {
              this.allReloadFunction();
            }, 5000);
          }
        }
      })
  }

  isUnderApprovalSubscribe() {
    this.bidStockService.isUnderApprovalSubscribe()
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.status == true) {
            this.isUnderApproval = res.status;
            setTimeout(() => {
              this.allReloadFunction();
            }, 5000);
          }
        }
      })
  }

  isUnSoldSubscribe() {
    this.bidStockService.isUnSoldSubscription()
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.status == true) {
            this.isUnSold = res.status;
            setTimeout(() => {
              this.allReloadFunction();
            }, 5000);
          }
        }
      })
  }

  auctionResumePauseFunSubscribe() {
    this.bidStockService
      .pauseAuction()
      .subscribe((val: any) => {
        if (val == true) {
          this.isPause = true;
        } else if (val === false) {
          this.isPause = false;
        }
      });
  }

  lastFiveBidsSubscribe() {
    this.bidStockService.lastFiveBidsSubscription()
      .subscribe((res: any) => {
        if (res) {
          this.historyData = res;
        }
      })
  }

  isAuctionVehicleReloadSubscription() {
    this.bidStockService.isAuctionVehicleReloadSubscription()
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.status == true) {
            this.allReloadFunction();
          }
        }
      })
  }


  allReloadFunction() {
    this.isSold = false;
    this.isUnSold = false;
    this.isUnderApproval = false;
    this.getPendingCar();
  }

  isCarApprovedSubscribe() {
    this.bidStockService
      .isCarApproved()
      .subscribe((val: any) => {
        if (val === true || val === false) {
          this.aprovalStatus = val ? true : false;
        }
      });
  }

  getSkipStatusSubscribe() {
    this.bidStockService
      .getSkipStatus(this.auctionId, this.currentVehicleOnBid?.inventoryId)
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.status == true) {
            setTimeout(() => {
              this.allReloadFunction();
            }, 1000);
          }
        }
      });
  }

  forceRefreshSubscribe() {
    this.bidStockService
      .forceRefresh()
      .subscribe((val: any) => {
        /*  force refresh */
        if (val?.status == true) {
          if (this.currentVehicleOnBid?.inventoryId != val?.inventoryId) {
            window.location.reload();
          }
        }
      });
  }

  emitIsCarApproved() {
    this.bidStockService.emitIsCarApproved(this.auctionId, this.currentVehicleOnBid?.inventoryId);
  }

  emitLastFiveBids() {
    this.bidStockService.emitLastFiveBides(this.auctionId, this.currentVehicleOnBid?.inventoryId);
  }

  joinAcution() {
    this.bidStockService.joinAuction(this.auctionId, this.currentVehicleOnBid.inventoryId, this.currentUser._id, this.auctionDetailsData?.auctionStart);
  }

  GetAuctionInformation(auctionId: any): Promise<any> {
    return this.auctionService
      .auctionVehicleList({
        pageLimit: 3,
        page: 1,
        auctionId: auctionId,
        inventoryStatus: 2,
        userId: this.currentUser._id,
        status: 'pending',
      })
      .toPromise();
  }

  async getPendingCar(): Promise<any> {
    let data = {
      auctionId: this.auctionId,
      page: 1,
      pageLimit: 4
    }
    this.loader.hide();
    const response: any = await this.auctionService.getPendingAuctionVehicle(data).toPromise();

    if (response?.status) {
      if (response?.result?.length > 1) {
        this.currentVehicleOnBid = response.result[0];
        this.upcomingInventoryList = response.result.slice(1);
      } else {
        this.currentVehicleOnBid = response.result[0];
        this.upcomingInventoryList = [];
      }
      if (response?.result?.length == 0) {
        window.location.href = '/auctions';
      }
      this.joinAcution();
      this.emitLastFiveBids();
      this.emitIsCarApproved();

      // Update image URLs with CDN base URL
      this.currentVehicleOnBid.images = this.currentVehicleOnBid.images.map((img: string) => this.sharedService.cloudFrontUrl("500x400",img));
    }
  }
 

  wakeupfunction() {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    function handleVisibilityChange() {
      if (document.hidden) {
        // Trigger your desired action here
      } else {
        this.allReloadFunction();
        // Trigger your desired action here
      }
    }
  }

  getTimeDiffrence(endDate: any) {
    let start = new Date().getTime();
    let end = new Date(endDate).getTime();

    let diff = end - start;
    let seconds = Math.floor((diff / 1000) % 60);
    return seconds;
  }

  RevceiveFunctionalAudioMessageSubscription() {
    this.bidStockService.RevceiveFunctionalAudioMessageSubscription()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res && Object.keys(res).length != 0 && res.constructor === Object) {
          if (res.type) {
            res.type == 'background' ? this.playBackgroundMusic(res) : this.playBeep(res.link, res.duration);
          }
        }
      })
  }

  playBeep(message, durationtime) {
    let audio = new Audio(message);
    audio.volume = 0.5;
    if (!this.currentUser.isAuctionMute) {
      audio.play()
    };
  }

  playMessage(message: any) {
    var audio = new Audio(message);

    if (!this.currentUser.isAuctionMute) { audio.play() };
  }

  async waitForUserClick(): Promise<MouseEvent> {
    return new Promise((resolve) => {
      const listener = (event: MouseEvent) => {
        window.removeEventListener('click', listener);
        resolve(event);
      };
      window.addEventListener('click', listener);
    });
  }

  backgroundMusic: any;
  playBackgroundMusic(data: any) {
    if (data && data?.link && data?.link != '') {
      this.backgroundMusic = new Audio(data?.link);
      this.backgroundMusic.loop = data?.loop;
      this.backgroundMusic.volume = data?.volume;
      if (!this.currentUser.isAuctionMute) {
        this.backgroundMusic.play().catch(async (reason: any) => {
          const event = await this.waitForUserClick();
          this.backgroundMusic.play();
        });
      };
    }
  }
}

