import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-buyer-dashboard',
  templateUrl: './buyer-dashboard.component.html',
  styleUrls: ['./buyer-dashboard.component.scss'],
  animations: [
    trigger('widthGrow', [
      state(
        'closed',
        style({
          width: 0,
        })
      ),
      state(
        'open',
        style({
          width: 230,
        })
      ),
      transition('* => *', animate(150)),
    ]),
  ],
})
export class BuyerDashboardComponent implements OnInit {

  state = 'closed';

  isMenuOpen = false;
  menuOverlay = false;
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  constructor() { }

  ngOnInit(): void {
  }

  changeState(): void {
    this.state == 'closed' ? (this.state = 'open') : (this.state = 'closed');
    this.menuOverlay = !this.menuOverlay;
  }
}
