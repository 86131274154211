import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class BuyerSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  } 

}
