import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuctionStockService {
  url: any = environment.API_URL;

  constructor(private http: HttpClient) { }

  getUserList(data: any) {
    return this.http.post(this.url + 'user/list', data);
  }

  updateUser(data: any) {
    return this.http.patch(this.url + 'user/update', data);
  }

  auctionList(data: any) {
    return this.http.post(this.url + 'auction/list', data);
  }

  inventoryList(data: any) {
    return this.http.post(this.url + 'inventory/list', data);
  }

  addAuction(data: any) {
    return this.http.post(this.url + 'auction/add', data);
  }

  updateAuction(data: any) {
    return this.http.patch(this.url + 'auction/update ', data);
  }
  removeAuctionVehicle(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({}),
      body: data,
    };
    return this.http.delete(
      this.url + 'auction/removeAuctionVehicle ',
      httpOptions
    );
  }

  branchList(data: any) {
    return this.http.post(this.url + 'master/branchlist', data);
  }

  checkAccess(data: any) {
    return this.http.post(this.url + 'master/sidebarMenuAcess', data);
  }

  addMoreAuction(data: any) {
    return this.http.post(this.url + 'auction/addmore', data);
  }

  addWatchList(data: any) {
    return this.http.post(this.url + 'inventory/watchlistadd', data);
  }

  updateReservePrice(data: any) {
    return this.http.patch(this.url + 'bid/update-reserve-price', data);
  }

  auctionVehicleList(data: any) {
    return this.http.post(this.url + 'auction/auctionvehicleslist', data);
  }

  auctionVehicleUpdate(data: any, inventoryId: any, auctionId: any) {
    return this.http.patch(this.url + 'auction/auctionVehicleUpdate?fromBid=' + true + '&inventoryId=' + inventoryId + '&auctionId=' + auctionId, data);
  }

  auctionUpdateDisplayNo(data: any) {
    return this.http.patch(this.url + 'auction/auctionVehicleUpdate', data);
  }

  auctionReportList(data: any) {
    return this.http.post(this.url + 'auction/auctionvehiclesReport', data);
  }

  auctionHistory(data: any) {
    return this.http.post(this.url + 'auction/auctionvehiclesHistory', data);
  }

  SalerList(data: any) {
    return this.http.post(this.url + 'user/list', data);
  }


  getPendingAuctionVehicle(data: any) {
    return this.http.post(this.url + 'auction/pending-auction-vehicles', data);
  };


  getFinishedAuctionVehicle(data: any) {
    return this.http.post(this.url + 'auction/finished-auction-vehicles', data);
  };

  getSkippedAuctionVehicle(data: any) {
    return this.http.post(this.url + 'auction/skipped-auction-vehicles', data);
  };
}
