import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BidStockService {
  url: any = environment.API_AUCT_URL;
  public lastFiveBids$: BehaviorSubject<any> = new BehaviorSubject([{ isFrontEnd: true }]);
  public latestBidData$: BehaviorSubject<string> = new BehaviorSubject('');
  public messageData$: BehaviorSubject<string> = new BehaviorSubject('');
  public messageSound$: BehaviorSubject<string> = new BehaviorSubject('');
  public activeUser$: BehaviorSubject<string> = new BehaviorSubject('');
  public timerDuration$: BehaviorSubject<string> = new BehaviorSubject('');
  public onlineBidLast$: BehaviorSubject<string> = new BehaviorSubject('');
  public highLightFlag$: BehaviorSubject<string> = new BehaviorSubject('');
  public skipStatus$: BehaviorSubject<object> = new BehaviorSubject({});
  public skippedAuctionVehicles$: BehaviorSubject<string> = new BehaviorSubject('');
  public auctionDetails$: BehaviorSubject<string> = new BehaviorSubject('');
  public isUnsold$: BehaviorSubject<object> = new BehaviorSubject({});
  public isSold$: BehaviorSubject<object> = new BehaviorSubject({});
  public isUnderApproval$: BehaviorSubject<object> = new BehaviorSubject({});
  public timerOnlineAuction$: BehaviorSubject<object> = new BehaviorSubject({});
  public isTextMessage$: BehaviorSubject<object> = new BehaviorSubject({});
  public forcefullyRefresh$: BehaviorSubject<object> = new BehaviorSubject({});
  public isBothAudioTextMessage$: BehaviorSubject<object> = new BehaviorSubject({});
  public isAudioMessage$: BehaviorSubject<object> = new BehaviorSubject({});
  public pendingAuctionVehicle$: BehaviorSubject<string> = new BehaviorSubject('');
  public inventryData$: BehaviorSubject<string> = new BehaviorSubject('');
  public isCarApprovedData$: BehaviorSubject<string> = new BehaviorSubject('');
  public isProcessDone$: BehaviorSubject<string> = new BehaviorSubject('');
  public isSequenceChanged$: BehaviorSubject<string> = new BehaviorSubject('');
  public isProcessingData$: BehaviorSubject<string> = new BehaviorSubject('');
  public isPauseAuction$: BehaviorSubject<any> = new BehaviorSubject('');
  public isRefreshForcce$: BehaviorSubject<string> = new BehaviorSubject('');
  public receiveFunctionalAudioMessage$: BehaviorSubject<object> = new BehaviorSubject({});
  public isAuctionVehicleReload$: BehaviorSubject<string> = new BehaviorSubject('');
  public isRefreshInventoryList$: BehaviorSubject<object> = new BehaviorSubject({});

  constructor(private http: HttpClient, private socket: Socket) { }

//#region backend seperation for auction 

SalerList(data: any) {
  return this.http.post(this.url + 'user/list', data);
}

deleteWatchList(data:any){
  const httpOptions = {
      headers: new HttpHeaders({}),body:data
  }
  return this.http.delete(this.url + 'inventory/watchlistdelete',httpOptions);
}

watchListAdd(data:any){
  return this.http.post(this.url + 'inventory/watchlistadd',data);
}

getPendingAuctionVehicle(data: any) {
  return this.http.post(this.url + 'auction/pending-auction-vehicles', data);
};


getFinishedAuctionVehicle(data: any) {
  return this.http.post(this.url + 'auction/finished-auction-vehicles', data);
};

getSkippedAuctionVehicle(data: any) {
  return this.http.post(this.url + 'auction/skipped-auction-vehicles', data);
};

auctionDetail(data:any){
  return this.http.post(this.url + 'auction/get-auction',data);
}

auctionListwithDate(data:any){
  return this.http.post(this.url + 'auction/auctionlist',data);
}

updateuser(data: any) {
  return this.http.patch(this.url + 'user/update', data);
}

//#endregion

  joinAuction(auctionId: any, inventoryId: any, userId: any, auctionStart: any) {
    this.socket.connect();
    this.socket.emit('joinAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      userId,
      auctionStart
    });
  }

  joinAuctionvehicle(auctionId: any, inventoryId: any) {
    this.socket.connect();
    this.socket.emit('joinAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  }

  addBid(data: Object) {
    this.socket.connect();
    this.socket.emit('bidData', data);
  }

  public showHighLightEventSubscription = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('highlightEvent', (data: any) => {
      this.highLightFlag$.next(data);
    });
    return this.highLightFlag$.asObservable();
  };

  public emitshowHighlight(auctionId: any, inventoryId: any, flag) {
    this.socket.connect();
    this.socket.emit('showHighlight', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      flag: flag,
    });
  };

  public getUserCounts = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('userCount', (data: any) => {
      this.activeUser$.next(data);
    });
    return this.activeUser$.asObservable();
  };

  public getInventoryData = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('inventryData', (data: any) => {
      this.inventryData$.next(data);
    });
    return this.inventryData$.asObservable();
  };

  public isProcessDone = () => {
    this.socket.connect();
    this.isProcessDone$.next('');
    this.socket.on('isProcessDone', (data: any) => {
      this.isProcessDone$.next(data);
    });
    return this.isProcessDone$.asObservable();
  };

  public isCarApproved = () => {
    this.socket.connect();
    this.socket.on('is-inventory-approved', (data: any) => {
      this.isCarApprovedData$.next(data);
    });
    return this.isCarApprovedData$.asObservable();
  };

  public emitIsCarApproved(auctionId: any, inventoryId: any) {
    this.socket.emit('is-inventory-approved', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  public isSequenceChanged = () => {
    this.socket.connect();
    this.socket.on('isSequenceChanged', (data: any) => {
      this.isSequenceChanged$.next(data);
    });
    return this.isSequenceChanged$.asObservable();
  };

  public isProcessing = () => {
    this.socket.connect();
    this.socket.on('isProcessing', (data: any) => {
      this.isProcessingData$.next(data);
    });
    return this.isProcessingData$.asObservable();
  };

  public pauseAuction = () => {
    this.socket.connect();
    this.socket.on('pauseAuction', (data: any) => {
      console.log("==========pauseAuction service=====", data);
      this.isPauseAuction$.next(data);
    });
    return this.isPauseAuction$.asObservable();
  };

  public forceRefresh = () => {
    this.socket.connect();
    this.socket.on('forceRefresh', (data: any) => {
      this.isRefreshForcce$.next(data);
    });
    return this.isRefreshForcce$.asObservable();
  };

  public RevceiveFunctionalAudioMessageSubscription = () => {
    this.receiveFunctionalAudioMessage$.next({});
    this.socket.on('receiveFunctionalAudioMessage', (data: any) => {
      this.receiveFunctionalAudioMessage$.next(data);
    });
    return this.receiveFunctionalAudioMessage$.asObservable();
  };

  public unsubscribeReceiveFunctionalAudioMessage(): void {
    this.socket.removeListener('receiveFunctionalAudioMessage');
  }

  public getOnlineBidTime = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('onlineBidSartTime', (data: any) => {
      this.onlineBidLast$.next(data);
    });
    return this.onlineBidLast$.asObservable();
  };

  public lastFiveBidsSubscription() {
    this.socket.connect();
    this.socket.on('last-five-bids', (data: any) => {
      this.lastFiveBids$.next(data);
    });
    return this.lastFiveBids$.asObservable();
  };

  public emitLastFiveBides(auctionId: any, inventoryId: any) {
    this.socket.emit('last-five-bids', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  public getMessageSubscription() {
    this.socket.connect();
    this.messageData$.next('');
    this.socket.on('bidMessage', (data: any) => {
      this.messageData$.next(data);
    });
    return this.messageData$.asObservable();
  };

  public playSoundSubscription() {
    this.socket.connect();
    this.socket.on('beepEvent', (data: any) => {
      this.messageSound$.next(data);
    });
    return this.messageSound$.asObservable();
  };

  public closeBidsSubscription(auctionId: any, inventoryId: any) {
    this.socket.connect();
    this.socket.emit('closeAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
    });
  };

  public puseBidsSubscription = (auctionId: any, inventoryId: any, status: any) => {
    this.socket.connect();
    this.socket.emit('puaseAuction', {
      auctionId: auctionId,
      inventoryId: inventoryId,
      status: status,
    });
  };

  public isAuctionVehicleReloadSubscription() {
    this.socket.connect();
    this.socket.on('refreshInventoryList', (data: any) => {
      this.isAuctionVehicleReload$.next(data);
    });
    return this.isAuctionVehicleReload$.asObservable();
  };

  public onlineTimerDurationSubscription() {
    this.socket.connect();
    this.socket.on('onlieTimerDuration', (data: any) => {
      this.timerDuration$.next(data);
    });
    return this.timerDuration$.asObservable();
  };

  public skipStatusSubscribe() {
    this.socket.connect();
    this.skipStatus$.next({});
    this.socket.on('skipStatus', (data: any) => {
      this.skipStatus$.next(data);
    });
    return this.skipStatus$.asObservable();
  };

  public auctionDetailsSubscription() {
    this.socket.connect();
    this.socket.on('auction-details', (data: any) => {
      this.auctionDetails$.next(data);
    });
    return this.auctionDetails$.asObservable();
  };

  public isUnSoldSubscription() {
    this.isUnsold$.next({});
    this.socket.connect();
    this.socket.on('isUnSold', (data: any) => {
      this.isUnsold$.next(data);
    });
    return this.isUnsold$.asObservable();
  };

  public isSoldSubscription() {
    this.isSold$.next({})
    this.socket.connect();
    this.socket.on('isSold', (data: any) => {
      this.isSold$.next(data);
    });
    return this.isSold$.asObservable();
  };

  public isUnderApprovalSubscribe() {
    this.socket.connect();
    this.isUnderApproval$.next({})
    this.socket.on('isUnderApproved', (data: any) => {
      this.isUnderApproval$.next(data);
    });
    return this.isUnderApproval$.asObservable();
  };

  public timerSubscription = () => {
    this.timerOnlineAuction$.next({});
    this.socket.on('timer', (data: any) => {
      this.timerOnlineAuction$.next(data);
    });
    return this.timerOnlineAuction$.asObservable();
  };

  public isTextMessageSubscription = () => {
    this.isTextMessage$.next({});
    this.socket.on('receiveTextMessage', (data: any) => {
      this.isTextMessage$.next(data);
    });
    return this.isTextMessage$.asObservable();
  };

  public isAudioMessageSubscription = () => {
    this.isAudioMessage$.next({});
    this.socket.on('receiveAudioMessage', (data: any) => {
      this.isAudioMessage$.next(data);
    });
    return this.isAudioMessage$.asObservable();
  };

  public isBothTextMessageSubscription = () => {
    this.isBothAudioTextMessage$.next({});
    this.socket.on('receiveBothMessages', (data: any) => {
      this.isBothAudioTextMessage$.next(data);
    });
    return this.isBothAudioTextMessage$.asObservable();
  };


  emitFunctionalMessage(type:any) {
    this.socket.emit('emitFunctionalMessage',type);
  };
  
  emitAuctionDetails(auctionId: any, inventoryId: any) {
    this.socket.emit('auction-details', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  public emitTimerInfo = (auctionId: any, inventoryId: any) => {
    this.socket.emit('timer-info', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  socketon() {
    this.socket.connect();
  }

  socketend(auctionId: any, userId: any) {
    this.socket.connect();
    this.socket.emit('disconnectUser', { auctionId, userId });
    this.socket.disconnect();
  }



  public callBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.emit('getBidData', {
      auctionId: auctionId,
      inventoryId: inventoryId
    });
  };

  public getSkipStatus = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('skipStatus', (data: any) => {
      this.skipStatus$.next(data);
    });
    return this.skipStatus$.asObservable();
  };


  public getBids = (auctionId: any, inventoryId: any) => {
    this.socket.connect();
    this.socket.on('getData', (data: any) => {
      this.latestBidData$.next(data);
    });
    return this.latestBidData$.asObservable();
  };


  allvalueReset(){
    // this.lastFiveBids$.next({ isFrontEnd: true });
    // this.latestBidData$.next('');
    // this.messageData$.next('');
    // this.messageSound$.next('');
    // this.activeUser$.next('');
    // this.timerDuration$.next('');
    // this.onlineBidLast$.next('');
    // this.highLightFlag$.next('');
    // this.skipStatus$.next({});
    // this.skippedAuctionVehicles$.next('');
    // this.auctionDetails$.next('');
    // this.isUnsold$.next({});
    // this.isSold$.next({});
    // this.isUnderApproval$.next({});
    // this.timerOnlineAuction$.next({});
    // this.isTextMessage$.next({});
    // this.forcefullyRefresh$.next({});
    // this.isBothAudioTextMessage$.next({});
    // this.isAudioMessage$.next({});
    // this.pendingAuctionVehicle$.next('');
    // this.inventryData$.next('');
    // this.isCarApprovedData$.next('');
    // this.isProcessDone$.next('');
    // this.isSequenceChanged$.next('');
    // this.isProcessingData$.next('');
    this.isPauseAuction$.next('');
    // this.isRefreshForcce$.next('');
    // this.receiveFunctionalAudioMessage$.next({});
    // this.isAuctionVehicleReload$.next('');
    // this.isRefreshInventoryList$.next({});
  }
}
