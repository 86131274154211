import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailSendComponent } from './components/email-send/email-send.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  { path: 'login', component: MainComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'email-send', component: EmailSendComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' }

];



@NgModule({
  declarations: [
    MainComponent,
    SignupComponent,
    ForgotPasswordComponent,
    EmailSendComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    RouterModule.forChild(routes)
  ],
})
export class AuthModule { }
