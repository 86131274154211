import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class SingUpService {
    url: any = environment.API_URL;
    lang = sessionStorage.getItem('lang');
    // isSignUp:boolean = false;
    @Output() signUpEvent = new EventEmitter<boolean>();
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    EnableSignUpModel(isEnable){
        if(isEnable == true){
            this.signUpEvent.emit(true);
        }
        else{
            this.signUpEvent.emit(false);
        }
    }

    getDashboardData(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({ lang: this.lang ? this.lang : 'eng', 'Content-Type': 'application / json' })
        };
        return this.http.post(this.url + 'user/admin/dashboard', data);
    }

    registerUser(data: any) {
        return this.http.post(this.url + 'user/register', data);
    }

    updateUser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }


    login(data:any) {
        return this.http.post<any>(this.url + 'user/login', data);
    }

    GetUserByEmail(data:any) {
        return this.http.patch<any>(this.url + 'user/reset-password', data);
    }
    
    ResendOtp(data:any) {
        return this.http.post<any>(this.url + 'user/resend-otp', data);
    }
    
    updateProfileOtp(data:any) {
        return this.http.post<any>(this.url + 'user/update-profile-otp', data);
    }

    UserValidityCheck(data:any) {
        return this.http.post<any>(this.url + 'user/check-valid', data);
    }

    getTermsCondition(data: any) {
        return this.http.post(this.url + 'termscondition/get-terms-condition', data);
    }

    acceptTermsCondition(data: any) {
        return this.http.post(this.url + 'termscondition/accept-terms-condition', data);
    }

    UpdatePassword(data:any) {
        return this.http.patch<any>(this.url + 'user/update-password', data);
    }


    verifyOtp(data:any){
        return this.http.post<any>(this.url + 'user/verify-otp', data);
    }

    uploadFile(file: any) {
        return this.http.post(this.url + "upload/file", file);
    }

}