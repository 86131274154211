<section class="content auction-join-wpr">
    <div class="baner-display">
        <div class="with-list expended-with-list">
            <div class="auction-list-details-wpr ovrflow">
                <div>
                </div>
            </div>
            <div class="col-md-12  right-side-auction-card ">
                <div class="auction-join-main-card">
                    <div class="page-container-body">
                        <div class="content auction-join-inner">
                            <div class="row" style="
                        background-color: whitesmoke;display: flex;
                        justify-content: center;
                        align-items: center;">
                                <div class="col-md-2" style=" margin-top: 12px; margin-bottom: 15px;">
                                    <img src="../../../assets/img/logo.png" style="height: 100%;
                                width: 75%;">
                                </div>
                                <div class="col-md-10">
                                    <h1 class="youtube-header-banner-1"
                                        [ngStyle]="{'font-size': currentVehicleOnBid?.carDescription.length <= 25 ? '75px' : '64px'}">
                                        {{currentVehicleOnBid?.carDescription}} | Run#
                                        {{currentVehicleOnBid?.fixedDisplayNo}}
                                    </h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" style="margin-top: 4px; ">
                                    <div class="row p-slider" style="background-color: whitesmoke;">
                                        <div class="col-md-5 slider-details-wpr "
                                            style="padding: 3px; background-color: whitesmoke;">
                                            <div class="row">
                                                <div class="col-md-12 slider-details-wpr">
                                                    <div style="position: relative;">
                                                        <div class="chip chip-green mt-2 blinkAnimation"
                                                            *ngIf="aprovalStatus">
                                                            <div class="yutube-banner">Approved</div>
                                                        </div>
                                                        <div class="chip chip-red mt-2 blinkAnimation"
                                                            *ngIf="!aprovalStatus">
                                                            <div class="yutube-banner">Under Approval</div>
                                                        </div>
                                                        <div class="img-zoom-container">
                                                            <img *ngIf="currentVehicleOnBid?.images?.length == 0"
                                                                [defaultImage]="'../../../assets/images/lazy-loader3.gif'"
                                                                [lazyLoad]="sharedService.cloudFrontUrl('400x300',currentVehicleOnBid[0]?.singleImages)" />
                                                            <carousel [interval]="2000"
                                                                style="height: 500px !important;">
                                                                <slide *ngFor="let img of currentVehicleOnBid?.images">
                                                                    <img *ngIf="currentVehicleOnBid?.images.length > 0"
                                                                        [defaultImage]="'../../../assets/images/lazy-loader3.gif'"
                                                                        [lazyLoad]="sharedService.cloudFrontUrl('400x300',img)" />
                                                                </slide>
                                                            </carousel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 slider-thumbnail mt-2">
                                                    <div class="youtube-thumbnail-img-slide">
                                                        <img [lazyLoad]="img.image"
                                                        [defaultImage]="img.image ? img.image : '../../../assets/img/default-car123123.jpg'"
                                                        class="youtube-thumbnail-img"
                                                            *ngFor="let img of currentVehicleOnBid?.vinimages">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-youtube-1 mt-2">
                                                <div class="bid-youtube card"
                                                    [ngClass]="{'approved-bg': aprovalStatus, 'under-approval-bg': !aprovalStatus}">
                                                    <div class="card-youtube">
                                                        <div class="card-heading-yutube"
                                                            *ngIf="!(historyData[0]?.isFrontEnd == true)">
                                                            <h1><span
                                                                    [ngStyle]="{'color': aprovalStatus ? 'green' : 'red'}">{{historyData[0]?.calcAmount}}</span>
                                                                AED </h1>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-md-3 slider-thumbnail card-youtube-1" style="height: 100%;">
                                            <h1 style="background-color: whitesmoke;">Car Description</h1>
                                            <div class="table-responsive table-card table-style-2 table-youtube">
                                                <table
                                                    class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="font-size: 22px;">Seq Number</td>
                                                            <td class="text-right" style="font-size: 22px;">
                                                                {{currentVehicleOnBid?.fixedDisplayNo}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Lot Number</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.lot}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Vin Number</td>
                                                            <td class="text-right" style="font-size: 22px;"> {{currentVehicleOnBid?.vin?.length>8
                                                                ? '**' : ''}}{{currentVehicleOnBid?.vin?.length > 8 ?
                                                                currentVehicleOnBid?.vin?.slice(-8) :
                                                                currentVehicleOnBid?.vin}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Make</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.make}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Model</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.model}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 20px;">Year</td>
                                                            <td class="text-right" style="font-size: 20px;">{{currentVehicleOnBid?.year}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Body Type</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.body}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Engine Type</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.engine}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Cylinder</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.cylinder == 0
                                                                ? 'NA' : currentVehicleOnBid?.cylinder ?
                                                                currentVehicleOnBid?.cylinder : 'NA'}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Transmission Type</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.transmission ?
                                                                currentVehicleOnBid?.transmission : 'NA'}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Fuel Type</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.fuelType}}
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Color Exterior</td>
                                                            <td class="text-right" style="font-size: 22px;">
                                                                {{currentVehicleOnBid?.exteriorColor}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Driven</td>
                                                            <td class="text-right" style="font-size: 22px;">{{currentVehicleOnBid?.driveType}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Mileage</td>
                                                            <td class="text-right" style="font-size: 22px;">{{ (currentVehicleOnBid?.mileage === 0 || currentVehicleOnBid?.mileage === 1) ? '-' : currentVehicleOnBid?.mileage + ' mi' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 22px;">Remarks</td>
                                                            <td class="text-right" style="font-size: 22px;">
                                                                {{currentVehicleOnBid?.inventoryRemarks}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style="padding: 3px;">
                                            <div class="bid-history card" style="background-color: whitesmoke;">
                                                <div class="card-header">
                                                    <h1 class="upcomming-youtube"
                                                        style="background-color: white;  border-radius: 8px;"> Auction
                                                        History </h1>
                                                </div>
                                                <div class="card-body"
                                                    *ngIf="!(historyData[0]?.showHistory == false || historyData[0]?.isFrontEnd == true)">
                                                    <ul class="history-list">
                                                        <div class="stepper-line"></div>
                                                        <li *ngFor="let i of historyData; let first = first"
                                                            [ngClass]="{'online-li': i.userUniqueIdentifier !== '', 'blink': i.userUniqueIdentifier !== '' && first}">
                                                            AED {{i.calcAmount}} by {{i.userName || i.userName}} -
                                                            <span *ngIf="i.userUniqueIdentifier !== ''"
                                                                class="online-status"><strong>Online</strong></span>
                                                            <span *ngIf="i.userUniqueIdentifier == ''">Onsite</span>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-youtube-1 mt-2" style="background-color: whitesmoke;">
                                                <div class="col-md-12">
                                                    <h1 class="upcomming-youtube"
                                                        style="background-color: white;  border-radius: 8px;"> Upcoming
                                                        Cars</h1>
                                                </div>
                                                <div class="card-body scrollbar-style-1"
                                                    *ngFor="let car of upcomingInventoryList"
                                                    style=" max-height: 500px; overflow-x: clip;">
                                                    <div class="product-list-new product-card-view product-list-view">
                                                        <div class="row">
                                                            <div class="col-lg-3 items">
                                                                <div
                                                                    class="product-item product-item__style-2 product-item__border-radius-card ">
                                                                    <div class="product-youtube">
                                                                        <img *ngIf="car?.images[0]; else defaultImage" 
                                                                        [lazyLoad]="sharedService.cloudFrontUrl('200x200', car.images[0])"
                                                                        [defaultImage]="car.images[0] ? car.images[0] : '../../../assets/img/default-car123123.jpg'"
                                                                        alt="Car Image" />
                                                                        <ng-template #defaultImage>
                                                                          <img src="../../../assets/img/youtube-car.jpg" alt="Default Image" />
                                                                        </ng-template>
                                                                      </div>
                                                                      
                                                                    <div class="product-header-youtube">
                                                                        <h4 class="product-title-youtube">
                                                                            {{car?.carDescription}}
                                                                        </h4>
                                                                        <div class="product-main-info">
                                                                            <h5 class="product-title-info-youtube">
                                                                                Starting Bid: <span
                                                                                    class="product-title-info-value-youtube">AED</span>
                                                                                <span
                                                                                    class="product-title-info-highlited-youtube">{{car?.startingBid}}</span>
                                                                            </h5>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>