import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SallerBuyerService } from '../main/dashboard/sallerBuyer.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from '../shared/services1/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataServiceService } from '../shared/services/data-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmdialogServiceService } from '../shared/confirmation-dialog/confirmdialog-service.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
  viewInteriorVedio = false;
  viewExteriorVedio = false;
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  screenWidth: number;
  dialogRef: BsModalRef<any>;
  vechicleId: any;
  vehicleDetails: any;
  showVehicle: any;
  inventoryList: any = [];
  customBid: any;
  defaultImagCar: any;
  currentUser: any;
  bidHistory: any;
  auctionDetail: any;
  isAuctionVehicle: boolean = false;
  isNextCarAvailable: any;
  isPreviousCarAvailable: any;
  startingBid = 0;
  latestBidAmount = 0;

  constructor(
    private router: ActivatedRoute,
    private service: SallerBuyerService,
    private defaultImag: SharedService,
    private toastr: ToastrService,
    private route: Router,
    private dialog: BsModalService,
    private dialogService: ConfirmdialogServiceService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private confirmationDialog: ConfirmdialogServiceService,
    public sharedService: SharedService
  ) { }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.onResize();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.router.queryParams.subscribe((params: any) => {
      this.vechicleId = params['id'];
      if (this.vechicleId) {
        this.getInventoryList(this.vechicleId);
      }
    });
    this.defaultImagCar = this.defaultImag.getImage();
    this.getInventory();


    window.addEventListener('keydown', (e) => {
      let key = e.keyCode;
      let src = (<HTMLInputElement>document.getElementById('myimage')).src;
      if (key == 39) { //forward
        let index = 0
        for (var i of this.vehicleDetails?.vinimages) {
          if (i?.image == src) {
            let pos = index + 1;
            if (pos > this.vehicleDetails?.vinimages.length - 1) {
              pos = 0;
              this.showVehicle = this.vehicleDetails?.vinimages[pos].image;
            } else {
              this.showVehicle = this.vehicleDetails?.vinimages[pos].image;
            }
          }
          index++;
        }
      }

      if (key == 37) { // backward


        let index = 0
        for (var i of this.vehicleDetails?.vinimages) {
          if (i?.image == src) {
            let pos = index - 1;
            if (pos < 0) {
              pos = this.vehicleDetails?.vinimages.length - 1;
              this.showVehicle = this.vehicleDetails?.vinimages[pos].image;
            } else {
              this.showVehicle = this.vehicleDetails?.vinimages[pos].image;
            }
          }
          index++;
        }

      }
    })
  }
  customOptions: OwlOptions = {
    navText: [
      '<i class="fa fa-circle-chevron-left"></i>',
      '<i class="fa fa-circle-chevron-right"></i>',
    ],
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5
      },
      2000: {
        items: 6
      },
    },
    nav: true,
  };

  nextImage() {
    let src = (<HTMLInputElement>document.getElementById('myimage')).src;
    let index = 0
    let allimg = this.filterImage(this.vehicleDetails?.vinimages);
    for (var i of allimg) {
      if (i?.image == src) {
        let pos = index + 1;
        if (pos > allimg.length - 1) {
          pos = 0;
          this.showVehicle = allimg[pos].image;
        } else {
          this.showVehicle = allimg[pos].image;
        }
      }
      index++;
    }
  }

  previousImage() {
    let src = (<HTMLInputElement>document.getElementById('myimage')).src;
    let index = 0
    let allimg = this.filterImage(this.vehicleDetails?.vinimages);
    for (var i of allimg) {
      if (i?.image == src) {
        let pos = index - 1;
        if (pos < 0) {
          pos = allimg.length - 1;
          this.showVehicle = allimg[pos].image;
        } else {
          this.showVehicle = allimg[pos].image;
        }
      }
      index++;
    }
  }

  getAuctionDetail() {
    this.service.auctionDetail({ auctionId: this.vehicleDetails?.auctionId }).subscribe((data: any) => {
      this.auctionDetail = data.result;
    })
  }

  getColor(code) {
    return code;
  }

  getInventoryList(id) {
    this.vehicleDetails = undefined;
    this.showVehicle = undefined;
    let data = {
      page: 1,
      pageLimit: 10000,
      inventoryId: id,
      pagination: true,
      apiType: 'web',
      isInspectionNeeded: true,
    }
    if (this.currentUser)
      data['buyerId'] = this.currentUser?._id;
    this.service
      .getInventoryList(data)
      .subscribe((res: any) => {
        if (res.status == true) {
          this.vehicleDetails = res.result[0];
          let startingBid = this.vehicleDetails?.startingBid;
          this.customBid = startingBid + 500;
          this.bidHistory = null;
          this.getBidHIstory();
          this.isAuctionVehicle = this.vehicleDetails?.auctionId ? true : false;
          if (this.isAuctionVehicle)
            this.getAuctionDetail();
          this.isNextCarAvailable = this.vehicleDetails?.isNextCarAvailable
          this.isPreviousCarAvailable = this.vehicleDetails?.isPreviousCarAvailable
          if (this.vehicleDetails?.vinimages.length <= 0) {
            this.vehicleDetails?.vinimages.push({
              image: this.vehicleDetails?.vinimages[0],
            });
          }
          const filterimages = this.vehicleDetails?.vinimages.filter(x => x.type == 'carimage');
          this.showVehicle = filterimages.length > 0 ? filterimages[0].image : '';
          this.getInteriorVideo();
        }
        this.cdr.detectChanges();
      });
  }

  getInventory() {
    this.service
      .getInventoryList({ page: 1, pageLimit: 20, status: 2, apiType: 'web' })
      .subscribe((res: any) => {
        if (res.status == true) {
          this.inventoryList = res.result;
        }
      });
  }

  onClickImage(path: any) {
    this.showVehicle = path;
  }

  addToWatchList() {
    this.service.watchListAdd({ "inventoryId": this.vechicleId, "buyerId": this.currentUser?._id, auctionId: this.vehicleDetails?.auctionId }).subscribe((res: any) => {
      if (res.status == true) {
        this.getInventoryList(this.vechicleId);
        this.toastr.success("Successfully added");
      }
    })
  }

  deleteWatchList(id) {
    if (id) {
      this.service.deleteWatchList({ watchlistId: id }).subscribe((res: any) => {
        if (res.status == true) {
          this.getInventoryList(this.vechicleId);
          this.toastr.success("Successfully removed");
        }
      })
    } else {
      this.toastr.success("Please contact admin");
    }
  }

  decreaseVal(e: any) {
    let bidAmount = parseInt((<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML);
    if (bidAmount > this.customBid) {
      bidAmount = bidAmount - 500;
      (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = '' + bidAmount;
    }
  }

  increaseVal(e: any) {
    let bidAmount = parseInt((<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML);
    if (bidAmount) {
      if (bidAmount + 500 > this.currentUser?.creditLimit) {
        this.toastr.warning("you don't have enough credit ");
        return
      } else {
        bidAmount = bidAmount + 500;
      }
    } else {
      bidAmount = 500;
    }

    (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = '' + bidAmount;
  }

  showConfirmation(): void {
    const message = 'Are you sure you want to proceed?';
    const userReasonSubscription = this.confirmationDialog.showConfirmationDialog(message).subscribe((res: any) => {
      if (res) {
        this.preBid();
        userReasonSubscription.unsubscribe();
      } else {
        this.toastr.warning('You declined to Pre-bid', 'Warning');
        userReasonSubscription.unsubscribe();
      }
    });
  }

  preBid() {
    if (this.currentUser?.userType === 4 || this.currentUser?.userType === 5) {
      let bidAmount = (<HTMLInputElement>(
        document.getElementById('pre-bid-val')
      )).innerHTML;
      let reserve = parseInt(this.vehicleDetails?.reservePrice);
      if (!bidAmount) {
        return
      }
      if (bidAmount) {
        if (bidAmount > this.currentUser?.creditLimit) {
          this.toastr.warning("you don't have enough credit ")
          return
        }
      }
      let currAmount = this.currentBidCalculation(bidAmount);
      let inventoryId = this.vehicleDetails._id;
      let userId = this.currentUser._id;
      let userName = this.currentUser.name;

      this.service.updatePreBid({ currAmount: currAmount, inventoryId: inventoryId, userId: userId, userName: userName, bidStatus: 'Open', bidType: 'Pre', bidMode: 'online', calcAmount: parseInt(bidAmount), auctionId: this.vehicleDetails.auctionId, userUniqueIdentifier: this.currentUser.uniqueIdentifier, }).subscribe((res: any) => {
        if (res.status == true) {
          this.vehicleDetails.reservePrice = parseInt(bidAmount);
          this.toastr.success(res.message);
          this.getBidHIstory();
          (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = '';
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.openPopupAlert();
    }
  }


  currentBidCalculation(currentBidAmount) {
    if (this.latestBidAmount === 0 && this.startingBid === 0) {
      return currentBidAmount;
    };

    if (this.latestBidAmount > 0 && this.startingBid > 0) {
      return currentBidAmount - this.latestBidAmount;
    }
    else {
      return currentBidAmount - this.startingBid;
    }
  }
  showResult = false;
  name = 'Angular';

  imageZoom1(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    img.parentElement.insertBefore(lens, img);
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      e.preventDefault();
      pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    img.parentElement.insertBefore(lens, img);
    cx = img.width / lens.offsetWidth;
    cy = img.height / lens.offsetHeight;
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = img.width * cx + "px " + img.height * cy + "px";
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    img.addEventListener("mouseenter", showResult);
    img.addEventListener("mouseleave", hideResult);
    lens.addEventListener("mouseenter", showResult);
    lens.addEventListener("mouseleave", hideResult);

    function moveLens(e) {
      var pos, x, y;
      e.preventDefault();
      pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }

    function showResult() {
      result.style.display = "block";
    }

    function hideResult() {
      result.style.display = "none";
    }
  }


  getBidHIstory() {
    this.bidHistory = undefined;
    let data = {
      bidStatus: "Open",
      bidType: "Pre",
      page: 1,
      pageLimit: 1000000,
      userId: this.currentUser?._id,
      vin: this.vehicleDetails?.vin,
      auctionId: this.vehicleDetails?.auctionId
    }
    this.service.getPrebidWeb(data).subscribe((res: any) => {
      if (res.status == true) {
        this.bidHistory = res.result[0];
        let amt: any;
        if (this.bidHistory) {
          if (this.bidHistory?.bidDetails?.latestBidAmount) {
            this.latestBidAmount = this.bidHistory?.bidDetails?.latestBidAmount;
            if (this.bidHistory?.bidDetails?.latestBidAmount == this.currentUser?.creditLimit || this.bidHistory?.bidDetails?.latestBidAmount > this.currentUser?.creditLimit) {
              this.customBid = this.bidHistory?.bidDetails?.latestBidAmount + 500;
              (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = this.customBid;
              return
            } else {
              amt = this.bidHistory?.bidDetails?.latestBidAmount + 500;
              this.customBid = amt
            }
          } else {
            this.customBid = this.startingBid > 0 ? this.startingBid + 500 : 500;
          }
          (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = this.customBid;
        }else{
          this.customBid = this.vehicleDetails?.startingBid +500;
          (<HTMLInputElement>(document.getElementById('pre-bid-val'))).innerHTML = this.customBid;
        }
      }
    })
  }

  onClickDetails(id: any) {
    this.route.navigate(['/vehicle-details'], { queryParams: { id: id } });
  }

  transform(end: Date): string {
    let formatedTime = this.datePipe.transform(end, 'yyyy-MM-dd HH:mm', 'UTC');
    let time = new Date(formatedTime);
    const timeDiff = time.getTime() - new Date().getTime();
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

    this.cdr.markForCheck();
    if (seconds < 0) {
      return 'AUCTION STARTED'
    } else {
      return formattedTime;
    }
  }

  GetAuctionVehicleByDisplay(type) {
    let data = {
      auctionId: this.vehicleDetails?.auctionId,
      displayNo: this.vehicleDetails?.auctionDisplayNo,
      buyerId: this.currentUser?._id,
    }
    type == 'n' ? data['next'] = true : data['previous'] = true;
    this.service.inventoryByAuction(data).subscribe((data: any) => {
      debugger
      this.vehicleDetails = data.result[0];
      this.customBid = this.vehicleDetails?.startingBid + 500;
      this.vechicleId = this.vehicleDetails?._id;
      this.isNextCarAvailable = this.vehicleDetails?.isNextCarAvailable
      this.isPreviousCarAvailable = this.vehicleDetails?.isPreviousCarAvailable
      if (this.vehicleDetails?.vinimages.length <= 0) {
        this.vehicleDetails?.vinimages.push({
          image: this.vehicleDetails?.vinimages[0],
        });
      }
      this.bidHistory = null;
      this.getBidHIstory();
      this.showVehicle = this.vehicleDetails?.vinimages[0]?.image;
      this.cdr.detectChanges();
    })
  }

  openPopupAlert() {
    let message = "Please login as a buyer."
    this.dialogService.showWarningDialog(message);
  }


  zoomImageClick: any = false;
  onClickImageZoom() {
    this.zoomImageClick = !this.zoomImageClick
  }
  capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  lowercaseTitle(make, model, year) {
    const title = `${this.capitalizeFirstLetter(make)} ${this.capitalizeFirstLetter(model)} ${this.capitalizeFirstLetter(year)}`;
    return title;
  }
  formatCarMake(make: string): string {
    if (make) {
      return make.charAt(0).toUpperCase() + make.slice(1).toLowerCase();
    }
    return '';
  }

  filterImage(images: any) {
    return images?.filter(x => x.type == 'carimage')
  }

  videos: any = [];
  getInteriorVideo() {
    for (var i of this.vehicleDetails.sellerVinImages) {
      if (i?.image?.endsWith('mp4')) {
        this.videos.push(i?.image)
      }
    }
  }

}
