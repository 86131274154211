import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SingUpService } from '../../services/signup.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ConfirmdialogServiceService } from '../../confirmation-dialog/confirmdialog-service.service';
import { SseService } from '../../services1/sse.service';


@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit, AfterViewInit {

  buyerTab: boolean = false;
  sellerTab: boolean = false;
  buyerTab1: boolean = false;
  sellerTab1: boolean = false;
  SignUpPopupSection: boolean = false;
  OTPVerifyPopupSection: boolean = false;
  termAndCondition: boolean = false;
  LogInPopupSection: boolean = true;
  ResetPasswordPopupSection: boolean = false;
  ForgotPasswordPopupSection: boolean = false;
  submitted: boolean;
  userType: any = 2;
  resetPhone = new FormControl('', Validators.required);
  resetEmail: any;
  userFoundByEmail: any;
  userNotFound: boolean = false;
  isVerifyOtpChangePassword: boolean = false;
  isEnableVerifyBtn: boolean = true;
  hide: boolean = true;
  hide1: boolean = true;
  hide2: boolean = true;
  userForRegistration: { forApproval: boolean; businessType: string; phone: any; password: any; userType: any; email: any; countryCode: any; countryIso: string; };
  registeredUser: any;
  userPhoneNumber: any;
  validEmail: boolean = true;
  validPhone: boolean = true;
  errorMessageForEmail: any;
  errorMessageForPhone: any;
  controlTypeEmail: any;
  controlTypePhone: any;
  isResetPassUsingPhone = false;
  isSignUp = false;
  userTypeForPassReset = 0;
  isUserSelectedForReset = false;
  isSeller: boolean;
  dataForResendOtp: any = {};
  userEmailOtpVerification = false;
  userPhoneOtpVerification = false;
  enableContinueBtn = false;
  readonlyEmailInput: boolean = false;
  readonlyPhoneInput: boolean = false;
  otpErrorMessage: any;
  isNumberEnter: boolean;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates, CountryISO.Oman, CountryISO.Kazakhstan, CountryISO.Iraq];
  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[\w !@#$%&*]/;



  constructor(
    private fb: FormBuilder,
    private service: SingUpService,
    private toastr: ToastrService,
    private router: Router,
    private dialogService: ConfirmdialogServiceService,
    private cd: ChangeDetectorRef,
    private sseService: SseService
  ) { }


  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  userForm = this.fb.group({
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', Validators.required),
    term: new FormControl('', Validators.required),
  });

  ngAfterViewInit() {
    this.userForm.controls['email'].patchValue('');
  }



  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(5)])
  })

  otpForm = new FormGroup({
    first: new FormControl('', Validators.required),
    second: new FormControl('', Validators.required),
    third: new FormControl('', Validators.required),
    forth: new FormControl('', Validators.required),
    firstPhoneCH: new FormControl('', Validators.required),
    secondPhoneCH: new FormControl('', Validators.required),
    thirdPhoneCH: new FormControl('', Validators.required),
    forthPhoneCH: new FormControl('', Validators.required),
  })

  resetPasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.passwordRegex)]),
  })

  ngOnInit(): void {
    this.signUpPopUpEventCheck();
  }


  signUpPopUpEventCheck() {
    this.service.signUpEvent.subscribe((data: any) => {
      if (data === true) {
        this.SignUpPopupActive();
      }
      if (data === false) {
        this.LogInPopupActive();
      }
    });
  }

  buyerTabActive() {
    this.buyerTab1 = true;
    this.sellerTab1 = false;
    this.userType = 4;
    this.phoneValidityCheck();
    this.emailValidityCheck();
  }

  sellerTabActive() {
    this.buyerTab1 = false;
    this.sellerTab1 = true;
    this.userType = 2;
    this.phoneValidityCheck();
    this.emailValidityCheck();
  }

  buyerSignInTabActive() {
    this.buyerTab = true;
    this.sellerTab = false;
    this.buyerSignInTab = true;
    this.sellerSignInTab = false;
    this.sellerSignInUserType = 4;
    this.phoneValidityCheck();
    this.emailValidityCheck();
  }
  buyerSignInTab = true;
  sellerSignInTab = false;
  sellerSignInUserType = 4;

  sellerSignInTabActive() {
    this.buyerTab = false;
    this.sellerTab = true;
    this.buyerSignInTab = false;
    this.sellerSignInTab = true;
    this.sellerSignInUserType = 2;
    this.phoneValidityCheck();
    this.emailValidityCheck();
  }

  goBack() {
    this.buyerTab = false;
    this.sellerTab = false;
  }


  SignUpPopupActive() {
    this.termAndCondition = false;
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = true;

    // Reset selection
    this.buyerTab1 = false;
    this.sellerTab1 = false;
    this.userType = null;
  }

  LogInPopupActive() {
    this.termAndCondition = false;
    this.LogInPopupSection = true;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }
  ResetPasswordPopupActive() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = true;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }


  ForgotPasswordPopupActive(otpSubmitType = null) {
    let otp = this.otpForm.value.first + this.otpForm.value.second + this.otpForm.value.third + this.otpForm.value.forth;
    if (this.isVerifyOtpChangePassword == true) {
      let otpPhone = this.otpForm.value.firstPhoneCH + this.otpForm.value.secondPhoneCH + this.otpForm.value.thirdPhoneCH + this.otpForm.value.forthPhoneCH;
      this.isOtpMatchedFun(this.userPhoneOtpVerification ? otpPhone : otp);
    } else {
      let otpPhone = this.otpForm.value.firstPhoneCH + this.otpForm.value.secondPhoneCH + this.otpForm.value.thirdPhoneCH + this.otpForm.value.forthPhoneCH;
      let otpPostData = {
        "email": this.registeredUser?.email,
        "otp": otp,
        "update": true,
        "userType": this.registeredUser?.userType

      }

      let otpphonePostData = {
        "phone": this.getFormatedNumber(this.registeredUser.phone),
        "countryCode": this.registeredUser.countryCode,
        "otp": otpPhone,
        "update": true,
        "userType": this.registeredUser?.userType

      }

      let data = otpSubmitType === 'email' ? otpPostData : otpphonePostData;
      this.service.verifyOtp(data).subscribe((res: any) => {
        if (res.status == true) {
          localStorage.setItem("phone", JSON.stringify({ "phone": { number: this.registeredUser.phone, dialCode: this.registeredUser.countryCode, countryIso: this.registeredUser.countryIso } }));
          this.toastr.success(res.message);
          otpSubmitType === 'email' ? this.readonlyEmailInput = true : this.readonlyPhoneInput = true;
          if (this.readonlyEmailInput && this.readonlyPhoneInput) {
            this.ContinueToComplateProfile();
          }
          this.otpErrorMessage = '';
          this.enableContinueBtn = true;
        } else {
          this.otpErrorMessage = res.message //this.toastr.warning(res.message);
        }
      }, error => {
        this.toastr.error(error.message);
      })
    }

  }

  ContinueToComplateProfile() {
    document.getElementById('btnClose').click();
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
    this.router.navigateByUrl('/sign-up/complete-profile');
  }

  closePopUp() {

    // (document.getElementById('logInPopup') as any).modal('hide');
    // (document.getElementById('.modal-backdrop') as any).remove();
    const myElement = document.getElementById('logInPopup');
    myElement.classList.remove('in');
    myElement.classList.remove('show');
    this.userForm.reset();
    this.otpForm.reset();
    this.userFoundByEmail as {};
    this.registeredUser = {};
    this.userPhoneNumber = null;
    this.termAndCondition = false;
    this.resetPhone.reset();
    this.resetEmail = '';
    this.resetPasswordForm.reset();
    this.isResetPassUsingPhone = false;
    this.isSignUp = false;
    this.userTypeForPassReset = 0;
    this.isUserSelectedForReset = false;
    this.LogInPopupSection = false;
    this.SignUpPopupSection = false;
    this.readonlyEmailInput = false;
    this.readonlyPhoneInput = false;
    this.isVerifyOtpChangePassword = false;
    this.buyerTab = false;
    this.sellerTab = false;
    this.cd.detectChanges();
  }

  forgotPasswordResetActive() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = true;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
  }

  isFormValid(): boolean {
    if (this.userForm.valid == true) {
      if (this.userForm.value.term == true) {
        return true;
      }
      else {
        this.toastr.error("Please accept term and policies");
        return false;
      }
    } else {
      return this.userForm.valid;
    }
  }

  UserRegistration(user: any) {

    this.service.registerUser(user).subscribe((res: any) => {
      if (res.status == true) {
        this.registeredUser = res.result;
        this.service.acceptTermsCondition({ userId: this.registeredUser?._id }).subscribe((res: any) => {
          if (res.status == true) {
            localStorage.setItem("currentUser", JSON.stringify(this.registeredUser));
            this.toastr.success('registered successfully');
            this.termAndCondition = false;
            this.SignUpPopupSection = false;
            this.userEmailOtpVerification = true;
            this.userPhoneOtpVerification = true;
            this.OTPVerifyPopupSection = true;
          }
        })
      } else {
        this.toastr.error(res.message);
        return
      }
    })


  }

  signIn() {
    if (this.loginForm.invalid) return;
    let user = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      loginType: 'web'
    }
    user['role'] = this.sellerSignInUserType == 2 ? 'seller' : 'buyer';
    if (this.isNumberEnter) {
      delete user.email;
      user['phone'] = this.getFormatedNumber(this.loginForm.value.email);
    }

    this.service.login(user)
      .subscribe((res: any) => {

        if (res.status == true) {
          document.getElementById('btnClose').click();
          this.toastr.success(res.message);
          if (res?.result?.userType == 2 || res?.result?.userType == 3) {
            res.result.role = 'seller'
            this.router.navigateByUrl('/dashboard/seller/edit-profile');
          } else {
            res.result.role = 'buyer'
            this.router.navigateByUrl('/dashboard/buyer/edit-profile');
          }

          this.sseService.createEventSource();

          localStorage.setItem('currentUser', JSON.stringify(res.result));

        } else {
          if (res.step == 'otp') {
            this.LogInPopupSection = false;
            this.OTPVerifyPopupSection = true;
            this.userEmailOtpVerification = true;
            this.userPhoneOtpVerification = true;
            this.ResendOtp(this.dataformationForNewUserVerify(res.user));
            this.registeredUser = res.user;
            localStorage.setItem("currentUser", JSON.stringify(this.registeredUser));
          }
          if (res.step == "profile") {
            this.registeredUser = res.user;
            localStorage.setItem("currentUser", JSON.stringify(this.registeredUser));
            localStorage.setItem("phone", JSON.stringify({ "phone": { number: this.registeredUser.phone, dialCode: this.registeredUser.countryCode, countryIso: this.registeredUser.countryIso } }));
            this.ContinueToComplateProfile();
          }
          this.toastr.error(res.message);
        }
      },
        err => {
          this.toastr.error(err.error.err);
        }
      )
  }


  acceptTermCondition() {
    this.userForRegistration['addedFrom'] = 'web';
    this.userForRegistration['createdBy'] = 'web';
    this.UserRegistration(this.userForRegistration);
  }

  get f() {
    return this.userForm.controls;
  }

  get p() {
    return this.resetPasswordForm.controls;
  }

  SignUpOTPVerifyPopupActive() {
    // if (!this.buyerTab && !this.sellerTab) {
    //   this.toastr.error("Please select either Buyer or Seller");
    //   return;
    // }
    if (this.errorMessageForEmail || this.errorMessageForPhone) return;
    if (this.userForm.invalid || this.userForm.pristine) return;
    if (this.userForm.value.password != this.userForm.value.confirmPassword) {
      this.toastr.error("Password and confirm password are not matching");
      return;
    }
    this.userPhoneNumber = this.userForm.value.phone;
    let user = {
      "forApproval": true,
      "businessType": "external",
      "phone": this.getFormatedNumber(this.userForm.value.phone.nationalNumber),
      "password": this.userForm.value.password,
      "userType": this.userType,
      "email": this.userForm.value.email,
      "countryCode": this.userForm.value.phone.dialCode,
      "countryIso": Object.keys(this.CountryISO).find(country => this.CountryISO[country] === this.userForm.value.phone.countryCode.toLowerCase()),
    };

    this.userForRegistration = user;
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = false;
    this.termAndCondition = true;
    this.getTermsCondition(); //commenting for live
    //this.acceptTermCondition();
  }

  BackToSignUp() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    this.OTPVerifyPopupSection = false;
    this.SignUpPopupSection = true;
    this.termAndCondition = false;
  }

  resetPasswordOTP() {
    this.LogInPopupSection = false;
    this.ForgotPasswordPopupSection = false;
    this.ResetPasswordPopupSection = false;
    if (this.isResetPassUsingPhone === true) { this.userPhoneOtpVerification = true; this.userEmailOtpVerification = false }
    else { this.userPhoneOtpVerification = false; this.userEmailOtpVerification = true };
    this.OTPVerifyPopupSection = true;
    this.SignUpPopupSection = false;
  }

  isOtpMatchedFun(data) {
    this.dataForResendOtp['otp'] = data;
    this.service.verifyOtp(this.dataForResendOtp).subscribe((res: any) => {
      if (res.status == true) {
        this.forgotPasswordResetActive();
      }
      else {
        this.toastr.error(res.message, 'OTP not valid');
      }
    })

  }

  isPasswordMatchFun() {
    if (this.resetPasswordForm.value.password !== this.resetPasswordForm.value.confirmPassword) {

      if (this.resetPasswordForm.value.password.length === this.resetPasswordForm.value.confirmPassword.length) {
        this.toastr.error('Password and confirm Password not matching', 'Not Match');
      }
      return true;
    }
    else {
      return false;
    }
  }

  UpdatePasswordFun() {
    let data = {
      password: this.resetPasswordForm.value.password
    }
    delete this.dataForResendOtp.otp;
    if (!this.isResetPassUsingPhone) {
      data['email'] = this.dataForResendOtp.email;
    }
    if (this.isResetPassUsingPhone) {
      data = { ...data, ...this.dataForResendOtp }
    }

    this.service.UpdatePassword(data).subscribe((data: any) => {
      if (data.status) {
        this.toastr.success('Password updated Successfully!!!!', 'Successfully updated');
        this.resetPasswordForm.reset();
        this.resetPhone.reset();
        this.otpForm.reset();
        this.resetEmail = '';
        this.LogInPopupActive();
      } else {
        this.toastr.error('Not updated !! Please try again.', 'Error');
      }
    })
  }

  GetUserByEmail() {
    this.isVerifyOtpChangePassword = true;
    this.userFoundByEmail as {};
    this.userNotFound = false;
    let data = {};
    if (!this.isResetPassUsingPhone) {
      if (!this.isEmailValid(this.resetEmail)) {
        return
      }
      data = {
        email: this.resetEmail
      }
    }
    if (this.isResetPassUsingPhone && this.isUserSelectedForReset && this.resetPhone) {
      data = {
        role: this.isSeller ? 'seller' : 'buyer',
        phone: this.getFormatedNumber(this.resetPhone.value.number),
        countryCode: this.resetPhone.value.dialCode
      }
    }
    this.dataForResendOtp = data;
    this.service.GetUserByEmail(data).subscribe((data: any) => {
      if (data.status === true) {
        this.resetPasswordOTP();
        this.userFoundByEmail = data.result;
      }
      else {
        this.userNotFound = true;
        this.toastr.error('User not Found!!!', 'Not Found');
      }
    })
  }

  resendOTPAfterRegistration(resendOn) {
    let data;
    if (resendOn == 'email') {
      if (this.userEmailOtpVerification && this.userPhoneOtpVerification) {
        data = {
          email: this.registeredUser.email,
          mode: "register"
        }
        if ([2, 3].includes(this.registeredUser?.userType)) {
          data['role'] = 'seller'
        }
        else {
          data['role'] = 'buyer'
        }
      } else {
        if (!this.isResetPassUsingPhone) {
          data = {
            email: this.resetEmail,
            mode: "reset"
          }
        }
      }

    }

    if (resendOn == 'phone') {
      if (this.userEmailOtpVerification && this.userPhoneOtpVerification) {
        data = {
          phone: this.getFormatedNumber(this.registeredUser.phone),
          countryCode: this.registeredUser.countryCode,
          mode: "register"
        }
        if ([2, 3].includes(this.registeredUser?.userType)) {
          data['role'] = 'seller'
        }
        else {
          data['role'] = 'buyer'
        }
      }
      else {
        if (this.isResetPassUsingPhone) {
          data = {
            role: this.isSeller ? 'seller' : 'buyer',
            phone: this.getFormatedNumber(this.resetPhone.value.number),
            countryCode: this.resetPhone.value.dialCode,
            mode: "reset"
          }
        }
      }

    }

    this.ResendOtp(data);
  }

  resendresetPasswordOtp() {
    if (this.isVerifyOtpChangePassword == true) {
      delete this.dataForResendOtp.otp;
      this.dataForResendOtp['mode'] = 'reset';
      this.ResendOtp(this.dataForResendOtp);
    } else {
      let data = {
        mode: 'register',
        email: this.userForRegistration.email
      }
      this.ResendOtp(data);
    }
  }

  ResendOtp(data) {
    this.service.ResendOtp(data).subscribe((data: any) => {
      if (!data.status) {
        this.toastr.error('Please click again something went wrong', 'Error');
      }
    })
  }




  dataformationForNewUserVerify(data) {
    let postData = {
      "isEmailVerified": data.isEmailVerified,
      "isPhoneVerified": data.isPhoneVerified,
      "mode": 'register',
      "isVerificationCompleted": false,
      "isProfileCompleted": false,
      "email": data.email,
      "phone": this.getFormatedNumber(data.phone),
      "userType": data.userType,
      "countryCode": data.countryCode
    }
    return postData;
  }

  checkBackPress(e: any, index: any, elementName, inpField) {
    let key = e.key;
    if (key === 'Backspace') {
      this.isEnableVerifyBtn = true;
      if (index == 1) {
        if (this.otpForm.value[inpField] != '') {
          this.otpForm.controls[inpField].patchValue('');
        } else {
          document.getElementById(elementName).focus();
        }
      }
      if (index == 2) {
        if (this.otpForm.value[inpField] != '') {
          this.otpForm.controls[inpField].patchValue('');
        } else {
          document.getElementById(elementName).focus();
        }
      }

      if (index == 3) {
        if (this.otpForm.value[inpField] != '') {
          this.otpForm.controls[inpField].patchValue('');
        } else {
          document.getElementById(elementName).focus();
        }
      }

      if (index == 4) {
        if (this.otpForm.value[inpField] != '') {
          this.otpForm.controls[inpField].patchValue('');
        } else {
          document.getElementById(elementName).focus();
        }
      }
    }
  }

  moveToAnother(e: any, index: any, elementName, inpField) {
    if (index == 1) {
      if (this.otpForm.value[inpField] != '') {
        document.getElementById(elementName).focus();
      }
    }
    else if (index == 2) {
      if (this.otpForm.value[inpField] != '') {
        document.getElementById(elementName).focus();
      }
    }
    if (index == 3) {
      if (this.otpForm.value[inpField] != '') {
        document.getElementById(elementName).focus();
      }
    }
    if (index == 4) {
      this.isEnableVerifyBtn = false;
    }
    this.otpVerifyOnInput();
  }

  otpVerifyOnInput() {
    if (!this.readonlyPhoneInput && this.otpForm.controls.firstPhoneCH.valid && this.otpForm.controls.secondPhoneCH.valid && this.otpForm.controls.thirdPhoneCH.valid && this.otpForm.controls.forthPhoneCH.valid) {
      this.ForgotPasswordPopupActive();
    }
    if (!this.readonlyEmailInput && this.otpForm.controls.first.valid && this.otpForm.controls.second.valid && this.otpForm.controls.third.valid && this.otpForm.controls.forth.valid) {
      this.ForgotPasswordPopupActive('email');
    }
  }


  onlyNumbers: boolean;
  checkNumers(e: any) {
    let check = isNaN(this.loginForm.value.email);
    if (check == false) {
      if (this.loginForm.value.email.length <= 10) {
        this.onlyNumbers = true;
      } else {
        this.onlyNumbers = false;
      }
    } else {
      this.onlyNumbers = false;
    }
  }

  numberFormatFun(data) {
    const phoneNo = `${data.countryCode}${data.phone}`;
    const phone = this.removeSpecialCharacters(phoneNo);
  }

  removeSpecialCharacters(str) {
    if (!str) return;
    return str.replace(/[^\w\s]/g, "");
  };

  phoneValidityCheck() {
    let data = {};
    if (!this.userForm.controls.phone.valid)
      return
    data['phone'] = this.getFormatedNumber(this.userForm.value.phone.nationalNumber);
    data['userType'] = this.userType;
    data['countryCode'] = this.userForm?.value?.phone?.dialCode;
    this.controlTypePhone = 'phone';
    this.service.UserValidityCheck(data).subscribe((res: any) => {
      if (res.status == false) {
        this.validPhone = false;
        this.errorMessageForPhone = res.message['phone'];
      }
      if (res.status == true) {
        this.validPhone = true;
        this.errorMessageForPhone = '';
      }
    })
  }

  emailValidityCheck() {
    let data = {};
    if (!this.userForm.controls.email.valid)
      return
    data['email'] = this.userForm.value.email
    this.controlTypeEmail = 'email';
    this.service.UserValidityCheck(data).subscribe((res: any) => {

      if (res.status == false) {
        this.validEmail = false;
        this.errorMessageForEmail = res.message['email'];
      }
      if (res.status == true) {
        this.validEmail = true;
        this.errorMessageForEmail = '';
      }
    })
  }

  isEmailValid(email: string): boolean {
    // Regular expression pattern for a basic email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailPattern.test(email);
  }

  // getTermsCondition() {
  //   this.service.getTermsCondition({status:1}).subscribe((res: any) => {
  //     if (res.status == true) {
  //       this.termConditionList = res.result;
  //     }
  //   })
  // }
  getTermsCondition() {
    this.termAndCondition = true;
    document.getElementById('scrolltop').scrollTop = 0;
  }

  onTypeEmail(e: any) {
    let data = e.target.value?.toLowerCase();
    e.target.value = data;
    const isNumber = /^-?\d*\.?\d+$/.test(data);
    this.isNumberEnter = isNumber;
  }

  getFormatedNumber(inputValue) {
    if (inputValue && inputValue.startsWith('0')) {
      let phoneNumber = inputValue.substring(1)
      return phoneNumber.replace(/\s/g, '');
    }
    else {
      let phoneNumber = inputValue.replace(/[\s\D]/g, '');
      phoneNumber = phoneNumber.replace(/\s/g, '');
      return phoneNumber
    }
  }

}
