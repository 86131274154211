import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { AuthService } from './auth/services/auth.service';
import { SharedService } from './shared/services1/shared.service';
import { UserService } from './views/users/services/user.service';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SessionService } from './shared/services1/session.service';
import { MatSelectModule } from '@angular/material/select';
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { SingUpService } from './shared/services/signup.service';
import { SallerBuyerService } from './main/dashboard/sallerBuyer.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { BidStockService } from './main/auction-join/services/bid-stock.service ';
import { AuctionStockService } from './main/auction-join/services/auction-stock.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { YoutubeBannerComponent } from './main/youtube-banner/youtube-banner.component';
import { CarouselModule, CarouselModule as NgxBootstrapCarouselModule } from 'ngx-bootstrap/carousel';
import { YoutubeCardsComponent } from './main/youtube-banner/youtube-cards/youtube-cards.component';
import { PageNotFoundComponent } from './main/404not-found/page-not-found/page-not-found.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';


const config: SocketIoConfig = { url: environment.SOCKET_URL, options: {} };
// import { NgxPrintModule } from 'ngx-print';

// ngx-bootstrap

@NgModule({
  declarations: [
    AppComponent, 
    PageNotFoundComponent, 
    ViewsComponent, 
    EditProfileComponent, 
    AppDashboardComponent, 
    VehicleDetailsComponent, 
    YoutubeBannerComponent, 
    YoutubeCardsComponent],
  imports: [
    MatExpansionModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    // NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SocketIoModule.forRoot(config),
    NgxBootstrapCarouselModule,
    LazyLoadImageModule,
    CarouselModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    UserService,
    SessionService,
    SingUpService,
    SallerBuyerService,
    BidStockService,
    AuctionStockService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
