import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SallerBuyerService } from 'src/app/main/dashboard/sallerBuyer.service';

@Directive({
  selector: '[appBlur]'
})
export class BlurDirective {
  currentUser: any;
  userDetails: any;

  constructor(private el: ElementRef, private renderer: Renderer2, private SallerBuyerService: SallerBuyerService, private authService: AuthService) {
    this.currentUser = this.authService.currentUserValue;
    this.getUser();
  }

  getUser(){
    this.SallerBuyerService.getProfile({page:1, pageLimit:20, userId: this.currentUser._id}).subscribe((res: any) => {
      this.userDetails = res.result[0];
      if(!this.userDetails.isApproved){
        this.renderer.setStyle(this.el.nativeElement, 'filter', 'blur(2px)');
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
      }
    });
  }

}
