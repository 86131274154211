<div class="side-navbar" style="margin-top: 40px;">
    <ul class="nav nav-pills flex-column mb-auto">


        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/dashboard/buyer/dashboard">
                <i class="fa-solid fa-house"></i>
                <span class="label-menu"> Dashboard </span>
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/buyer/edit-profile">
                <i class="fa-solid fa-user"></i>
                <span class="label-menu">Profile </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/my-bids">
                <i class="fa-solid fa-gavel"></i>
                <span class="label-menu"> My Bids </span>
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/buyer/won-vehicles">
                <i class="fa-solid fa-trophy"></i>
                <span class="label-menu"> Won Vehicles </span>
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/buyer/watchlist">
                <i class="fa-regular fa-bookmark"></i>
                <span class="label-menu"> Watchlist </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/order-history">
                <i class="fa-solid fa-clock-rotate-left"></i>
                Deposit
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/buyer/transaction">
                <i class="fa-solid fa-wallet"></i>
                <span class="label-menu"> Payment </span>
            </a>
        </li>
        <li>
            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard/buyer/history">
                <i class="fa-solid fa-clock-rotate-left"></i>
                <span class="label-menu"> History & Gatepass </span>
            </a>
        </li>
        <li>
            <a class="nav-link " routerLinkActive="active" routerLink="/dashboard/buyer/statement">
                <i class="fa-solid fa-receipt"></i>
                <span class="label-menu"> Statement </span>
            </a>
        </li>
    </ul>
</div>