<div class="sidebar">
    <div class="logo"><img routerLink="/dashboard" src="../../../assets/aca_images/logo.png" alt="logo"></div>
    <a routerLink="/dashboard" routerLinkActive="active" class="mt-3">
        <span class="sidebar-icon">
            <i class="fa-solid fa-chart-line"></i>
        </span>Dashboard</a>

    <div *ngIf="currentUser?.userType==8">
        <a routerLink="/saler/list" routerLinkActive="active">
            <i class="fa-solid fa-users-line" style="margin-left: 8px;"></i>
            Seller
        </a>
        <a routerLink="/inventory/list" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-warehouse"></i>
            </span>Inventory
        </a>
    </div>
    <div *ngIf="currentUser?.userType==7">
        <a routerLink="/receipt/list" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-file-invoice"></i>
            </span>Receipt
        </a>
        <a routerLink="/sold/cars/list" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-car-side"></i>
            </span>Sold Cars
        </a>
    </div>
    <div *ngIf="currentUser?.userType==1">
        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-users"></i>
                Users
            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/users/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-users"></i>
                        </span>Admin Users
                    </a>
                </li>
                <li>
                    <a routerLink="/buyer/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-users-between-lines"></i>

                        </span>Buyer
                    </a>
                </li>
                <li>
                    <a routerLink="/saler/list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Sellers
                    </a>
                </li>
                <li>
                    <a routerLink="/saler/external-approved-list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-users"></i>
                        </span>Approved Seller
                    </a>
                </li>
                <li>
                    <a routerLink="/saler/saler-creation" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-right-from-bracket"></i>
                        </span>Seller Approval System
                    </a>
                </li>
            </ul>
        </div>
        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-warehouse"></i>
                Inventory
            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/master-car/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-hourglass-end"></i>
                        </span>Ext. Car Approval
                    </a>
                </li>

                <li>
                    <a routerLink="/master-car/pending" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-hourglass-end"></i>
                        </span>Pending Cars
                    </a>
                </li>
                <li>
                    <a routerLink="/inventory/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-warehouse"></i>
                        </span>Inventory
                    </a>
                </li>
                <li>
                    <a routerLink="/auction/stock/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-gavel"></i>
                        </span>Auction Stock
                    </a>
                </li>
                <li>
                    <a routerLink="/receipt/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-file-invoice"></i>
                        </span>Receipt
                    </a>
                </li>
                <li>
                    <a routerLink="/sold/cars/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-car-side"></i>
                        </span>Sold Cars
                    </a>
                </li>
                <li>
                    <a routerLink="/documents/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-file-lines"></i>
                        </span>Documents
                    </a>
                </li>
                <li>
                    <a routerLink="/gatepass/list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-id-card"></i>
                        </span>Gatepass
                    </a>
                </li>
            </ul>
        </div>
        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-circle-chevron-down"></i>
                Seller

            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/saler/sold-car-list" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-car"></i>
                        </span>Sellers Sold Cars
                    </a>
                </li>
                <li>
                    <a routerLink="/saler/sale-approval" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-thumbs-up"></i>
                        </span>Sale approval
                    </a>
                </li>
                <li>
                    <a routerLink="/saler/outgoing-payment" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-right-from-bracket"></i>
                        </span>Outgoing Payment
                    </a>
                </li>
            </ul>
        </div>
        <a routerLink="/sold/cars/other-income" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-car-side"></i>
            </span>other Income
        </a>


        <a routerLink="/receipt/sales-return" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-right-left"></i>
            </span>Sales Return
        </a>
        <a routerLink="/vin/management/list" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-hourglass-end"></i>
            </span>VIN Management System
        </a>

        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-circle-chevron-down"></i>
                Reports

            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/reports/list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Cash In Hand
                    </a>
                </li>
                <li>
                    <a routerLink="/reports/in-hand" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Cash In hand Summary
                    </a>

                    <a routerLink="/reports/in-bank" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Cash In Bank
                    </a>


                </li>
                <li>
                    <a routerLink="/dayend/view" routerLinkActive="active">
                        <span class="sidebar-icon">
                            <i class="fa-solid fa-hourglass-end"></i>
                        </span>Day End
                    </a>
                </li>
            </ul>
        </div>



        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-circle-chevron-down"></i>
                Master All

            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/master/make-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Make List
                    </a>
                </li>
                <li>
                    <a routerLink="/master/model-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Model List
                    </a>

                    <a routerLink="/master/series-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Series List
                    </a>

                    <a routerLink="/master/year-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Year List
                    </a>

                    <a routerLink="/master/branch-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Branch List
                    </a>

                    <a routerLink="/master/warehouse-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Warehouse List
                    </a>

                    <a routerLink="/master/interior-color-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Interior Color
                    </a>

                    <a routerLink="/master/exterior-color-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Exterior Color
                    </a>

                    <a routerLink="/master/body-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Body List
                    </a>


                    <a routerLink="/master/engine-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Engine List
                    </a>

                    <a routerLink="/master/users-role-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Users Role List
                    </a>


                    <a routerLink="/master/buyer-limit-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Buyer Limit
                    </a>


                    <a routerLink="/master/auction-fee-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Auction Fee
                    </a>

                    <a routerLink="/master/transmission-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Transmission List
                    </a>

                    <a routerLink="/master/drive-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Drive Type List
                    </a>

                    <a routerLink="/master/fuel-list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Fuel List
                    </a>



                </li>
            </ul>
        </div>

        <a routerLink="/incomming-case/list" routerLinkActive="active">
            <span class="sidebar-icon">
                <i class="fa-solid fa-car-side"></i>
            </span>Incomming Case
        </a>

        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-circle-chevron-down"></i>
                Menu

            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/menu/list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Menu List
                    </a>
                </li>

                <li>
                    <a routerLink="/menu/access-add" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Menu Access List
                    </a>
                </li>
            </ul>
        </div>









        <div class="btn-group">
            <a class="btn rounded-right" data-toggle="dropdown" href="#">
                <i class="fa-solid fa-circle-chevron-down"></i>
                Account

            </a>
            <ul class="dropdown-menu rounded-0">
                <li>
                    <a routerLink="/account/list" routerLinkActive="active">
                        <span class="sidebar-icon ">
                            <i class="fa-solid fa-users-line"></i>
                        </span>Account Master
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>