<footer class="site-footer" *ngIf="hideFooter">
    <div class="overlay-photo-image-bg" data-bg-img="assets/img/landing-page/footer-bg-1.jpg" data-bg-opacity=".25"
        style="background-image: url(assets/img/landing-page/footer-bg-1.jpg);opacity: 0.25;"></div>
    <div class="container">
        <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
            <div class="col-lg-6">
                <div class="footer-info">
                    <div class="footer-logo">
                        <a href="index.html" title="ACA">
                            <img [lazyLoad]="'assets/img/logo-white.webp'" alt="Logo">
                        </a>
                    </div>
                    <p>Al Qaryah is your go-to destination for buying and selling cars in the UAE and the Middle East.
                        We offer a wide variety of vehicles, from compact cars to luxury SUVs and rugged trucks. You can
                        easily sell your car online and get the best price without any hassle.
                        Our listings provide detailed information about each vehicle, including its make, model, color,
                        mileage, and year of manufacture.</p>
                    <div>
                        <div class="mailchimp-form ">
                            <form class="one-field-form" method="post" action="#0">
                                <div class="field-group ">
                                    <input class="email-input " type="email" value="" name="EMAIL" id="email-input"
                                        placeholder="Enter Email Address" autocomplete="off">
                                    <div class="cta-area">
                                        <input class="btn-solid subscribe-btn" type="submit" value="Subscribe Now"
                                            name="subscribe">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="social-icon">
                        <a href="https://www.facebook.com/alqaryahcarsauction" target="_blank"
                            title="Follow on Facebook"><i class="fa-brands fa-facebook" aria-hidden="true"></i>  </a>
                        <a href="https://www.instagram.com/alqaryah_auction/" target="_blank"
                            title="Follow on Instagram"><i class="fa-brands fa-instagram" aria-hidden="true"></i></a>
                        <a href="https://www.linkedin.com/company/alqaryahauction/" target="_blank"
                            title="Follow on Linkedin"><i class="fa-brands fa-linkedin" aria-hidden="true"></i></a>
                        <a href="https://www.youtube.com/@alqaryah_auction" target="_blank" title="Follow on YouTube">
                            <i class="fa-brands fa-youtube" aria-hidden="true"></i></a>
                        <a href="https://www.tiktok.com/@alqaryah_auction" target="_blank" title="Follow on TikTok">
                            <i class="fa-brands fa-tiktok"  aria-hidden="true"></i></a>
                        <a href="https://play.google.com/store/apps/details?id=com.vehicle.al_qaryah" target="_blank"
                            title="Download App from Andriod">
                            <i class="fa-brands fa-android" aria-hidden="true"></i>
                        </a>
                        <a href="https://apps.apple.com/in/app/al-qaryah-auction/id6474415566" target="_blank"
                            title="Download From App Store">
                            <i class="fa-brands fa-apple" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Auctions</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a
                                routerLink="/contact-us">Contact</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a
                                routerLink="/pricing">Pricing</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a
                                routerLink="/auctions">Auction</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Explore</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/wp">Blogs</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/faq">FAQ</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/complaint">Complaint</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-xs-6">
                <div class="footer-menu">
                    <h3 class="h3-title footer-title">Company</h3>
                    <ul>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a routerLink="/about-us">About</a>
                        </li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a
                                routerLink="/terms-and-conditions">Terms & conditions</a></li>
                        <li><i class="fa fa-long-arrow-right" aria-hidden="true"></i><a
                                routerLink="/privacy-policy">Privacy Policy</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>