
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SharedService } from './shared/services1/shared.service';
import { SseService } from './shared/services1/sse.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit , AfterViewInit{
  title = 'aca-web';
  status = 'ONLINE';
  isConnected = true;
  currentUser: any;
  eventSource: EventSource;
  showLoader: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private sseService: SseService,
    private sharedService: SharedService
  ) {
    this.currentUser = this.authService.currentUserValue;
  }
  ngOnInit(): void {
    if(this.currentUser?.token){
      this.sseService.createEventSource();
    }
    this.authService.hideParticles();
  }
  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showLoader = (!event.url.includes('auction-join') && !event.url.includes('banner')) ? true : false;
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.showLoader = (!event.url.includes('auction-join') && !event.url.includes('banner')) ? true : false;
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.sharedService.setIsShowHeader((!event.url.includes('auction-join') && !event.url.includes('auctions')) ? true : false)
      }
    });
    // this.sharedService.showChat();
  }


 
}
