import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-seller-sidebar',
  templateUrl: './sidbar.component.html',
  styleUrls: ['./sidbar.component.scss'],
})
export class SellerSidebarComponent implements OnInit {
  
  constructor() {}

  ngOnInit(): void {}


}
