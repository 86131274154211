import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  constructor(private zone: NgZone, private service: SharedService) { }
  // getEventSentSource(url:string){
  //   const eventSource = this.service.getEventService(url);
  //   return new Observable((observer) =>{
  //     eventSource.onmessage = event =>{
  //       this.zone.run(()=>{
  //         observer.next(event);
  //       });
  //     };
  //     eventSource.onerror = error =>{
  //       this.zone.run(()=>{
  //         observer.error(error);
  //       });
  //     };
  //   })
  // }

  async createEventSource() {
    this.service.getEventService('events/user-stream?name=global');
  }
  // async getEventSentSource(url: string): Promise<any> {
  //   const eventSource = await this.service.getEventService(url);
  //   return new Observable(observer => {
  //     eventSource.onmessage = event => {
  //       const messageData: any = JSON.parse(event.data);
  //       observer.next(messageData);
  //     };
  //   });
  // }


}
