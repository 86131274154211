<section class="testimonial">


    <div class="container">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
            <div class="tst-wrps" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="600">
                <div class="card" style="box-shadow: none;">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
                        data-interval="100000">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-2 align-items-start">
                                                    <img src="../../../../assets/img/dummy-image.jpg" alt=""
                                                        class="img-fluid">
                                                </div>
                                                <div class="col-sm-10">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p style="text-align: justify;">I recently had the pleasure of
                                                        attending Al Qaryah cars auction where I was able to purchase a
                                                        Honda Accord 2015 full option at an
                                                        incredibly affordable price comparing with local market. The
                                                        whole experience was a good one, from start to finish.
                                                        The facilities were well-maintained and the staff were courteous
                                                        and professional.
                                                        <br>
                                                        What impressed me most about the auction was the wide variety of
                                                        cars and the transparency of the auction process. The bidding
                                                        process was clear and easy to understand.
                                                        <br>
                                                        Overall, I would highly recommend this car auction to anyone
                                                        looking to purchase a vehicle at an affordable price. I will
                                                        definitely be back in the future if I need another car
                                                    </p>
                                                    <h3>— “Ashraf Raziq - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-2 align-items-start">
                                                    <img src="../../../../assets/img/dummy-image.jpg" alt=""
                                                        class="img-fluid">
                                                </div>
                                                <div class="col-sm-10">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p style="text-align: justify;">It's Highly Professional.
                                                        Responsible & Helpful to buy on Reasonable price with Large
                                                        number of vehicles with easy process. Mostly 99.9℅ Vehicles will
                                                        be clear passed. Best Experience with AL Qaryah Cars Auction.
                                                    </p>
                                                    <h3>— “Mohammed Baseer - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-2 align-items-start">
                                                    <img src="../../../../assets/img/dummy-image.jpg" alt=""
                                                        class="img-fluid">
                                                </div>
                                                <div class="col-sm-10">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p style="text-align: justify;">I had a great experience with this
                                                        car auction.
                                                        They have good support on the phone for new customers and they
                                                        guide you through the whole process.
                                                        They also help you with the registration and make sure
                                                        everything goes smoothly. I bought a car at a reasonable price
                                                        and I’m very happy with it.
                                                        I would recommend this car auction to anyone looking for a good
                                                        deal.</p>
                                                    <h3>— “شيخه الحلوة - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="bg"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carousel-caption">
                                            <div class="row">
                                                <div class="col-sm-2 align-items-start">
                                                    <img src="../../../../assets/img/dummy-image.jpg" alt=""
                                                        class="img-fluid">
                                                </div>
                                                <div class="col-sm-10">
                                                    <!-- <h2>Micheal Smith - <span>Web Developer</span></h2> -->
                                                    <p style="text-align: justify;">I recently attended a car auction
                                                        and was thoroughly impressed with the experience.
                                                        The selection of vehicles was vast and the bidding process was
                                                        exciting and well-organized.
                                                        The staff were knowledgeable and helpful, making the entire
                                                        process smooth and enjoyable.
                                                        I was able to find the car I was looking for at a great price
                                                        and am very happy with my purchase.
                                                        I would highly recommend this car auction to anyone in the
                                                        market for a new vehicle</p>
                                                    <h3>— “Muhammad Farid - Client”</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="arrow-btn-section">
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"><i
                                        class="fa fa-chevron-left"></i></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"><i
                                        class="fa fa-chevron-right"></i></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testimonial-matrix-left squre-matrix-8" data-aos="fade-right"
                data-aos-anchor-placement="top-center" data-aos-duration="800">
                <img src="assets/img/icons/testimonial-matrix-left-1.svg" />
            </div>
            <div class="testimonial-matrix-left squre-matrix-9" data-aos="fade-left"
                data-aos-anchor-placement="top-center" data-aos-duration="800">
                <img src="assets/img/icons/testimonial-matrix-right-1.svg" />
            </div>
        </div>

    </div>
</section>