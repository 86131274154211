import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './main/landing-page.component';
import { BuyerDashboardComponent } from './main/dashboard/buyer/buyer-dashboard.component';
import { SellerDashboardComponent } from './main/dashboard/seller/seller-dashboard.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { YoutubeBannerComponent } from './main/youtube-banner/youtube-banner.component';
import { YoutubeCardsComponent } from './main/youtube-banner/youtube-cards/youtube-cards.component';
import { PageNotFoundComponent } from './main/404not-found/page-not-found/page-not-found.component';

const routes: Routes = [

  {
    path:'vehicle/details',
    component: VehicleDetailsComponent
  },
  {
    path:'404',
    component: PageNotFoundComponent
  },
  {
    path:'banner',
    component: YoutubeBannerComponent
  },
  {
    path:'cards',
    component: YoutubeCardsComponent
  },
  {
    
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./main/landing-page.module').then(m => m.LandingPageModule)
      },
    ]
  },

  {
    path: 'dashboard/buyer',
    component: BuyerDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/main/dashboard/buyer/buyer-dashboard.module').then(m => m.BuyerDashboardModule)
      },
    ]
  },
  {
    path: 'dashboard/seller',
    component: SellerDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/main/dashboard/seller/seller-dashboard.module').then(m => m.SellerDashboardModule)
      },
    ]
  },
  {
    path: 'vehicle',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },

   { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [DatePipe],
})
export class AppRoutingModule { }
