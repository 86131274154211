import { Component, Input, OnInit } from '@angular/core';
import { ConfirmdialogServiceService } from '../confirmation-dialog/confirmdialog-service.service';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {
  @Input() message: string;
  constructor(
    private dialogService: ConfirmdialogServiceService
  ) { }

  ngOnInit(): void {
  }

  hideDialog() {
    this.dialogService.hideWarningDialog();
  }

}
