import { Component, OnInit } from '@angular/core';
import { SingUpService } from '../services/signup.service';
import { SharedService } from '../services1/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  hideFooter: boolean = false;

  constructor(
    private signUpService: SingUpService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.hideFooter$.subscribe((result: any) => {
      this.hideFooter = false;
      setTimeout(() => {
        this.hideFooter = true;
      }, 1000);
    });

    setTimeout(() => {
      this.hideFooter = true;
    }, 1000);
  }

  activateSignUpPopUp(isEnable: boolean) {
    this.signUpService.EnableSignUpModel(isEnable);
  }
}
