import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor() { }

  welcomeUserMsg = new BehaviorSubject(false);
  eventHeading = new BehaviorSubject(false);
  annualEventHeading = new BehaviorSubject(false);
  subEventHeading = new BehaviorSubject(false);
  careersHeading = new BehaviorSubject(false);
  sectorHeading = new BehaviorSubject(false);
  profileHeading = new BehaviorSubject(false);
  resourcesHeading = new BehaviorSubject(false);
  newsHeading = new BehaviorSubject(false);
  educationHeading = new BehaviorSubject(false);
  financialHeading = new BehaviorSubject(false);
  quizHeading = new BehaviorSubject(false);
  usersHeading = new BehaviorSubject(false);
  feedbackHeading = new BehaviorSubject(false);
  partnerHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  divisionHeading = new BehaviorSubject(false);
  subDivisionHeading = new BehaviorSubject(false);
  sponsorsHeading = new BehaviorSubject(false);
  buyerHeading = new BehaviorSubject(false);
  salerHeading = new BehaviorSubject(false);
  inventoryHeading = new BehaviorSubject(false);
  auctionStockHeading = new BehaviorSubject(false);
  soldCarsHeading = new BehaviorSubject(false);
  salerSoldCarsHeading = new BehaviorSubject(false);
  paymentHeading = new BehaviorSubject(false);
  saleApprovalHeading = new BehaviorSubject(false);
  outgoingPaymentHeading = new BehaviorSubject(false);
  receiptHeading = new BehaviorSubject(false);
  documentsHeading = new BehaviorSubject(false);
  gatepassHeading = new BehaviorSubject(false);
  dayendHeading = new BehaviorSubject(false);
  salesReturnHeading = new BehaviorSubject(false);
  addsalesReturnHeading = new BehaviorSubject(false);
  otherIncomeHeading = new BehaviorSubject(false);

  salerDetailsHeading = new BehaviorSubject(false);
  addSalerHeading = new BehaviorSubject(false);
  addexternalSalerHeading = new BehaviorSubject(false);
  externalSalerListHeading = new BehaviorSubject(false);
  vinManagementHeading = new BehaviorSubject(false);
  externalSallerApproved = new BehaviorSubject(false);
  masterCarHeading = new BehaviorSubject(false);
  reportsHeading = new BehaviorSubject(false);
  pendingCarDetails = new BehaviorSubject(false);
  bidHeading = new BehaviorSubject(false);
  cashBankHeading = new BehaviorSubject(false);
  cashHandHeading = new BehaviorSubject(false);
  makeHeading = new BehaviorSubject(false);
  modelHeading = new BehaviorSubject(false);
  seriesHeading = new BehaviorSubject(false);
  yearHeading = new BehaviorSubject(false);
  branchHeading = new BehaviorSubject(false);
  warehouseHeading = new BehaviorSubject(false);
  incommingCaseHeading = new BehaviorSubject(false);
  interiorColorHeading = new BehaviorSubject(false);
  exteriorColorHeading = new BehaviorSubject(false);
  bodyListHeading = new BehaviorSubject(false);
  engineHeading = new BehaviorSubject(false);
  usersRoleHeading = new BehaviorSubject(false);
  buyerLimitHeading = new BehaviorSubject(false);
  auctionFeeHeading = new BehaviorSubject(false);
  menuHeading = new BehaviorSubject(false);
  menuAccessHeading = new BehaviorSubject(false);
  fuelHeading = new BehaviorSubject(false);
  transmissionHeading = new BehaviorSubject(false);
  driveHeading = new BehaviorSubject(false);
  accountHeading = new BehaviorSubject(false);
}
