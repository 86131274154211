<section class="content auction-join-wpr">
    <div class="container" *ngIf="imageType == 'sold'">
        <img src="../../../../assets/images/sold-image.png" style="width: 95%; height: 100%;">
    </div>
    <div class="container" *ngIf="imageType == 'cancel'">
        <img src="../../../../assets/images/unsold-img.png" style="width: 75%; height: 100%;" alt="">
    </div>
    <div class="container" *ngIf="imageType == 'skip'">
        <img src="../../../../assets/images/skip-img.png" style="width: 80%; height: 100%;" alt="">
    </div>
    <div class="container" *ngIf="imageType == 'pause'">
        <img src="../../../../assets/images/pause-img.png" style="width: 80%; height: 100%;" alt="">
    </div>
</section>