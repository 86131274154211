import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-youtube-cards',
  templateUrl: './youtube-cards.component.html',
  styleUrls: ['./youtube-cards.component.scss']
  
})
export class YoutubeCardsComponent implements OnInit {
  imageType:any;
  constructor( ) {}
 ngOnInit() {
  }

}

