<div class="modal-body text-center">
    <div class="row">
        <div class="col-12">
            <img src="../../assets/images/warning.svg" style="height: 150px;" class="img-fluid mx-auto d-block"
                alt="Warning Image">
        </div>
        <div class="col-12 mt-3">
            <h2><b>Warning</b></h2>
        </div>
        <div class="col-12 mt-2">
            <p>{{message}}</p>
        </div>
        <div class="col-12 mt-3">
            <button type="button" class="btn btn-primary" (click)="hideDialog()">OK</button>
        </div>
    </div>
</div>